import React, {useState, useCallback, useEffect} from 'react';
import {Box, Button, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import {writeBatch, doc} from 'firebase/firestore';
import {useSnackbar} from 'notistack';
import {omit} from 'lodash';
import {useNavigate} from 'react-router-dom';
import {LoadingButton} from '@mui/lab';
import {red} from '@mui/material/colors';

import {db} from '../../firebase.js';

import ResponsiveDialog from '../../components/ResponsiveDialog';

import TrainingGrid from './Grid';

const ImportButton = ({selectionCount, ...rest}) => {
    return (
        <LoadingButton variant="contained" {...rest}>
            Import Selected ({selectionCount})
        </LoadingButton>
    );
};

export default function Import(props) {
    const {training: rawTraining = [], onClose, open} = props;

    const [training, setTraining] = useState(rawTraining || []);
    const [importing, setImporting] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setTraining(rawTraining || []);
    }, [rawTraining])

    const handleImport = useCallback(async () => {
        const toImport = training.filter(item => {
            const {uniqueId} = item;
            return rowSelectionModel.includes(uniqueId);
        });

        if (!toImport.length) {
            enqueueSnackbar('No training selected', {variant: 'error'});
            return;
        }

        setImporting(true);

        try {
            const batch = writeBatch(db);

            for (const item of training) {
                const {uniqueId} = item;
                const trainingRef = doc(db, 'training', uniqueId);

                const {instructors, types, ...rest} = omit(item, ['foundExisting', 'issues']);

                batch.set(trainingRef, {
                    ...rest,
                    instructors,
                    types
                });
            }

            await batch.commit();
            
            enqueueSnackbar('Training imported', {variant: 'success'});

            onClose();
        } catch(e) {
            enqueueSnackbar(`Error importing incidents: ${e}`, {variant: 'error'});
        }

        setImporting(false);
    }, [enqueueSnackbar, training, rowSelectionModel, db, navigate]);

    const handleProcessRowUpdate = useCallback(async(updatedRow, originalRow) => {
        const {types = [], instructors = []} = updatedRow;

        setTraining(training.map(row => {
            if (row.uniqueId === originalRow.uniqueId) {
                return {
                    ...row,
                    types: types.map(type => type.id),
                    instructors: instructors.map(instructor => instructor.id)
                };
            }

            return row;
        }));

        return updatedRow;
    }, [training]);

    return (
        <ResponsiveDialog maxWidth="xl" open={open} onClose={onClose}>
            <DialogTitle sx={{display: 'flex', alignItems: 'stretch', flexDirection: 'column'}}>
                Import Training
            </DialogTitle>
            <DialogContent dividers>
                <TrainingGrid
                    training={training}
                    getRowId={row => row.uniqueId}
                    checkboxSelection
                    onRowSelectionModelChange={newRowSelectionModel => {
                        setRowSelectionModel(newRowSelectionModel);
                    }}
                    processRowUpdate={handleProcessRowUpdate}
                    onProcessRowUpdateError={e => console.warn(e)}
                    rowSelectionModel={rowSelectionModel}
                    getRowClassName={params => {
                        const {row} = params;
                        const {foundExisting = false, issues = []} = row || {};
                        const classNames = [];

                        if (foundExisting) {
                            classNames.push('duplicate');
                        }

                        if (issues.length) {
                            classNames.push('has-issues');
                        }

                        return classNames.join(' ');
                    }}
                    sx={{
                        '& .has-issues': {
                            backgroundColor: 'rgba(250, 250, 0, .1)'
                        },
                        '& .duplicate': {
                            borderLeft: `4px solid ${red[500]}`
                        }
                    }}
                    columns={[
                        {
                            field: 'foundExisting',
                            headerName: 'Duplicate',
                            width: 100,
                            renderCell: params => {
                                const {row} = params;
                                const {foundExisting = false} = row || {};

                                return (
                                    <Box sx={{display: 'flex', p: 1, flexDirection: 'column'}}>
                                        {foundExisting ? 'Yes' : 'No'}
                                    </Box>
                                );
                            }
                        }
                    ]}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                types: false,
                                jprs: false,
                                type: false,
                                isntructors: false
                            }
                        }
                    }}
                    onRowClick={null}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <ImportButton loading={importing} onClick={handleImport} selectionCount={rowSelectionModel.length} />
            </DialogActions>
        </ResponsiveDialog>
    );
}
