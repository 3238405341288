import React from 'react';
// import Calendar from '@ericz1803/react-google-calendar';

export default function User() {
    return (
        <div>TODO</div>
    );
    // return (
    //     <Calendar
    //         apiKey={'AIzaSyDkrs4ivQKskyb2X5jQr2gXlvStli15k4w'}
    //         calendars={[{
    //             calendarId: 'c_adq7desanam8eqdnl8hirqcjqs@group.calendar.google.com'
    //         }]}
    //     />
    // );
}