import React, {useContext} from 'react';

import {StationsContext} from '../contexts/Stations';

import SelectField from './SelectField.js';

export default function(props) {
    const {stations} = useContext(StationsContext);

    return (
        <SelectField
            label="Station"
            name="station"
            options={stations.map(station => {
                return {
                    value: station.id,
                    label: station.name
                };
            })}
            {...props}
        />
    )
}