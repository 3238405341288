import React, {useMemo, useEffect, useState, useCallback, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Box} from '@mui/material';
import moment from 'moment';
import {GridActionsCellItem, useGridApiRef} from '@mui/x-data-grid-pro';
import {get, omit} from 'lodash';
import {useParams} from 'react-router-dom';
import {doc, collection, onSnapshot, addDoc} from 'firebase/firestore';
import {useSnackbar} from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import {UserContext} from '../../contexts/User';

import {verifyOfficer, processRawDoc, processRawDocs, populateKeyFromCollection, ensureJSDates} from '../../data/utils';

import SearchableDataGrid from '../../components/SearchableDataGrid';

import {db} from '../../firebase';

import ItemDialog from './ItemDialog';
import RefillDialog from './RefillDialog';

const renderMatchCell = params => {
    const {value} = params;

    if (Array.isArray(value)) {
        return (
            value.map(({text, highlight}, index) => {
                if (!highlight) {
                    return <span key={index}>{text}</span>;
                }

                return (
                    <span key={index} className="highlighted">
                        {text}
                    </span>
                );
            })
        );
    }

    if (typeof value === 'string') {
        return value;
    }

    return '-';
};

export const useItems = () => {
    const params = useParams();
    const [loadingItems, setLoadingItems] = useState(false);
    const [loadingParent, setLoadingParent] = useState(false);
    const [items, setItems] = useState([]);
    const [parent, setParent] = useState(null);

    const parentRefArgs = useMemo(() => {
        const {id: uid, ...otherParams} = params;
        let refArgs = [db];

        Object.entries(otherParams).forEach(([key, param]) => {
            if (key !== '*' && param) {
                refArgs.push('gear', param);
            }
        });

        refArgs.push('gear', uid);

        return refArgs;
    }, [db, params]);

    const refArgs = useMemo(() => {
        let refArgs = [...parentRefArgs];
        refArgs.push('items');

        return refArgs;
    }, [parentRefArgs]);

    useEffect(() => {
        setLoadingItems(true);

        const ref = collection(...refArgs);
        const unsubscribe = onSnapshot(ref, async snapshot => {
            let rows = processRawDocs(snapshot);
            rows = await populateKeyFromCollection(db, rows, 'apparatus', 'apparatus');

            setItems(rows.map(row => {
                const {apparatus, ...rest} = row;

                return {
                    ...rest,
                    apparatus: apparatus ? apparatus.tag : ''
                };
            }));

            setLoadingItems(false);
        });
        
        return () => {
            unsubscribe();
        };
    }, [db, refArgs]);

    useEffect(() => {
        setLoadingParent(true);

        const ref = doc(...parentRefArgs);
        const unsubscribe = onSnapshot(ref, async snapshot => {
            let row = processRawDoc(snapshot);

            setParent(row);

            setLoadingParent(false);
        });
        
        return () => {
            unsubscribe();
        };
    }, [db, parentRefArgs]);

    const loading = loadingItems || loadingParent;

    return {
        items,
        parent,
        loading,
        refArgs,
        parentRefArgs
    };
};

export default function ItemsGrid(props) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [refillOpen, setRefillOpen] = useState(false);
    const {currentUser} = useContext(UserContext);
    const isOfficer = verifyOfficer(currentUser);
    const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';
    const params = useParams();
    const {enqueueSnackbar} = useSnackbar();
    const {uid: userUid} = currentUser;
    const apiRef = useGridApiRef();
    const {items: rows, parent, loading, refArgs} = useItems();

    const {name, additionalFields = []} = parent || {};
    const isCylinder = !!(name || '').match(/cylinder|bottle/i);

    const autosizeOptions = {
        columns: [
            'name',
            ...additionalFields.map(field => field.key)
        ],
        includeOutliers: true,
        includeHeaders: true,
        expand: true
    };

    useEffect(() => {
        // TODO weird hack to get around some kind of timing issue where the autorize columns MUI code
        // is trying to find a ref, but it doesnt exist...
        if (rows.length) {
            setTimeout(() => {
                if (apiRef && apiRef.current) {
                    apiRef.current.autosizeColumns(autosizeOptions);
                }
            }, 10);
        }
    }, [apiRef, rows]);

    const baseUrl = useMemo(() => {
        let parts = [];

        Object.entries(params).forEach(([key, value]) => {
            if (key !== '*' && value) {
                parts.push('gear', value);
            }
        });

        return `/${parts.join('/')}`;
    }, [params]);

    const onRowClick = useCallback(params => {
        const {row} = params;
        const {uid} = row;
        
        navigate(`${baseUrl}/${uid}`);
    }, [baseUrl, navigate]);

    const onAddRefillRecord = useCallback(async data => {
        const {uid} = refillOpen;

        try {
            const args = [...refArgs, uid, 'records'];
            const ref = collection(...args);
            const {users = [], ...rest} = data;

            const record = ensureJSDates({
                ...rest,
                createdAt: new Date(),
                users: users.map(user => user.uid || user)
            });
            
            await addDoc(ref, record);
            return record;
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }
    }, [enqueueSnackbar, refArgs, refillOpen]);

    const handleAdd = useCallback(() => {
        setOpen(true);
    }, []);

    const onAdd = useCallback(async data => {
        try {
            const ref = collection(...refArgs);
            const record = ensureJSDates({
                ...omit(data, ['uid', 'id']),
                createdAt: new Date(),
                user: userUid
            });

            await addDoc(ref, record);
            return record;
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }
    }, [enqueueSnackbar, refArgs, userUid]);

    const handleRefill = useCallback(row => {
        setRefillOpen(row);
    }, []);

    const columns = [
        {
            field: 'createdAt',
            headerName: 'Created',
            sortable: true,
            width: 105,
            valueFormatter: value => {
                return value ? moment(value).format(dateFormat) : '-';
            }
        },
        {
            field: 'name',
            ...!rows.length && {flex: 1},
            headerName: 'Name',
            renderCell: renderMatchCell
        },
        ...additionalFields.map(field => {
            const {key, label: headerName, type} = field;

            return {
                field: key,
                headerName,
                sortable: true,
                // type: type === 'date' ? 'date' : 'string',
                renderCell: params => {
                    const {value: rawValue} = params;
                    let value;

                    if (type === 'date') {
                        value = rawValue ? moment(rawValue).format(dateFormat) : '-';
                    } else {
                        value = rawValue;
                    }

                    return renderMatchCell({value});
                }
            };
        }),
        {
            type: 'actions',
            field: 'actions',
            width: 50,
            getActions: params => {
                const {row} = params;
                const {uid} = row || {};

                if (!isCylinder) {
                    return [];
                }

                return [
                    <GridActionsCellItem key={`action-refill-${uid}}`} icon={<AutorenewIcon />} label="Refill" onClick={() => handleRefill(row)} />
                ];
            }
        }
    ];

    if (rows.length === 0 && !isOfficer) {
        return null;
    }

    return (
        <Box {...props}>
            {isCylinder && (
                <RefillDialog onSubmit={onAddRefillRecord} open={!!refillOpen} handleClose={() => setRefillOpen(false)} />
            )}

            <SearchableDataGrid
                stateId="gear-items"
                apiRef={apiRef}
                search={[
                    'name',
                    ...additionalFields.reduce((result, field) => {
                        const {label, type} = field;

                        if (type === 'text' || type === 'apparatus') {
                            result.push(label.toLowerCase());
                        }

                        return result;
                    }, [])
                ]}
                initialState={{
                    sorting: {
                        sortModel: [
                            {field: 'createdAt', sort: 'asc'}
                        ]
                    }
                }}
                autosizeOptions={autosizeOptions}
                autosizeOnMount
                localeText={{noRowsLabel: 'No items'}}
                loading={loading}
                rows={rows}
                columns={columns.map(column => ({...column, disableColumnMenu: true}))}
                pageSizeOptions={[]}
                disableRowSelectionOnClick
                disableColumnFilter
                onRowClick={onRowClick}
                hideFooter
                slotProps={{
                    loadingOverlay: {
                        variant: 'linear-progress',
                        noRowsVariant: 'skeleton'
                    }
                }}
                sx={{
                    '& .MuiDataGrid-cell:focus-within': {
                        outline: 'none'
                    }
                }}
            />

            {isOfficer && (
                <Box sx={{display: 'flex', alignItems: 'center', mt: 1}}>
                    <ItemDialog parent={parent} onSubmit={onAdd} open={open} handleClose={() => setOpen(false)} />
                    <Box sx={{flexGrow: 1}} />
                    <Button startIcon={<AddIcon />} disabled={loading} size="small" variant="outlined" onClick={handleAdd}>
                        Add New Item
                    </Button>
                </Box>
            )}
        </Box>
    );
};