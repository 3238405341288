import React, {useEffect, useCallback, useState} from 'react';
import {DataGridPro, useGridApiRef} from '@mui/x-data-grid-pro';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {TextField} from '@mui/material';

function joinWithOr(arr) {
    if (arr.length === 0) return '';
    if (arr.length === 1) return arr[0];
    if (arr.length === 2) return `${arr[0]} or ${arr[1]}`;
    
    return `${arr.slice(0, -1).join(', ')} or ${arr[arr.length - 1]}`;
}

const StatefulDataGrid = props => {
    const {stateId, rows, search, ...rest} = props;
    if (!stateId) {
        throw new Error('StatefulDataGrid requires a stateId prop');
    }

    const [filter, setFilter] = useState('');
    const apiRef = useGridApiRef();

    const handleStateChange = useCallback(() => {
        if (!stateId) {
            return;
        }

        const currentState = apiRef.current.exportState();
        localStorage.setItem(`statefulDataGrid-${stateId}`, JSON.stringify(currentState));
    }, [apiRef, stateId]);

    useEffect(() => {
        const state = JSON.parse(localStorage.getItem(`statefulDataGrid-${stateId}`) || null);
        if (state) {
            apiRef.current.restoreState(state);
        }
    }, [apiRef, stateId]);
    
    let filteredRows = rows;
    if (search && filter) {
        const keys = search;

        filteredRows = rows.reduce((result, row) => {
            const {...rest} = row;
            const keysToFilter = keys.map(key => {
                const matches = match(row[key] || '', filter); // Handles cases where key might not exist in row
                return {key, matches};
            });
        
            // Check if there's at least one match across all keys
            const hasMatch = keysToFilter.some(({matches}) => matches.length > 0);
        
            if (hasMatch) {
                const parsedRow = {...rest};
        
                // Parse each key's value based on matches and add it to the parsedRow
                keysToFilter.forEach(({key, matches}) => {
                    if (matches.length > 0) {
                        parsedRow[key] = parse(row[key], matches);
                    } else {
                        parsedRow[key] = row[key]; // Preserve the original value if no match
                    }
                });
        
                result.push(parsedRow);
            }
        
            return result;
        }, []);
    }

    return (
        <>
            {search && (
                <TextField
                    placeholder={`Filter by ${joinWithOr(search)}`}
                    fullWidth
                    sx={{mb: 1}}
                    onChange={e => setFilter(e.target.value)}
                />
            )}
            <DataGridPro
                apiRef={apiRef}
                onColumnVisibilityModelChange={handleStateChange}
                onSortModelChange={handleStateChange}
                onColumnResize={handleStateChange}
                onColumnWidthChange={handleStateChange}
                onColumnOrderChange={handleStateChange}
                rows={filteredRows}
                {...rest}
            />
        </>
    );
};

export default StatefulDataGrid;