import React, {useEffect, useState, useCallback, useContext, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import {Button, Box, Typography, Divider, Skeleton, Card, CardActionArea, CardContent, Grid} from '@mui/material';
import {collection, onSnapshot, addDoc} from 'firebase/firestore';
import {get, pickBy} from 'lodash';
import {useSnackbar} from 'notistack';
import * as MaterialIcons from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {ref, getDownloadURL} from 'firebase/storage';

import * as Icons from '../../components/Icons';

import {db, storage} from '../../firebase';
import {UserContext} from '../../contexts/User';

import {verifyOfficer, processRawDocs, populateKeyFromCollection} from '../../data/utils';

import CategoryDialog from './CategoryDialog';

const CategoryItem = ({doc, onClick}) => {
    const {icon, name, image, apparatus} = doc;
    const Icon = icon && (Icons[icon] || MaterialIcons[icon]);
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        const {filePath, thumbnailPath} = image || {};

        let isSubscribed = true;

        const fetch = async() => {
            if (thumbnailPath) {
                const url = await getDownloadURL(ref(storage, thumbnailPath));
                if (isSubscribed) {
                    setImageUrl(url)
                }

                return;
            }

            if (filePath) {
                const url = await getDownloadURL(ref(storage, filePath));
                if (isSubscribed) {
                    setImageUrl(url)
                }

                return;
            }
        };
        
        setImageUrl(null);
        fetch();

        return () => isSubscribed = false;
    }, [image]);

    return (
        <Grid item key={doc.id} xs={6} sm={6} md={4} lg={3}>
            <Card variant="outlined">
                <CardActionArea onClick={e => onClick(e, doc)}>
                    <CardContent
                        sx={{
                            aspectRatio: '4/3',
                            p: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative'
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                zIndex: 0,
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                                ...imageUrl && {backgroundImage: `url(${imageUrl})`},
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                opacity: 0.5,
                                filter: 'blur(3px) brightness(1.2)'
                            }}
                        />
                        <Box
                            sx={{
                                zIndex: 1,
                                background: imageUrl && 'rgba(255, 255, 255, 0.8)',
                                px: 1,
                                py: 0.5,
                                borderRadius: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 1
                            }}
                        >
                            {Icon && <Icon sx={{fontSize: 50}} />}
                            <Typography variant="h5">{name}</Typography>
                            {apparatus && <Typography variant="body2">{get(apparatus, 'tag')}</Typography>}
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
};

export default function CategoriesGrid(props) {
    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [docs, setDocs] = useState([]);
    const [open, setOpen] = useState(false);
    const {currentUser} = useContext(UserContext);
    const {uid: userUid} = currentUser;
    const isOfficer = verifyOfficer(currentUser);
    const {enqueueSnackbar} = useSnackbar();

    const isRoot = Object.keys(params).length === 1;
    const baseUrl = useMemo(() => {
        let parts = [];

        Object.entries(params).forEach(([key, value]) => {
            if (key !== '*' && value) {
                parts.push('gear', value);
            }
        });

        if (parts.length === 0) {
            return '/gear/';
        }

        return `/${parts.join('/')}/gear/`;
    }, [params]);

    const onClick = useCallback((e, doc) => {
        const {metaKey} = e;
        const {id} = doc;

        const url = `${baseUrl}${id}`;
        if (metaKey) {
            window.open(url);
            return;
        }

        navigate(url);
    }, [navigate, baseUrl]);

    const refArgs = useMemo(() => {
        let refArgs = [db];

        Object.entries(params).forEach(([key, param]) => {
            if (key !== '*' && param) {
                refArgs.push('gear', param);
            }
        });

        refArgs.push('gear');

        return refArgs;
    }, [db, params]);

    useEffect(() => {
        setLoading(true);

        const ref = collection(...refArgs);
        const unsubscribe = onSnapshot(ref, async snapshot => {
            let docs = processRawDocs(snapshot);
            docs = await populateKeyFromCollection(db, docs, 'apparatus', 'apparatus');
            setDocs(docs);

            setLoading(false);
        });
        
        return () => {
            unsubscribe();
        };
    }, [db, refArgs]);

    const onAdd = useCallback(async data => {
        try {
            const ref = collection(...refArgs);
            const category = pickBy({
                ...data,
                createdAt: new Date(),
                user: userUid
            });

            await addDoc(ref, category);
            return pickBy(category);
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
            setLoading(false);
        }
    }, [enqueueSnackbar, refArgs, userUid]);

    const addButton = isOfficer && (
        <>
            <CategoryDialog onSubmit={onAdd} parent={!isRoot} open={open} handleClose={() => setOpen(false)} />
            <Box sx={{flexGrow: 1}} />
            <Button disabled={loading} startIcon={<AddIcon />} variant="outlined" size="small" onClick={() => setOpen(true)}>Add New {isRoot ? 'Category' : 'Subcategory'}</Button>
        </>
    );

    if (!isOfficer && !docs.length) {
        return null;
    }

    return (
        <Box {...props}>
            {!isRoot && (
                <>
                    <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
                        <Typography variant="h6" sx={{display: docs.length ? 'block' : 'none'}}>{isRoot ? 'Categories' : 'Subcategories'}</Typography>

                        {!isRoot && addButton}
                    </Box>

                    {!!docs.length && <Divider sx={{mb: 2}}/>}
                </>
            )}

            {/* {isAdmin && (
                <Alert severity="info">{refArgs.join('/')}</Alert>
            )} */}
            
            <Grid container spacing={2}>
                {loading ? (
                    <Grid item xs={12} sm={6} md={4}>
                        <Skeleton variant="rounded" height={180} />
                    </Grid>
                ) : (
                    !!docs.length && docs.map(doc => {
                        return (
                            <CategoryItem key={doc.id} doc={doc} onClick={onClick}  />
                        );
                    })
                )}

                {!loading && !docs.length && isRoot && (
                    <Grid item>
                        <Typography variant="body2">No categories found.</Typography>
                    </Grid>
                )}
            </Grid>

            {isRoot && addButton && (
                <Box sx={{display: 'flex', alignItems: 'center', mt: 1}}>
                    {addButton}
                </Box>
            )}
        </Box>
    );
};