import React, {useState, useEffect, useContext, useCallback} from 'react';
import {useTheme} from '@mui/material/styles';
import {Box, Typography, Button, AvatarGroup, Alert, useMediaQuery} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {useParams} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import moment from 'moment';
import {get} from 'lodash';
import {LoadingButton} from '@mui/lab';
import {useNavigate} from 'react-router-dom';
import {collection, query, addDoc, limit, where, orderBy, onSnapshot} from 'firebase/firestore';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import {UserContext} from '../contexts/User';
import {db} from '../firebase';

import {populateUsers, processRawDocs, getCollection} from '../data/utils';

import UserAvatar from '../components/UserAvatar';

const Checks = () => {
    const [loading, setLoading] = useState(true);
    const [checks, setChecks] = useState([]);
    const [activeChecks, setActiveChecks] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
    const {currentUser} = useContext(UserContext);
    const {id: uid, type} = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const dateFormatLong = get(currentUser, 'settings.dateFormatLong') || 'LLL';

    const {isOfficer = false} = currentUser;

    let isSubscribed = true;

    const handleFetch = async raw => {
        try {
            let docs = processRawDocs(raw);
            docs = await populateUsers(db, docs);

            if (isSubscribed) {
                const activeChecks = docs.filter(doc => doc.active);
                const inactiveChecks = docs.filter(doc => !doc.active);

                setActiveChecks(activeChecks);
                setChecks(inactiveChecks);
            }
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        if (isSubscribed) {
            setLoading(false);
        }
    };

    const handleStartCheck = useCallback(async () => {
        if (activeChecks.length) {
            const [record] = activeChecks;
            return navigate(`/apparatus/${uid}/${type}/${record.uid}`);
        }

        const ref = collection(db, 'checks');
        const q = query(ref, where('apparatus', '==', uid), where('type', '==', type), orderBy('createdAt', 'desc'), limit(1));
        const docs = await getCollection(db, q);

        const [record] = docs || [];
        if (!record) {
            enqueueSnackbar('Checklist not found. Please contact the administrator.', {variant: 'error'});
            return;
        }

        const {checks = []} = record;
        
        const data = {
            type,
            loggedChecks: checks,
            createdAt: new Date(),
            active: true,
            users: []
        };

        const checkRef = collection(db, 'apparatus', uid, 'checks');
        const checkRecordRaw = await addDoc(checkRef, data);

        navigate(`/apparatus/${uid}/${type}/${checkRecordRaw.id}`);
    }, [navigate, uid, type, db, activeChecks]);

    useEffect(() => {
        const ref = collection(db, 'apparatus', uid, 'checks');
        const q = query(ref, where('type', '==', type), orderBy('createdAt', 'desc'), limit(10));
        const snapshot = onSnapshot(q, handleFetch);
        
        return () => {
            snapshot();
            isSubscribed = false;
        };
    }, [enqueueSnackbar, db, uid, type]);

    const columns = [
        {
            field: 'createdAt',
            headerName: 'Completed',
            ...(isSmall ? {width: 110} : {flex : 1}),
            sortable: true,
            valueFormatter: value => {
                if (!value) {
                    return '-';
                }

                value = value.toDate ? value.toDate() : value;
                
                return moment(value).format(isSmall ? 'MMM D, HH:mm' : dateFormatLong);
            }
        },
        {
            field: 'users',
            headerName: 'Members',
            renderCell: params => {
                const {row} = params;
                const {users = []} = row || {};

                if (!users || !users.length) {
                    return '-';
                }

                return (
                    <Box sx={{display: 'flex', height: '100%', alignItems: 'center'}}>
                        <AvatarGroup>
                            {users.map(user => {
                                const {uid} = user;

                                return (
                                    <UserAvatar key={`avatar-${uid}`} user={user} />
                                );
                            })}
                        </AvatarGroup>
                    </Box>
                );
            },
            flex: 1
        },
        {
            field: 'notes',
            headerName: 'Notes',
            width: isSmall ? 60 : 90,
            sortable: false,
            renderCell: params => {
                const {row} = params;
                const {loggedChecks = []} = row;
                const hasNote = loggedChecks.find(check => !!check.note);
                if (!hasNote) {
                    return;
                }

                return (
                    <Box sx={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                        <WarningAmberIcon />
                    </Box>
                );
            }
        }
    ];

    return (
        <Box>
            <Box sx={{mb: 4}}>
                {activeChecks.length > 0 ? (
                    <Alert
                        severity="error"
                        action={
                            <Button color="inherit" variant="contained" size="small" onClick={handleStartCheck}>
                                Continue Truck Check
                            </Button>
                        }
                        sx={{mb: 2}}
                    >
                        There is an incomplete truck check. Please complete it before starting a new one.
                    </Alert>
                ) : (
                    <LoadingButton
                        variant="contained"
                        size="large"
                        onClick={handleStartCheck}
                        fullWidth
                    >
                        {activeChecks.length ? 'Continue Truck Check' : 'Start Truck Check'}
                    </LoadingButton>
                )}
            </Box>

            <Typography variant="h6" gutterBottom>Recent History</Typography>

            <Box sx={{display: 'flex'}}>
                <DataGrid
                    localeText={{
                        noRowsLabel: `No ${type} checks`
                    }}
                    hideFooter
                    loading={loading}
                    autoHeight
                    rowHeight={60}
                    rows={checks}
                    columns={columns}
                    disableRowSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableColumnMenu
                    onRowClick={params => {
                        const {row} = params;

                        navigate(`/apparatus/${uid}/${type}/${row.uid}`);
                    }}
                />
            </Box>

            {isOfficer && (
                <Box sx={{mt: 2}}>
                    <Button
                        size="large"
                        onClick={() => navigate(`/apparatus/${uid}/${type}/edit`)}
                        fullWidth
                    >
                        Edit Checklist
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default Checks;