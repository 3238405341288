import React from 'react';
import {Card, Box, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';

const BaseChart = function({title, subtitle, children, ...rest}) {
    const theme = useTheme();

    return (
        <Card variant="outlined" {...rest}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                background: theme.palette.grey[50],
                borderBottom: 1,
                borderBottomStyle: 'solid',
                borderBottomColor: theme.palette.grey[200],
                p: 1
            }}>
                <Typography sx={{fontSize: 16, flex: 1}}>{title}</Typography>
                <Typography sx={{fontSize: 16, ml: 1, opacity: 0.6}}>{subtitle}</Typography>
            </Box>
            {children}
        </Card>
    );
};

export default BaseChart;