import React, {useState, forwardRef, useContext} from 'react';
import {Controller} from 'react-hook-form';
import Button from '@mui/material/Button';
import useForkRef from '@mui/utils/useForkRef';
import {DateRangePicker} from '@mui/x-date-pickers-pro/DateRangePicker';
import DateRangeIcon from '@mui/icons-material/DateRange';
import {get} from 'lodash';

import {UserContext} from '../contexts/User';

const DateRangeButtonField = forwardRef((props, ref) => {
    const {
        setOpen,
        label,
        id,
        disabled,
        InputProps: {ref: containerRef} = {},
        inputProps: {'aria-label': ariaLabel} = {}
    } = props;

    const handleRef = useForkRef(ref, containerRef);

    return (
        <Button
            variant="contained"
            id={id}
            disabled={disabled}
            ref={handleRef}
            aria-label={ariaLabel}
            onClick={() => setOpen?.(prev => !prev)}
            startIcon={<DateRangeIcon />}
        >
            {label ? label : 'Pick a date range'}
        </Button>
    );
});

DateRangeButtonField.fieldType = 'single-input';

const ButtonDateRangePicker = forwardRef((props, ref) => {
    const {slotProps, ...rest} = props;
    const [open, setOpen] = useState(false);

    return (
        <DateRangePicker
            slots={{field: DateRangeButtonField, ...props.slots}}
            ref={ref}
            {...rest}
            slotProps={{field: {setOpen}, ...slotProps}}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        />
    );
});

export default function ControlledTextField(props) {
    // TODO ensure errors and labels
    const {name, rules, format, readOnly, disabled, ...rest} = props;
    const {currentUser} = useContext(UserContext);
    const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';

    return (
        <Controller
            name={name}
            rules={rules}
            render={({field}) => {
                const {value, onChange, ...restField} = field;

                return (
                    <ButtonDateRangePicker
                        {...restField}
                        {...rest}
                        label={
                            value && value[0] !== null && value[1] !== null && value
                                .map(date => (date ? date.format(dateFormat) : 'null'))
                                .join(' - ')
                        }
                        format={format || dateFormat}
                        readOnly={readOnly}
                        disabled={disabled}
                        value={value || null}
                        onChange={range => onChange(range)}
                    />
                );
            }}
        />
    );
};
