import React, {useState, useCallback, useEffect} from 'react';
import {groupBy} from 'lodash';
import moment from 'moment';
import {collection, query, where} from 'firebase/firestore';
import {Typography, Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import Type from '../incidents/stats/Type';

import {getCollection} from '../../data/utils';

import {db} from '../../firebase.js';

export default function User(props) {
    const {user} = props;
    
    const [groupedData, setGroupedData] = useState({});
    // const [trainingCount, setTrainingCount] = useState(0);
    // const [totalTrainingCount, setTotalTrainingCount] = useState(0);

    const fetch = useCallback(async() => {
        if (!user) {
            return null;
        }

        const {uid} = user;

        // Incidents
        const ref = collection(db, 'incidents');
        const q = query(ref, where('members', 'array-contains', uid));
        const incidents = await getCollection(db, q);

        const groupedIncidents = groupBy(incidents, incident => {
            const {date} = incident;
            return moment(date).format('YYYY');
        });

        // Training
        const trainingRef = query(collection(db, 'training'), where('members', 'array-contains', uid));
        const training = await getCollection(db, trainingRef);

        const groupedTraining = groupBy(training, training => {
            const {date} = training;
            return moment(date).format('YYYY');
        });

        const years = Object.keys(groupedIncidents).concat(Object.keys(groupedTraining));
        
        const groupedData = years.reduce((acc, year) => {
            const incidents = groupedIncidents[year] || [];
            const training = groupedTraining[year] || [];

            return {
                ...acc,
                [year]: {
                    incidents,
                    training
                }
            };
        }, {});

        setGroupedData(groupedData);
    }, [db, user]);

    useEffect(() => {
        fetch();
    }, [user, fetch]);

    return (
        <>
            {Object.keys(groupedData).reverse().map(year => {
                const {incidents, training} = groupedData[year];

                return (
                    <Accordion key={`incidents-${year}`}>
                        <AccordionSummary key={`user-incidents-${year}`} expandIcon={<ExpandLessIcon />}>
                            <Typography><strong>{year}</strong> {incidents.length} incidents - {training.length} training</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Type incidents={incidents} />
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </>
    );
}