import React, {useState} from 'react';
import {DataGridPro} from '@mui/x-data-grid-pro';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {Box, TextField} from '@mui/material';

function joinWithOr(arr) {
    if (arr.length === 0) return '';
    if (arr.length === 1) return arr[0];
    if (arr.length === 2) return `${arr[0]} or ${arr[1]}`;
    
    return `${arr.slice(0, -1).join(', ')} or ${arr[arr.length - 1]}`;
}

const Searchable = props => {
    const {rows, search, ...rest} = props;

    const [filter, setFilter] = useState('');
    
    let filteredRows = rows;
    if (search && filter) {
        const keys = search;

        filteredRows = rows.reduce((result, row) => {
            const {...rest} = row;
            const keysToFilter = keys.map(key => {
                const matches = match(row[key] || '', filter);
                return {key, matches};
            });
        
            const hasMatch = keysToFilter.some(({matches}) => matches.length > 0);
        
            if (hasMatch) {
                const parsedRow = {...rest};
        
                keysToFilter.forEach(({key, matches}) => {
                    if (matches.length > 0) {
                        parsedRow[key] = parse(row[key], matches);
                    } else {
                        parsedRow[key] = row[key];
                    }
                });
        
                result.push(parsedRow);
            }
        
            return result;
        }, []);
    }

    return (
        <>
            {search && (
                <TextField
                    placeholder={`Filter by ${joinWithOr(search)}`}
                    fullWidth
                    sx={{mb: 1}}
                    onChange={e => setFilter(e.target.value)}
                />
            )}
            <Box sx={{display: 'flex', flexDirection: 'column', minHeight: 150}}>
                <DataGridPro
                    rows={filteredRows}
                    {...rest}
                />
            </Box>
        </>
    );
};

export default Searchable;