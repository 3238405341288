import React from 'react';
import {Controller} from 'react-hook-form';
import {MuiFileInput} from 'mui-file-input';
import AttachFileIcon from '@mui/icons-material/AttachFile';

export default function ControlledFileField(props) {
    const {name, rules, accept, ...rest} = props;

    return (
        <Controller
            name={name}
            rules={rules}
            render={({field, fieldState: {error}}) => (
                <MuiFileInput
                    InputProps={{
                        inputProps: {
                            accept
                        },
                        startAdornment: <AttachFileIcon />
                    }}
                    {...rest}
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : null}
                />
            )}
        />
    );
};
