import React from 'react';
import {useController} from 'react-hook-form';
import {Select, FormControl, InputLabel, MenuItem, FormHelperText} from '@mui/material';
import {capitalize} from 'lodash';

export default function SelectField(props) {
    const {name, rule: rawRules, required, label, options, fullWidth, margin, sx, allowBlank = false, ...rest} = props;
    const rules = {...rawRules};
    const fieldLabel = label !== false ? label || capitalize(name) : null;

    if (required && !rules.required) {
        rules.required = fieldLabel ? `${fieldLabel} is required` : 'This field is required';
    }

    const {field, fieldState: {error}} = useController({name, rules});
    const {value, ...restField} = field;

    return (
        <FormControl fullWidth={fullWidth} margin={margin} sx={sx} error={!!error}>
            {fieldLabel && <InputLabel id={`${name}-label`}>{label}</InputLabel>}
            <Select
                label={fieldLabel}
                required={required}
                value={value || ''}
                {...rest}
                {...restField}
                error={!!error}
            >
                {allowBlank && <MenuItem value=""><em>None</em></MenuItem>}
                {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
            </Select>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
        </FormControl>
    );
};
