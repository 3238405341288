import React from 'react';
import {Dialog, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material/styles';

export default function ResponsiveDialog({children, ...props}) {
    const {maxWidth = 'md', ...rest} = props;
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down(maxWidth));

    return (
        <Dialog
            fullScreen={isSmall}
            maxWidth={maxWidth}
            fullWidth
            {...rest}
        >
            {children}
        </Dialog>
    );
};