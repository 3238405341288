import React, {useEffect} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {Box} from '@mui/material';
import {useParams} from 'react-router-dom';
// import parse from 'autosuggest-highlight/parse';
// import match from 'autosuggest-highlight/match';

import {NFPA} from '../data/standards';

// import TextField from '../form/TextField';
import SelectField from '../form/SelectField';

export const StandardSubsection = ({parent, chapter, subsection, ...rest}) => {
    let section = parent[subsection];
    if (!section) {
        section = parent[parseInt(subsection)];
    }

    if (!section) {
        console.warn(`No section found for ${chapter}.${subsection}`);
        return;
    }

    const {title, text: rawText = '', other, warning} = section;
    const subsections = Object.keys(section).filter(key => parseInt(key) > 0);
    const key = `${chapter}.${subsection}`;
    const highlighted = false;

    const lines = (rawText || '').split('\n');
    let [text, ...otherLines] = lines;
    if (text && otherLines.length > 0 && text.startsWith('(')) {
        otherLines = [text, ...otherLines].filter(Boolean);
        text = '';
    }

    return (
        <Box sx={{my: 1, '&:last-child': {mb: 0}}} key={key} className={highlighted ? 'section highlighted' : 'section'} {...rest}>
            <Box sx={{mx: 1}}>
                <span style={{fontWeight: 'bold'}}>{chapter}.{subsection} {title ? `${title}.` : ''}</span> {text}

                {otherLines.length > 0 && (
                    <Box sx={{ml: 1, mt: 0.5}}>
                        {otherLines.map((line, i) => <Box key={`${chapter}.${subsection}.line.${i}`}>{line}</Box>)}
                    </Box>
                )}
            </Box>

            {warning && (
                <Box sx={{ml: 2, mt: 1}}>
                    <strong>WARNING: </strong>{warning}
                </Box>
            )}

            {other && other.map((item, i) => {
                const {title, text} = item;

                return (
                    <Box sx={{ml: 2, mt: 1}} key={`${chapter}.${subsection}.other.${i}`}>
                        <span style={{fontWeight: 'bold'}}>{title}.</span> {text}
                    </Box>
                );
            })}

            {subsections.map(i => <StandardSubsection key={`${parent}.${subsection}.${i}`} parent={parent[subsection]} chapter={`${chapter}.${subsection}`} subsection={i} />)}
        </Box>
    );
};

const Standard = ({standard, year}) => {
    const {title, editions = {}} = NFPA[standard] || {};
    const edition = editions[year] || {};

    // const {watch} = useFormContext();
    // const filter = watch('filter', '');
    // const filters = filter.split(' ').filter(Boolean);

    const chapters = Object.keys(edition).filter(ch => parseInt(ch) > 0);
    // let highlightedBlocks = [];

    if (!title || !edition) {
        return null;
    }

    const renderedChapters = chapters.map((chapter, i) => {
        const {title} = edition[chapter];
        const subsections = Object.keys(edition[chapter]).filter(key => parseInt(key) > 0);

        return (
            <Box sx={{mb: 5, fontSize: '0.8rem'}} key={`chapter-${i}`}>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Box sx={{fontWeight: 'bold'}}>Chapter {chapter}&nbsp;&nbsp;{title}</Box>
                </Box>

                {subsections.map((subsection, i) => <StandardSubsection key={`${standard}.${year}.${subsection}.${i}`} parent={edition[chapter]} chapter={chapter} subsection={subsection} />)}
            </Box>
        );
    });

    return (
        <Box sx={{fontFamily: 'Libre Baskerville, serif'}}>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, fontWeight: 'bold', mb: 4}}>
                <Box sx={{fontSize: '0.7rem'}}>NFPA {standard}</Box>
                <Box sx={{fontSize: '0.7rem'}}>Standard for</Box>
                <Box>{title}</Box>
                <Box sx={{fontSize: '0.7rem'}}>{year} Edition</Box>
            </Box>

            {renderedChapters}
        </Box>
    );
};

const Container = () => {
    const {standard: defaultStandard = 1001, edition: defaultEdition = 2019} = useParams();
    const editions = NFPA[defaultStandard]?.editions || [];

    const methods = useForm({
        defaultValues: {
            filter: '',
            standard: defaultStandard,
            edition: editions[defaultEdition] ? defaultEdition : Object.keys(editions)[0]
        },
        mode: 'onChange'
    });

    const {watch, setValue} = methods;

    const standard = watch('standard');
    const edition = watch('edition');

    useEffect(() => {
        if (standard) {
            const editions = NFPA[standard]?.editions || [];

            if (!edition || !editions || !editions[edition]) {
                setValue('edition', Object.keys(editions)[0]);
            }
        }
    }, [setValue, standard, edition]);

    return (
        <FormProvider {...methods}>
            <Box sx={{display: 'flex', mb: 2}}>
                <SelectField
                    name="standard"
                    label="Standard"
                    options={Object.keys(NFPA).map(key => {
                        const {title} = NFPA[key] || {};

                        return {
                            value: key,
                            label: `NFPA ${key}: ${title}`
                        };
                    })}
                    sx={{mr: 1, flex: 2}}
                />
                <SelectField
                    name="edition"
                    label="Edition"
                    options={Object.keys(NFPA[standard]?.editions || {}).map(key => ({value: key, label: key}))}
                    sx={{width: 120}}
                />
                {/* <TextField name="filter" label="Filter" sx={{flex: 3, ml: 1}} /> */}
            </Box>

            {standard && edition && (
                <Standard standard={standard} year={edition} />
            )}
        </FormProvider>
    );
};

export default Container;