import React, {useState, useCallback, useContext, useEffect} from 'react';
import {Button, Box, Grid} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {doc, collection, getDoc, addDoc, updateDoc, deleteDoc} from 'firebase/firestore';
import {useForm, FormProvider} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {get} from 'lodash';

import {UserContext} from '../contexts/User';
import {StationsContext} from '../contexts/Stations';
import {db, storage} from '../firebase';

import {processRawDoc, ensureMomentDates, ensureJSDates, uploadImage} from '../data/utils';

import TextField from '../form/TextField.js';
import SelectField from '../form/SelectField.js';
import StationsField from '../form/StationsField.js';
import ImageUploadField from '../form/ImageUploadField';

export default function Apparatus() {
    const {id: uid} = useParams();
    const isNew = !uid;

    const [loading, setLoading] = useState(!isNew);
    const [deleting, setDeleting] = useState(false);
    const {currentUser} = useContext(UserContext);
    const {stations} = useContext(StationsContext);
    const navigate = useNavigate();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const {isAdmin} = currentUser;

    const methods = useForm({
        defaultValues: {
            type: 'ENGINE',
            station: null,
            tag: null,
            color: '',
            imageUrl: '',
            imageThumbnailUrl: '',
            imageFile: ''
        },
        mode: 'onChange'
    });
    const {handleSubmit, formState, reset, watch} = methods;
    const {isValid} = formState;

    const type = watch('type');
    const stationUid = watch('station');
    const station = stationUid && stations.find(station => station.uid === stationUid);
    const tagPlaceholder = (type && station) ? `${type[0]}${get(station, 'name')}` : null;

    useEffect(() => {
        let isSubscribed = true;

        if (isNew && !isAdmin) {
            navigate('/apparatus');
            return;
        }

        const fetch = async() => {
            if (uid && !isNew) {
                const docRef = doc(db, 'apparatus', uid);
                const raw = await getDoc(docRef);
                const row = processRawDoc(raw);
                
                if (isSubscribed) {
                    reset(ensureMomentDates(row));

                    setLoading(false);
                }
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [db, isNew, reset, uid, isAdmin, navigate]);

    const handleDelete = () => {
        const onDelete = async() => {
            setDeleting(true);

            const ref = doc(db, 'apparatus', uid);
            await deleteDoc(ref);

            navigate('/apparatus');
        };

        enqueueSnackbar('Are you sure you want to delete this apparatus?', {
            variant: 'warning',
            action: key => {
                return (
                    <>
                        <Button onClick={() => {
                            closeSnackbar(key);
                            onDelete();
                        }}>
                            Delete
                        </Button>
                        <Button onClick={() => closeSnackbar(key)}>
                            Cancel
                        </Button>
                    </>
                );
            }
        });
    };

    const onSubmit = useCallback(async data => {
        setLoading(true);

        try {
            const {tag, type, station, color, imageFile} = data;

            if (isNew) {
                let docRef = collection(db, 'apparatus');
                let image;
                if (imageFile) {
                    await uploadImage(`apparatus/${uid}`, imageFile);
                } else {
                    image = null;
                }

                const {id: uid} = await addDoc(docRef, ensureJSDates({
                    type,
                    station,
                    color,
                    tag: tag || tagPlaceholder,
                    image
                }));

                navigate(`/apparatus/${uid}`);
            } else {
                let image;
                if (imageFile) {
                    image = await uploadImage(`apparatus/${uid}`, imageFile);
                } else if (imageFile == null) {
                    image = null;
                }

                const toUpdate = ensureJSDates({
                    type,
                    station,
                    color,
                    tag: tag || tagPlaceholder,
                    image
                });

                const docRef = doc(db, 'apparatus', uid);
                await updateDoc(docRef, toUpdate);

                console.log(toUpdate);

                reset(ensureMomentDates(toUpdate));

                enqueueSnackbar('Apparatus updated', {variant: 'success'});
            }
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    }, [db, isNew, uid, navigate, storage, enqueueSnackbar]);

    return (
        <FormProvider {...methods}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <ImageUploadField name="image" />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            label="Tag"
                            name="tag"
                            required
                            placeholder={tagPlaceholder}
                            disabled={loading}
                        />
                        <SelectField
                            sx={{mt: 2}}
                            fullWidth
                            required
                            label="Type"
                            name="type"
                            disabled={loading}
                            options={[
                                {value: 'ENGINE', label: 'Engine'},
                                {value: 'PUMPER', label: 'Pumper'},
                                {value: 'TENDER', label: 'Tender'},
                                {value: 'BUSH', label: 'Bush Truck'},
                                {value: 'LADDER', label: 'Ladder'},
                                {value: 'RESCUE', label: 'Rescue Truck'},
                                {value: 'UTILITY', label: 'Utility Truck'},
                                {value: 'CAR', label: 'Car'}
                            ]}
                        />
                        <StationsField
                            sx={{mt: 2}}
                            fullWidth
                            required
                            disabled={loading}
                        />
                        <TextField
                            sx={{mt: 2}}
                            fullWidth
                            label="Color"
                            name="color"
                            disabled={loading}
                        />

                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mt: 2}}>
                            {!isNew && (
                                <LoadingButton
                                    variant="outlined"
                                    sx={{mr: 1}}
                                    onClick={handleDelete}
                                    disabled={deleting || loading}
                                >
                                    Delete
                                </LoadingButton>
                            )}

                            <LoadingButton
                                type="submit"
                                variant="contained"
                                onClick={handleSubmit(onSubmit)}
                                disabled={loading || !isValid || deleting}
                                loading={loading}
                            >
                                Save Apparatus
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </FormProvider>
    );
}