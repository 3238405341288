import React, {useContext, useState, useEffect, useCallback, useMemo} from 'react';
import {Button, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {useForm, FormProvider} from 'react-hook-form';
import {LoadingButton} from '@mui/lab';

import {UserContext} from '../../contexts/User';

import TextField from '../../form/TextField';
import UserAutocompleteField from '../../form/UserAutocompleteField';
import FileField from '../../form/FileField';

import ResponsiveDialog from '../../components/ResponsiveDialog';

export default function TaskCompletionDialog({onSubmit: onUpdate, record, open, onClose}) {
    const [loading, setLoading] = useState(false);
    const {currentUser} = useContext(UserContext);
    const lastSelectedUser = localStorage.getItem('lastSelectedUser');

    const defaultValues = useMemo(() => {
        const {assignee, completedBy} = record || {};

        const {uid, role} = currentUser || {};
        let users = [];

        if (lastSelectedUser) {
            users.push(lastSelectedUser);
        } else if (role !== 'STATION') {
            users.push(uid);
        }

        return {
            ...record,
            files: [],
            completedNote: '',
            completedBy: completedBy || assignee || uid
        };
    }, [record, currentUser, lastSelectedUser]);

    const methods = useForm({
        defaultValues,
        mode: 'onChange'
    });
    const {reset, handleSubmit, watch} = methods;
    const users = watch('users', []);

    useEffect(() => {
        reset(defaultValues);
    }, [reset, defaultValues, record]);

    useEffect(() => {
        const [user] = users || [];
        if (user) {
            const {uid} = typeof user === 'string' ? {uid: user} : user;
            const {uid: currentUserUid} = currentUser || {};

            if (currentUserUid !== uid) {
                localStorage.setItem('lastSelectedUser', uid);
            }
        }
    }, [users, currentUser]);

    const onSubmit = useCallback(async data => {
        setLoading(true);

        const result = await onUpdate(data);
        if (result) {
            onClose();
            reset({});
        }

        setLoading(false);
    }, [onClose, reset, onUpdate]);

    return (
        <FormProvider {...methods}>
            <ResponsiveDialog
                open={open}
                onClose={onClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit(onSubmit)
                }}
            >
                <DialogTitle>Complete Task</DialogTitle>
                <DialogContent dividers>
                    <TextField
                        name="completedNote"
                        label="Note"
                        fullWidth
                        disabled={!onUpdate || loading}
                    />
                    <UserAutocompleteField
                        sx={{mt: 2}}
                        name="completedBy"
                        label="Completed By"
                        multiple={false}
                        required
                        fullWidth
                        disabled={!onUpdate || loading}
                    />
                    <FileField
                        sx={{mt: 2}}
                        name="files"
                        placeholder="Attach Files"
                        label="Attach Files"
                        multiple
                        fullWidth
                        accept="image/*,video/*,application/pdf,.txt,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        disabled={!onUpdate || loading}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} disabled={loading}>Cancel</Button>
                    <LoadingButton variant="contained" loading={loading} type="submit" disabled={loading}>Complete Task</LoadingButton>
                </DialogActions>
            </ResponsiveDialog>
        </FormProvider>
    );
};