import React, {useContext, useState, useEffect, useCallback, useMemo} from 'react';
import {Button, DialogActions, DialogContent, DialogTitle} from '@mui/material';

import {useForm, FormProvider} from 'react-hook-form';
import {pickBy} from 'lodash';
import {LoadingButton} from '@mui/lab';

import {UserContext} from '../../contexts/User';

import TextField from '../../form/TextField';
import UserAutocompleteField from '../../form/UserAutocompleteField';

import ResponsiveDialog from '../../components/ResponsiveDialog';

export default function RecordDialog({onSubmit: onUpdate, record, open, handleClose}) {
    const [loading, setLoading] = useState(false);
    const {currentUser} = useContext(UserContext);
    const lastSelectedUser = localStorage.getItem('lastSelectedUser');

    const defaultValues = useMemo(() => {
        const {uid, role} = currentUser || {};
        let users = [];

        if (lastSelectedUser) {
            users.push(lastSelectedUser);
        } else if (role !== 'STATION') {
            users.push(uid);
        }

        return {
            type: 'REFILL',
            note: '',
            users,
            ...pickBy(record)
        };
    }, [record, currentUser, lastSelectedUser]);
    const methods = useForm({
        defaultValues,
        mode: 'onChange'
    });
    const {reset, handleSubmit, watch} = methods;
    const users = watch('users', []);

    useEffect(() => {
        reset({
            ...defaultValues,
            ...pickBy(record)
        });
    }, [reset, defaultValues, record]);

    useEffect(() => {
        const [user] = users || [];
        if (user) {
            const {uid} = typeof user === 'string' ? {uid: user} : user;
            const {uid: currentUserUid} = currentUser || {};

            if (currentUserUid !== uid) {
                localStorage.setItem('lastSelectedUser', uid);
            }
        }
    }, [users, currentUser]);

    const onSubmit = useCallback(async data => {
        setLoading(true);

        const result = await onUpdate(data);
        if (result) {
            handleClose();
            reset({});
        }

        setLoading(false);
    }, [handleClose, reset, onUpdate]);

    return (
        <FormProvider {...methods}>
            <ResponsiveDialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit(onSubmit)
                }}
            >
                <DialogTitle>Refill Cylinder</DialogTitle>
                <DialogContent dividers>
                    <TextField
                        name="note"
                        label="Note"
                        fullWidth
                        disabled={!onUpdate || loading}
                    />
                    <UserAutocompleteField
                        sx={{mt: 2}}
                        name="users"
                        label="Members"
                        required
                        fullWidth
                        disabled={!onUpdate || loading}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={loading}>Cancel</Button>
                    <LoadingButton variant="contained" loading={loading} type="submit" disabled={loading}>Complete Refill</LoadingButton>
                </DialogActions>
            </ResponsiveDialog>
        </FormProvider>
    );
};