import React, {useState, useCallback, useLayoutEffect, useRef} from 'react';
import {useGridApiContext} from '@mui/x-data-grid';
import {Chip, Autocomplete, TextField} from '@mui/material';
import moment from 'moment';

import CRUDDataGrid from '../../components/CRUDDataGrid';

const AutocompleteTags = params => {
    const {id, field, value: rawValue, hasFocus} = params || {};
    const [value, setValue] = useState(rawValue || []);
    const apiRef = useGridApiContext();
    const ref = useRef();

    const handleChange = useCallback((event, newValue) => {
        setValue(newValue);

        apiRef.current.setEditCellValue({id, field, value: newValue});

        event.stopPropagation();
    }, [apiRef, id, field]);

    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current.focus();
        }
    }, [hasFocus]);

    return (
        <Autocomplete
            ref={ref}
            multiple
            freeSolo
            value={value}
            onChange={handleChange}
            options={[]}
            fullWidth
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    const {key, ...rest} = getTagProps({index})
                    return (
                        <Chip label={option} key={key} {...rest} />
                    );
                })
            }
            sx={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', pl: 1}}
            renderInput={params => (
                <TextField
                    {...params}
                    variant="standard"
                    InputProps={{
                        disableUnderline: true,
                        ...params.InputProps
                    }}
                    placeholder="Tags"
                />
            )}
        />
    );
};

export default function() {
    const columns = [
        {field: 'name', headerName: 'Name', flex: 1, editable: true},
        {
            field: 'tags',
            headerName: 'Tags for Import',
            editable: true,
            flex: 2,
            renderCell: params => {
                const {value, row} = params || {};
                if (!value) {
                    return '';
                }

                const {id} = row || {};

                return value.map((tag, index) => {
                    return (
                        <Chip key={`${id}-${index}`} sx={{mr: 1}} label={tag} />
                    );
                });
            },
            renderEditCell: params => {
                const {key, ...rest} = params || {};

                return (
                    <AutocompleteTags key={key} {...rest} />
                );
            }
        },
        {
            field: 'updatedAt',
            headerName: 'Last Updated',
            width: 150,
            valueFormatter: value => {
                if (!value) {
                    return '';
                }

                return moment(value).fromNow();
            }
        }
    ];

    return (
        <CRUDDataGrid
            collection="trainingTypes"
            label="Training Type"
            initialState={{
                sorting: {
                    sortModel: [
                        {field: 'updatedAt', sort: 'desc'}
                    ]
                }
            }}
            columns={columns}
        />
    );
};