import {alpha} from '@mui/material/styles';
import {red, gray, orange, yellow} from '../themePrimitives';

export const surfacesCustomizations = {
    MuiAccordion: {
        defaultProps: {
            elevation: 0,
            disableGutters: true
        },
        styleOverrides: {
            root: ({theme}) => ({
                padding: 4,
                overflow: 'clip',
                backgroundColor: (theme.vars || theme).palette.background.default,
                border: '1px solid',
                borderColor: (theme.vars || theme).palette.divider,
                ':before': {
                    backgroundColor: 'transparent'
                },
                '&:not(:last-of-type)': {
                    borderBottom: 'none'
                },
                '&:first-of-type': {
                    borderTopLeftRadius: (theme.vars || theme).shape.borderRadius,
                    borderTopRightRadius: (theme.vars || theme).shape.borderRadius
                },
                '&:last-of-type': {
                    borderBottomLeftRadius: (theme.vars || theme).shape.borderRadius,
                    borderBottomRightRadius: (theme.vars || theme).shape.borderRadius
                }
            })
        }
    },
    MuiAccordionSummary: {
        styleOverrides: {
            root: () => ({
                border: 'none',
                borderRadius: 8,
                '&:hover': {backgroundColor: gray[50]},
                '&:focus-visible': {backgroundColor: 'transparent'},
                '.Mui-expanded &': {
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0
                }
            })
        }
    },
    MuiAccordionDetails: {
        styleOverrides: {
            root: {mb: 20, border: 'none'}
        }
    },
    MuiPaper: {
        defaultProps: {
            elevation: 0
        }
    },
    MuiCard: {
        styleOverrides: {
            root: ({theme}) => {
                return {
                    // padding: 16,
                    // gap: 16,
                    transition: 'all 100ms ease',
                    backgroundColor: gray[50],
                    borderRadius: (theme.vars || theme).shape.borderRadius,
                    border: `1px solid ${(theme.vars || theme).palette.divider}`,
                    boxShadow: 'none',
                    variants: [
                        {
                            props: {
                                variant: 'outlined'
                            },
                            style: {
                                border: `1px solid ${(theme.vars || theme).palette.divider}`,
                                boxShadow: 'none',
                                background: 'hsl(0, 0%, 100%)'
                            }
                        },
                        {
                            props: {
                                variant: 'outlined',
                                severity: 'error'
                            },
                            style: {
                                backgroundColor: red[50],
                                border: `1px solid ${alpha(red[100], 0.5)}`,
                                '& .MuiAlert-icon': {
                                    color: red[500]
                                },
                                '& .MuiButton-text': {
                                    '&:hover': {
                                        backgroundColor: alpha(red[100], 0.5)
                                    }
                                }
                            }
                        },
                        {
                            props: {
                                variant: 'outlined',
                                severity: 'warning'
                            },
                            style: {
                                backgroundColor: alpha(orange[50], 0.7),
                                border: `1px solid ${alpha(orange[200], 0.5)}`,
                                '& .MuiAlert-icon': {
                                    color: orange[500]
                                },
                                '& .MuiButton-text': {
                                    '&:hover': {
                                        backgroundColor: alpha(orange[100], 0.5)
                                    }
                                },
                                '& .MuiIconButton-root': {
                                    backgroundColor: alpha(orange[100], 0.5),
                                    '&:hover': {
                                        backgroundColor: alpha(orange[100], 0.9)
                                    }
                                }
                            }
                        }
                    ]
                };
            }
        }
    },
    MuiCardContent: {
        styleOverrides: {
            root: {
                // padding: 0,
                '&:last-child': {paddingBottom: 16}
            }
        }
    }
    // MuiCssBaseline: {
    //     styleOverrides: {
    //         body: theme => ({
    //             backgroundColor: (theme.vars || theme).palette.background.paper
    //         })
    //     }
    // }
};
