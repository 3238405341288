import React, {useState, useContext, useEffect, useCallback, useMemo} from 'react';
import {Button, Box, IconButton, Typography, Grid2 as Grid, Divider, DialogActions, DialogContent, DialogTitle, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper} from '@mui/material';
import {useForm, useFieldArray, FormProvider} from 'react-hook-form';
import {get, pickBy} from 'lodash';
import {LoadingButton} from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';

import {UserContext} from '../../contexts/User';

import TextField from '../../form/TextField';
import DatePickerField from '../../form/DatePickerField';
import UserAutocompleteField from '../../form/UserAutocompleteField';
import SelectField from '../../form/SelectField';
import CheckboxField from '../../form/CheckboxField';
import ToggleButtonField from '../../form/ToggleButtonField';

import ResponsiveDialog from '../../components/ResponsiveDialog';

import {TaskStatuses, TaskFrequencies, ensureJSDates} from '../../data/utils';

const FilesGridField = ({name, ...rest}) => {
    const {fields, remove} = useFieldArray({
        name
    });

    const handleDownload = file => {
        const {url} = file;
        window.open(url, '_blank');
    };

    return (
        <Box {...rest}>
            <TableContainer component={Paper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{px: 1, flex: 1}}>Name</TableCell>
                            <TableCell sx={{px: 1, width: 95}}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.length === 0 && (
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell colSpan={2} align="center">
                                    No files
                                </TableCell>
                            </TableRow>
                        )}
                        {fields.map((field, index) => (
                            <TableRow key={field.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell sx={{pl: 1, pr: 0, py: 1}}>
                                    <Typography variant="body">{field.name}</Typography>
                                </TableCell>
                                <TableCell sx={{pl: 1, pr: 0, py: 1}}>
                                    <IconButton onClick={() => handleDownload(field)}>
                                        <DownloadIcon />
                                    </IconButton>
                                    <IconButton onClick={() => remove(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
};

export default function TaskDialog({onSubmit: onUpdate, onDelete, item, open, handleClose}) {
    const [loading, setLoading] = useState(false);
    const methods = useForm({
        defaultValues: useMemo(() => {
            return {
                name: '',
                notes: '',
                assignee: null,
                date: null,
                dateType: 'month',
                recurring: false,
                frequency: null,
                status: 'OPEN',
                ...pickBy(item)
            };
        }, [item]),
        mode: 'onChange'
    });
    const {reset, handleSubmit, watch} = methods;
    const recurring = watch('recurring');
    const status = watch('status');
    const dateType = watch('dateType');
    const files = watch('files');
    const {currentUser} = useContext(UserContext);
    const {isAdmin} = currentUser || {};
    const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';

    const isComplete = status === 'COMPLETE';
    const hasFiles = files && files.length > 0;

    useEffect(() => {
        reset({
            name: '',
            notes: '',
            assignee: null,
            date: null,
            dateType: 'month',
            recurring: false,
            frequency: null,
            status: 'OPEN',
            ...pickBy(item)
        });
    }, [reset, item]);

    const handleDelete = async () => {
        setLoading();

        const result = await onDelete(item);
        if (result) {
            handleClose();
            reset({});
        }

        setLoading(false);
    };

    const onClose = () => {
        handleClose();
        reset({});
    };

    const onSubmit = useCallback(async data => {
        setLoading(true);

        const result = await onUpdate(ensureJSDates(data));
        
        if (result) {
            handleClose();
        }

        setLoading(false);
    }, [handleClose, onUpdate]);

    const dueDateViews = dateType === 'year' ? ['year'] : dateType === 'month' ? ['month', 'year'] : ['year', 'month', 'day'];
    const dueDateFormat = dateType === 'year' ? 'YYYY' : dateType === 'month' ? 'MM/YYYY' : dateFormat;

    return (
        <FormProvider {...methods}>
            <ResponsiveDialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit(onSubmit)
                }}
            >
                <DialogTitle>{item ? 'Update Task' : 'Add New Task'}</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid size={{xs: 12, sm: 6}}>
                            <TextField
                                required
                                name="name"
                                label="Name"
                                placeholder={'Organize SCBA fit test'}
                                fullWidth
                                disabled={loading || isComplete}
                            />
                        </Grid>
                        <Grid size={{xs: 12, sm: 6}}>
                            <UserAutocompleteField
                                name="assignee"
                                label="Assignee"
                                multiple={false}
                                fullWidth
                                disabled={loading || isComplete}
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        sx={{mt: 2}}
                        name="notes"
                        label="Notes"
                        multiline
                        placeholder={'Normally organzed using Brogan.'}
                        fullWidth
                        disabled={loading || isComplete}
                    />
                    {(item && isAdmin) && (
                        <SelectField
                            sx={{mt: 2}}
                            name="status"
                            label="Status"
                            options={Object.keys(TaskStatuses).map(value => ({label: TaskStatuses[value], value}))}
                            fullWidth
                            disabled={loading}
                        />
                    )}

                    <Typography variant="h6" sx={{mt: 2}}>Date</Typography>
                    <Divider sx={{mb: 2}} />

                    <Box sx={{display: 'flex', alignItems: 'stretch', mt: 2}}>
                        <ToggleButtonField
                            name="dateType"
                            label="Date Type"
                            exclusive
                            sx={{mr: 1}}
                            options={[
                                {label: 'Day', value: 'day'},
                                {label: 'Month', value: 'month'},
                                {label: 'Year', value: 'year'}
                            ]}
                            disabled={loading || isComplete}
                        />

                        <DatePickerField
                            sx={{flex: 1}}
                            name="date"
                            label="Due Date"
                            fullWidth
                            format={dueDateFormat}
                            views={dueDateViews}
                            disabled={loading || isComplete}
                        />
                    </Box>

                    {!isComplete && (
                        <Box sx={{display: 'flex', mt: 2}}>
                            <CheckboxField
                                name="recurring"
                                label="Recurring"
                                disabled={loading || isComplete}
                            />
                            {recurring && (
                                <SelectField
                                    sx={{ml: 2}}
                                    name="frequency"
                                    label="Frequency"
                                    options={Object.keys(TaskFrequencies).map(value => ({label: TaskFrequencies[value], value}))}
                                    fullWidth
                                    disabled={loading || isComplete}
                                />
                            )}
                        </Box>
                    )}

                    {(item && hasFiles) && (
                        <>
                            <Typography variant="h6" sx={{mt: 2}}>Files</Typography>
                            <Divider sx={{mb: 2}} />

                            <FilesGridField name="files" sx={{mt: 2}} />
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    {(item && onDelete) && (
                        <>
                            <Button onClick={handleDelete} disabled={loading}>Delete</Button>
                            <Box sx={{flex: 1}} />
                        </>
                    )}
                    <Button onClick={onClose} disabled={loading}>Cancel</Button>
                    <LoadingButton variant="contained" type="submit" loading={loading} disabled={loading}>{item ? 'Update' : 'Add'}</LoadingButton>
                </DialogActions>
            </ResponsiveDialog>
        </FormProvider>
    );
};