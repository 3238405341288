import React, {useEffect, useRef} from 'react';
import MapView from '@arcgis/core/views/MapView';
import Map from '@arcgis/core/Map';
// import Graphic from '@arcgis/core/Graphic';
import Basemap from '@arcgis/core/Basemap';
import TileLayer from '@arcgis/core/layers/TileLayer';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import {get} from 'lodash';

// import {ApiKeyManager} from "@esri/arcgis-rest-request";
// import {geocode} from "@esri/arcgis-rest-geocoding";

const GOOGLE_MAPS_API_KEY = 'AIzaSyCCI_7230YkA7R8LJXiqrg6_zVlfy9eSfk';

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

// const autocompleteService = {current: null};
const geocoderService = {current: null};

export default function MapComponent(props) {
    const {item} = props;
    const {location = ''} = item || {};
    let [simpleLocation] = location.match(/^([^,]+)/) || [];

    const loaded = React.useRef(false);

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
                document.querySelector('head'),
                'google-maps'
            );
        }

        loaded.current = true;
    }

    const mapDiv = useRef(null);
    let view;

    useEffect(() => {
        if (mapDiv.current) {
            const mapBaseLayer = new TileLayer({
                url: 'https://www.rdcogis.com/arcgis/rest/services/Basemaps/RDCO_Basemap/MapServer'
            });

            const customBasemap = new Basemap({
                baseLayers: [mapBaseLayer],
                title: 'Terrain'
            });

            const webmap = new Map({
                basemap: customBasemap
            });

            const parcelLayer = new FeatureLayer({
                url: 'https://www.rdcogis.com/arcgis/rest/services/GIS_App/GIS_App_BASE_lite/MapServer/44'
            });

            view = new MapView({
                container: mapDiv.current,
                map: webmap,
                center: [-119.247430, 49.859620],
                scale: 60000
            });

            view.when(async() => {
                const results = await parcelLayer.queryFeatures({
                    text: simpleLocation,
                    returnGeometry: true
                });

                const symbol = {
                    type: 'simple-fill',
                    color: [20, 130, 200, 0.5],
                    outline: {
                        color: 'white',
                        width: 1
                    }
                };

                results.features.map(feature => {
                    feature.symbol = symbol;
                    return feature;
                });

                view.graphics.addMany(results.features);
                
                const firstFeatureCenteroid = get(results, 'features[0].geometry.centroid');
                if (firstFeatureCenteroid) {
                    view.goTo({
                        center: results.features[0].geometry.centroid,
                        zoom: 17
                    });
                } else {
                    if (!geocoderService.current && window.google) {
                        geocoderService.current = new window.google.maps.Geocoder();
                    }
                    
                    geocoderService.current.geocode({address: location}, (responses, status) => {
                        if (status === 'OK') {
                            const lat = responses[0].geometry.location.lat();
                            const lng = responses[0].geometry.location.lng();

                            view.goTo({
                                center: [lng, lat],
                                zoom: 17
                            });
                        }
                    });

                    // const result = await geocode({
                    //     address: location,
                    //     countryCode: 'CA',
                    //     authentication: ApiKeyManager.fromKey('AAPTxy8BH1VEsoebNVZXo8HurE4IyEBQ8_awCGieoK3SDSCb8HyEkAgDnTNXdTEzzO0DrbLgIeiMYEfOPRc4y3cwg2BJoieB_AT1l7uf2HyBEQ2vHBuxJuO7tHn90gB-MJOf02ovro_mCPDURCP6-1UdlA4va300Kx_UiLMv4Wuoz2LWLWOgKU5XOqRAllzQS4-Y7sbEKjHvggJPXoqZwGELjD52uWmkVKkhZ6erXnSFh40.AT1_lCitiDTP')
                    // });

                    // console.log(simpleLocation, result)

                    // const match = get(result, 'candidates[0].extent');
                    // if (match) {
                    //     view.extent = match;
                    //     // view.goTo({
                    //     //     center: [match.x, match.y],
                    //     //     zoom: 16
                    //     // });
                    // }
                }
            });
        }
    }, []);

    return <div className="mapDiv" ref={mapDiv} style={{height: '100%', width: '100%'}}></div>;
}