import React, {useEffect, useContext, useState, useMemo, useCallback} from 'react';
import {Stack, Grid2 as Grid, Box, Button, IconButton, Typography, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@mui/material';
import {useForm, useFieldArray, FormProvider} from 'react-hook-form';
import * as MaterialIcons from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {camelCase} from 'lodash';
import {v4 as uuid} from 'uuid';

import * as Icons from '../../components/Icons';

import TextField from '../../form/TextField';
import SelectField from '../../form/SelectField';
import AutocompleteField from '../../form/AutocompleteField';
import ImageUploadField from '../../form/ImageUploadField';
import CollectionAutocompleteField from '../../form/CollectionAutocompleteField';

import ResponsiveDialog from '../../components/ResponsiveDialog';

import {UserContext} from '../../contexts/User';

const FieldsGridField = ({name, ...rest}) => {
    const {currentUser} = useContext(UserContext);
    const {isAdmin} = currentUser;
    const {fields, append, move, remove} = useFieldArray({
        name
    });

    return (
        <Box {...rest}>
            <TableContainer component={Paper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {isAdmin && <TableCell sx={{width: 130}}>Key</TableCell>}
                            <TableCell sx={{px: 1, flex: 1}}>Label</TableCell>
                            <TableCell sx={{px: 1, flex: 2}}>Type</TableCell>
                            <TableCell sx={{px: 1, width: 135}}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.length === 0 && (
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell colSpan={isAdmin ? 5 : 4} align="center">
                                    No fields
                                </TableCell>
                            </TableRow>
                        )}
                        {fields.map((field, index) => (
                            <TableRow key={field.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                {isAdmin && (
                                    <TableCell>
                                        <Typography variant="body" color="text.secondary">{camelCase(field.label)}</Typography>
                                    </TableCell>
                                )}
                                <TableCell sx={{pl: 1, pr: 0, py: 1}}>
                                    <TextField fullWidth size="small" label={false} placeholder="Label" name={`${name}.${index}.label`} />
                                </TableCell>
                                <TableCell sx={{px: 1, py: 1}}>
                                    <SelectField
                                        fullWidth
                                        placeholder="Type"
                                        label={false}
                                        name={`${name}.${index}.type`}
                                        size="small"
                                        options={[
                                            {value: 'text', label: 'Text'},
                                            {value: 'date', label: 'Date'},
                                            {value: 'apparatus', label: 'Apparatus'}
                                        ]}
                                    />
                                </TableCell>
                                <TableCell sx={{pl: 1, pr: 0, py: 1}}>
                                    <Stack direction="row" spacing={1}>
                                        <IconButton onClick={() => move(index, Math.max(0, index - 1))}>
                                            <ArrowDropUpIcon />
                                        </IconButton>
                                        <IconButton onClick={() => move(index, Math.min(index + 1, fields.length - 1))}>
                                            <ArrowDropDownIcon />
                                        </IconButton>
                                        <IconButton onClick={() => remove(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 1}}>
                <Button onClick={() => append({id: uuid()})} startIcon={<AddIcon />}>Add Field</Button>
            </Box>
        </Box>
    )
};

export default function CategoryDialog({onSubmit: onUpdate, onDelete, parent, item, open, handleClose}) {
    const [loading, setLoading] = useState(false);
    const methods = useForm({
        defaultValues: useMemo(() => {
            return item || {
                name: '',
                icon: '',
                imageUrl: '',
                imageThumbnailUrl: '',
                additionalFields: [],
                apparatus: ''
            };
        }, [item]),
        mode: 'onChange'
    });
    const {reset, handleSubmit} = methods;

    useEffect(() => {
        reset(item || {
            name: '',
            icon: '',
            imageUrl: '',
            imageThumbnailUrl: '',
            additionalFields: [],
            apparatus: ''
        });
    }, [item, reset]);

    const onSubmit = useCallback(async data => {
        setLoading(true);

        const {icon, additionalFields, ...rest} = data;

        const result = await onUpdate({
            ...rest,
            additionalFields: additionalFields.map(field => {
                const {label} = field;

                return {
                    ...field,
                    key: camelCase(label)
                };
            }),
            icon: icon && icon.value
        });

        if (result) {
            handleClose();
            reset({});
        }

        setLoading(false);
    }, [handleClose, reset, onUpdate]);

    const handleDelete = async () => {
        setLoading();

        const result = await onDelete(item);
        if (result) {
            handleClose();
            reset({});
        }

        setLoading(false);
    };

    const icons = [
        ...Object.keys(Icons).map(icon => {
            return {
                value: icon,
                label: icon
            };
        }),
        ...Object.keys(MaterialIcons).map(icon => {
            return {
                value: icon,
                label: icon
            };
        })
    ];

    const type = parent ? 'Subcategory' : 'Gear Category';

    return (
        <FormProvider {...methods}>
            <ResponsiveDialog
                open={open}
                onClose={handleClose}
                maxWidth="lg"
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit(onSubmit)
                }}
            >
                <DialogTitle>{item ? `Update ${type}` : `Add New ${type}`}</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        {item && (
                            <Grid size={{xs: 12, sm: 6, md: 4}}>
                                <ImageUploadField name="image" />
                            </Grid>
                        )}

                        <Grid size={{xs: 12, sm: item ? 6 : 12, md: item ? 8 : 12}}>
                            <TextField
                                sx={{mt: 1}}
                                required
                                name="name"
                                label="Name"
                                placeholder={parent ? 'Bottles, Packs, etc.' : 'SCBA, Ladders, etc.'}
                                fullWidth
                                disabled={loading}
                            />
                            <AutocompleteField
                                sx={{mt: 2}}
                                required
                                name="icon"
                                label="Icon"
                                options={icons}
                                multiple={false}
                                fullWidth
                                disabled={loading}
                                renderOption={(props, option) => {
                                    const {key, ...rest} = props;
                                    const {value, label} = option;
                                    const Icon = Icons[value] || MaterialIcons[value];
                                    
                                    return (
                                        <li key={key} {...rest}>
                                            <Box display={'flex'} flexDirection={'row'}>
                                                <Icon /> {label}
                                            </Box>
                                        </li>
                                    );
                                }}
                            />
                            <CollectionAutocompleteField
                                sx={{mt: 2}}
                                name="apparatus"
                                label="Apparatus"
                                collection="apparatus"
                                displayProperty="tag"
                                multiple={false}
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="h6" sx={{mt: 2}}>Additional Fields</Typography>
                    <Typography variant="body2" color="text.secondary">Add additional fields for items within this category</Typography>
                    <FieldsGridField name="additionalFields" sx={{mt: 2}} />
                </DialogContent>
                <DialogActions>
                    {(item && onDelete) && (
                        <>
                            <Button onClick={handleDelete} disabled={loading}>Delete</Button>
                            <Box sx={{flex: 1}} />
                        </>
                    )}

                    <Button onClick={handleClose} disabled={loading}>Cancel</Button>
                    <LoadingButton variant="contained" type="submit" loading={loading} disabled={loading}>{item ? 'Update' : 'Add'}</LoadingButton>
                </DialogActions>
            </ResponsiveDialog>
        </FormProvider>
    );
};