import React, {useRef, useState, useLayoutEffect, useEffect, useCallback, useContext} from 'react';
import {Chip, IconButton, Box, TextField, Autocomplete} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {doc, updateDoc} from 'firebase/firestore';
import {FormProvider, useForm, useFieldArray, useFormContext} from 'react-hook-form';
import Draggable from 'react-draggable';
import {sortBy} from 'lodash';
import useResizeObserver from '@react-hook/resize-observer';
import EditIcon from '@mui/icons-material/Edit';
import {v4 as uuid} from 'uuid';

import {UserContext} from '../../../contexts/User';

import {verifyOfficer} from '../../../data/utils';

import {useItems} from '../ItemsGrid';

function useSize(target) {
    const [size, setSize] = useState();
  
    useLayoutEffect(() => {
        const {current} = target || {};
        if (current) {
            setSize(current.getBoundingClientRect());
        }
    }, [target]);
  
    useResizeObserver(target, entry => setSize(entry.contentRect));
    return size;
};

const MarkerSize = 30;

const Marker = ({label, position: centerPosition, scale, onDrag, onStop, ...rest}) => {
    const nodeRef = useRef(null);

    const handleDrag = useCallback((e, data) => {
        const {x, y} = data;
        const centerX = (x + MarkerSize / 2) / scale;
        const centerY = (y + MarkerSize / 2) / scale;
        onDrag({x: centerX, y: centerY});
    }, [scale]);

    const handleStop = useCallback((e, data) => {
        const {x, y} = data;
        const centerX = (x + MarkerSize / 2) / scale;
        const centerY = (y + MarkerSize / 2) / scale;
        // setCenterPosition({x: centerX, y: centerY});
        onStop({x: centerX, y: centerY});
    }, []);
 
    const topLeftPosition = {
        x: centerPosition.x * scale - MarkerSize / 2,
        y: centerPosition.y * scale - MarkerSize / 2
    };

    return (
        <Draggable
            nodeRef={nodeRef}
            bounds="parent"
            onDrag={handleDrag}
            onStop={handleStop}
            position={topLeftPosition}
            {...rest}
        >
            <Chip ref={nodeRef} variant="tooltip" sx={{position: 'absolute'}} label={label} />
        </Draggable>
    )
};

const PlacementView = ({imageUrl}) => {
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);

    const {items, refArgs} = useItems();

    const {currentUser} = useContext(UserContext);
    const isOfficer = verifyOfficer(currentUser);
    const {setValue, watch} = useFormContext();
    const placements = watch('placements');

    const [imageRef, setImageRef] = useState();
    const {width = 1024, height} = useSize(imageRef) || {};
    const scale = width / 1024;

    const {fields, update, replace} = useFieldArray({
        name: 'placements'
    });

    const handleDrag = (index, field, position) => {
        setValue(`placements[${index}].position`, position);
    };
    
    const handleDragStop = async(index, field, position) => {
        const {uid} = field;

        update(index, {
            ...field,
            position
        });

        setLoading(true);

        const ref = doc(...refArgs, uid);
        await updateDoc(ref, {
            position
        });

        setLoading(false);
    };

    const handleRemove = async item => {
        setLoading(true);

        const {uid} = item;

        const ref = doc(...refArgs, uid);
        await updateDoc(ref, {
            position: null
        });

        setLoading(false);
    };

    const handleChange = useCallback((e, value) => {
        const removed = placements.filter(({uid}) => !value.find(item => item.uid === uid));

        replace(value);

        for (const item of removed) {
            handleRemove(item);
        }
    }, [placements]);

    return (
        <>
            <Box sx={{display: 'flex', borderRadius: 1, overflow: 'hidden', position: 'relative'}}>
                <Box
                    draggable={false}
                    ref={setImageRef}
                    component="img"
                    src={imageUrl}
                    sx={{
                        width: '100%',
                        opacity: 0.6,
                        '-webkit-user-select': 'none',
                        '-khtml-user-select': 'none',
                        '-moz-user-select': 'none',
                        '-o-user-select': 'none',
                        'user-select': 'none'
                    }}
                />

                {fields.map((field, index) => {
                    const {id, name, position} = field;

                    return (
                        <Marker
                            key={`draggable-${id}`}
                            disabled={!editing || loading}
                            label={name}
                            position={position || {x: (width / scale) / 2, y: (height / scale) / 2}}
                            scale={scale}
                            onDrag={position => handleDrag(index, field, position)}
                            onStop={position => handleDragStop(index, field, position)}
                        />
                    );
                })}
            </Box>

            {isOfficer && (
                <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                    {editing && (
                        <Autocomplete
                            sx={{mr: 1}}
                            options={sortBy(items, 'createdAt')}
                            multiple
                            getOptionLabel={option => option.name}
                            isOptionEqualToValue={(option, value) => option.uid === value.uid}
                            fullWidth
                            renderInput={params => <TextField {...params} label="Items" />}
                            value={fields}
                            filterSelectedOptions
                            onChange={handleChange}
                        />
                    )}
                    
                    {editing ? (
                        <LoadingButton size="large" loading={loading} disabled={loading} variant="contained" color="primary" onClick={() => setEditing(false)}>Finish</LoadingButton>
                    ) : (
                        <IconButton disabled={loading} onClick={() => setEditing(true)}>
                            <EditIcon />
                        </IconButton>
                    )}
                </Box>
            )}
        </>
    );
};

export default function(props) {
    const {imageUrl, ...rest} = props;
    const {items} = useItems();

    const placements = items.filter(item => item.position).map(item => {
        const {uid, name, position} = item;
        
        return {
            id: uuid(),
            uid,
            name,
            position
        };
    });

    const methods = useForm({
        defaultValues: {
            placements
        }
    });

    const {reset} = methods;

    useEffect(() => {
        reset({
            placements
        });
    }, [items]);

    return (
        <FormProvider {...methods}>
            <PlacementView imageUrl={imageUrl} {...rest} />
        </FormProvider>
    );
};