import React, {useEffect, useState} from 'react';
import {Card, CardMedia, Box} from '@mui/material';
import {ref, getDownloadURL} from 'firebase/storage';
import HideImageIcon from '@mui/icons-material/HideImage';

import {storage} from '../firebase';

const ImageCard = ({image, imageUrl, ...rest}) => {
    return (
        <Card sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', ...rest}}>
            <CardMedia
                sx={{aspectRatio: '16/9', width: '100%', borderRadius: 0.5, backgroundColor: 'background.paper'}}
                image={imageUrl}
            >
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', color: 'text.secondary', opacity: 0.5}}>
                    {!image && (
                        <HideImageIcon sx={{color: 'grey.500', fontSize: 40}} />
                    )}
                </Box>
            </CardMedia>
        </Card>
    );
};

const Image = ({image, imageUrl,  ...rest}) => {
    if (!image) {
        return;
    }

    return (
        <Box component="img" src={imageUrl} {...rest} />
    );
};

export default function(props) {
    const {image, type, ...rest} = props;
    const [imageUrl, setImageUrl] = useState(null);
    
    useEffect(() => {
        const {filePath, thumbnailPath} = image || {};

        let isSubscribed = true;

        const fetch = async() => {
            if (thumbnailPath) {
                const url = await getDownloadURL(ref(storage, thumbnailPath));
                if (isSubscribed) {
                    setImageUrl(url)
                }

                return;
            }

            if (filePath) {
                const url = await getDownloadURL(ref(storage, filePath));
                if (isSubscribed) {
                    setImageUrl(url)
                }

                return;
            }
        };

        if (image) {
            fetch();
        }

        return () => isSubscribed = false;
    }, [image]);

    if (type === 'card') {
        return <ImageCard image={image} imageUrl={imageUrl} {...rest} />;
    }

    return <Image image={image} imageUrl={imageUrl} {...rest} />;
};