import React from 'react';
import {Box, TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody, TextField} from '@mui/material';
import {People as PeopleIcon} from '@mui/icons-material';
import {isNil, isNaN, sortBy} from 'lodash';

const HoursTable = props => {
    const {members = [], defaultSalary = 0, salaryOverrides = {}, handleHourChange} = props;
    
    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableCell sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <PeopleIcon sx={{fontSize: 20}} />
                        <Box sx={{ml: 1}}>Members</Box>
                    </TableCell>
                    <TableCell>Hours</TableCell>
                </TableHead>
                <TableBody>
                    {members.length ? sortBy(members, ['lastName', 'firstName']).map((row, index) => {
                        const {fullName} = row || {};
                        const value = (salaryOverrides || {})[row.id];

                        return (
                            <TableRow
                                key={`detail-member-${index}`}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell scope="row">
                                    {fullName}
                                </TableCell>
                                <TableCell scope="row">
                                    <TextField
                                        size="small"
                                        sx={{width: 50}}
                                        hiddenLabel
                                        variant="standard"
                                        type="number"
                                        inputProps={{min: 0, step: 0.5}}
                                        placeholder={`${defaultSalary}`}
                                        value={isNil(value) || isNaN(value) ? '' : value}
                                        onChange={e => handleHourChange(e, row)}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    }) : (
                        <TableRow>
                            <TableCell colSpan={2} sx={{textAlign: 'center'}}>
                                No members
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default HoursTable;