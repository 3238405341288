import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {Grid2 as Grid, Box, Button, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {useForm, FormProvider} from 'react-hook-form';
import {LoadingButton} from '@mui/lab';

import TextField from '../../form/TextField';
import DatePickerField from '../../form/DatePickerField';
import CollectionAutocompleteField from '../../form/CollectionAutocompleteField';
import ImageUploadField from '../../form/ImageUploadField';

import ResponsiveDialog from '../../components/ResponsiveDialog';

export default function ItemDialog({onSubmit: onUpdate, onDelete, parent, item, open, handleClose}) {
    const [loading, setLoading] = useState(false);
    const methods = useForm({
        defaultValues: useMemo(() => {
            const {additionalFields = []} = parent || {};

            return item || {
                name: '',
                identifier: '',
                apparatus: null,
                ...additionalFields.reduce((result, field) => {
                    const {name} = field;

                    return {
                        ...result,
                        [name]: ''
                    };
                }, {})
            };
        }, [item, parent]),
        mode: 'onChange'
    });
    const {reset, handleSubmit} = methods;

    const {additionalFields = []} = parent || {};

    useEffect(() => {
        reset(item || {
            name: '',
            identifier: '',
            inServiceDate: null,
            expiryDate: null
        });
    }, [reset, item]);

    const handleDelete = async () => {
        setLoading();

        const result = await onDelete(item);
        if (result) {
            handleClose();
            reset({});
        }

        setLoading(false);
    };

    const onSubmit = useCallback(async data => {
        setLoading(true);

        const {inServiceDate: inServiceDateRaw, expiryDate: expiryDateRaw, identifier, ...rest} = data;
        const inServiceDate = inServiceDateRaw ? inServiceDateRaw.toDate() : null;
        const expiryDate = expiryDateRaw ? expiryDateRaw.toDate() : null;

        const result = await onUpdate({
            ...rest,
            inServiceDate,
            expiryDate,
            identifier: identifier || null
        });
        
        if (result) {
            handleClose();
            reset({});
        }

        setLoading(false);
    }, [handleClose, onUpdate, reset]);

    return (
        <FormProvider {...methods}>
            <ResponsiveDialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit(onSubmit)
                }}
            >
                <DialogTitle>{item ? 'Update Item' : 'Add New Item'}</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        {item && (
                            <Grid size={{xs: 12, sm: 6}}>
                                <ImageUploadField name="image" />
                            </Grid>
                        )}
                        <Grid size={{xs: 12, sm: item ? 6 : 12}}>
                            <TextField
                                sx={{mt: 1}}
                                required
                                autoFocus
                                name="name"
                                label="Name"
                                placeholder={'Scott X3 Pro'}
                                fullWidth
                                disabled={loading}
                            />

                            {additionalFields.map((field, index) => {
                                const {label, key: name, type} = field;
                                const key = `additional-field-${index}`;

                                const base = {
                                    sx: {mt: 2},
                                    name,
                                    label,
                                    fullWidth: true,
                                    disabled: loading
                                };

                                if (type === 'date') {
                                    return (
                                        <DatePickerField
                                            key={key}
                                            {...base}
                                        />
                                    )
                                } else if (type === 'apparatus') {
                                    return (
                                        <CollectionAutocompleteField
                                            key={key}
                                            {...base}
                                            collection="apparatus"
                                            displayProperty="tag"
                                            multiple={false}
                                        />
                                    )
                                }

                                return (
                                    <TextField
                                        key={key}
                                        {...base}
                                    />
                                );
                            })}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {(item && onDelete) && (
                        <>
                            <Button onClick={handleDelete} disabled={loading}>Delete</Button>
                            <Box sx={{flex: 1}} />
                        </>
                    )}
                    <Button onClick={handleClose} disabled={loading}>Cancel</Button>
                    <LoadingButton loading={loading} variant="contained" type="submit" disabled={loading}>{item ? 'Update' : 'Add'}</LoadingButton>
                </DialogActions>
            </ResponsiveDialog>
        </FormProvider>
    );
};