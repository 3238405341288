import React, {useState, useEffect, useCallback, useContext} from 'react';
import {useTheme} from '@mui/material/styles';
import {Box, CircularProgress, Divider, useMediaQuery, Accordion, Button, AccordionSummary, AccordionDetails, Typography, TextField} from '@mui/material';
import {useParams, useNavigate} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import CheckIcon from '@mui/icons-material/Check';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import moment from 'moment';
import {LoadingButton} from '@mui/lab';
import {doc, onSnapshot, updateDoc, deleteDoc} from 'firebase/firestore';
import useDebouncedEffect  from  'use-debounced-effect';
import {useForm, FormProvider} from 'react-hook-form';
import {get} from 'lodash';
import {httpsCallable} from 'firebase/functions';

import {UserContext} from '../contexts/User';
import {db, functions} from '../firebase';

import {populateUsers, processRawDoc} from '../data/utils';

import UserAutocompleteField from '../form/UserAutocompleteField';

const CompleteButton = props => {
    const {isCompleted, isSavable, ...rest} = props;

    return (
        <Button
            variant={isCompleted ? 'contained' : 'outlined'}
            color="success"
            endIcon={<CheckIcon />}
            size="large"
            disabled={!isSavable}
            {...rest}
        >
            {isCompleted ? 'Completed' : 'Mark Complete'}
        </Button>
    );
};

const SkipButton = props => {
    const {isSkipped, isSavable, ...rest} = props;

    return (
        <Button
            variant={isSkipped ? 'contained' : 'outlined'}
            color="secondary"
            endIcon={<SkipNextIcon />}
            size="large"
            disabled={!isSavable}
            {...rest}
        >
            {isSkipped ? 'Skipped' : 'Skip'}
        </Button>
    );
};

const calculateExpandedIndex = (checks, completed, skipped) => {
    let nextIndex = -1;

    for (let i = 0; i < checks.length; i++) {
        if (!completed[i] && !skipped[i]) {
            nextIndex = i;
            break;
        }
    }

    return nextIndex;
}

const EditChecks = () => {
    const {id: uid, checkId: checkUid} = useParams();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [completing, setCompleting] = useState(false);
    const [checkRecord, setCheckRecord] = useState(checkUid && {uid: checkUid});
    const [checks, setChecks] = useState([]);
    const [completed, setCompleted] = useState([]);
    const [skipped, setSkipped] = useState([]);
    const [expandedIndex, setExpandedIndex] = useState(-1);
    const [notes, setNotes] = useState([]);
    const [canSave, setCanSave] = useState(false);
    const [lastSave, setLastSave] = useState(null);
    const finishCheck = httpsCallable(functions, 'finishCheck');
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const {currentUser} = useContext(UserContext);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const dateFormatLong = get(currentUser, 'settings.dateFormatLong') || 'LLL';

    const {isOfficer = false, isAdmin} = currentUser;
    const isSavable = checkRecord && checkRecord.active; // !checkUid || isOfficer;

    let isSubscribed = true;
    let firstLoad = true;

    const methods = useForm({
        defaultValues: {
            members: []
        },
        mode: 'onChange'
    });
    const {watch, formState, reset} = methods;

    const members = watch('members');

    const handleFetch = useCallback(async checkRecordRaw => {
        try {
            let newCheckRecord = processRawDoc(checkRecordRaw);
            newCheckRecord = await populateUsers(db, newCheckRecord);

            if (!newCheckRecord) {
                return;
            }

            const {users = [], loggedChecks = []} = newCheckRecord;
            const newChecks = loggedChecks;

            const newNotes = loggedChecks.map(check => check.note || '');
            const newCompleted = loggedChecks.map(check => check.completed || false);
            const newSkipped = loggedChecks.map(check => check.skipped || false);

            const canSave = newCheckRecord.active && newChecks.length && calculateExpandedIndex(newChecks, newCompleted, newSkipped) === -1;

            if (isSubscribed) {
                if (newCheckRecord.active === false && checkRecord.active === true) {
                    navigate(`/apparatus/${uid}/weekly`);
                    return;
                }

                setCheckRecord(newCheckRecord);
                setCanSave(canSave);

                setChecks(newChecks);
                setCompleted(newCompleted);
                setSkipped(newSkipped);
                setNotes(newNotes);
                reset({
                    members: users.map(user => user.id)
                });

                if (firstLoad) {
                    setExpandedIndex(calculateExpandedIndex(newChecks, newCompleted, newSkipped));
                    firstLoad = false;
                }
            }
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        if (isSubscribed) {
            setLoading(false);
        }
    }, [isSubscribed, navigate, checkRecord, db, uid, enqueueSnackbar]);

    useEffect(() => {
        const ref = doc(db, 'apparatus', uid, 'checks', checkUid);
        const snapshot = onSnapshot(ref, handleFetch);
        
        return () => {
            snapshot();
            isSubscribed = false;
        };
    }, [enqueueSnackbar, db, uid]);

    const updateExpandedIndex = useCallback(nextIndex => {
        if (!nextIndex) {
            setExpandedIndex(calculateExpandedIndex(checks, completed, skipped));

            return;
        }

        let newExpandedIndex = canSave ? -1 : nextIndex;
        setExpandedIndex(newExpandedIndex);
    }, [checks, completed, skipped, canSave]);

    const handleChange = useCallback((index, type) => {
        const newCompleted = [...completed];
        const newSkipped = [...skipped];

        let flag = false;
        if (type === 'COMPLETE') {
            flag = !newCompleted[index];
            newCompleted[index] = flag;
            setCompleted(newCompleted);

            if (newSkipped[index]) {
                newSkipped[index] = false;
                setSkipped(newSkipped);
            }
        } else {
            flag = !newSkipped[index];
            newSkipped[index] = flag;
            setSkipped(newSkipped);

            if (newCompleted[index]) {
                newCompleted[index] = false;
                setCompleted(newCompleted);
            }
        }

        const newCanSave = checks.length && calculateExpandedIndex(checks, newCompleted, newSkipped) === -1;
        setCanSave(newCanSave);
        
        if (newCanSave) {
            updateExpandedIndex(-1)
        } else if (flag) {
            let nextIndex = -1;

            for (let i = index; i < checks.length; i++) {
                if (!newCompleted[i] && !newSkipped[i]) {
                    nextIndex = i;
                    break;
                }
            }

            if (nextIndex !== -1) {
                updateExpandedIndex(nextIndex);
            } else {
                updateExpandedIndex();
            }
        }

        setLastSave(new Date());
    }, [completed, skipped, checks, updateExpandedIndex]);

    const handleExpandedToggle = useCallback(index => {
        if (isSavable) {
            setExpandedIndex(index);
        }
    }, [isSavable]);

    const handleNoteChange = useCallback((index, note) => {
        const newNotes = [...notes];
        newNotes[index] = note;
        setNotes(newNotes);

        setLastSave(new Date());
    }, [notes]);

    const handleSave = useCallback(async complete => {
        try {
            setSaving(true);

            const loggedChecks = checks.map((check, index) => {
                const {text} = check;
                const note = notes[index];

                return {
                    text,
                    completed: completed[index] || false,
                    skipped: skipped[index] || false,
                    ...(note && {note})
                };
            });

            const docRef = doc(db, 'apparatus', uid, 'checks', checkRecord.uid);
            await updateDoc(docRef, {
                loggedChecks,
                updatedAt: new Date(),
                users: members.filter(Boolean),
                ...(complete ? {
                    active: false
                } : {
                    active: true
                })
            });
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setSaving(false);
    }, [checkRecord, checks, completed, skipped, notes, members, enqueueSnackbar, db, uid]);

    const handleFinish = useCallback(async () => {
        if (!members.length) {
            enqueueSnackbar('Please select members to finish this truck check', {variant: 'error'});
            return;
        }

        const onFinish = async () => {
            setCompleting(true);

            await handleSave();

            try {
                await finishCheck({
                    apparatus: uid,
                    check: checkUid,
                    debugEmail: isAdmin
                });

                navigate(`/apparatus/${uid}/weekly`);
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});

                setCompleting(false);
            }
        };

        const allCompleted = checks.length && calculateExpandedIndex(checks, completed, skipped) === -1;

        if (!allCompleted) {
            enqueueSnackbar('You have incomplete checks - do you still want to finish the truck check?', {
                variant: 'warning',
                action: key => {
                    return (
                        <>
                            <Button onClick={() => {
                                closeSnackbar(key);
                                onFinish();
                            }}>
                                Finish Truck Check
                            </Button>
                            <Button onClick={() => closeSnackbar(key)}>
                                Cancel
                            </Button>
                        </>
                    );
                }
            });
        } else {
            onFinish();
        }
    }, [uid, checkUid, handleSave, finishCheck, enqueueSnackbar, closeSnackbar, navigate, isAdmin, members, checks, completed, skipped]);

    const handleDelete = useCallback(() => {
        const onDelete = async() => {
            try {
                setDeleting(true);
    
                const docRef = doc(db, 'apparatus', uid, 'checks', checkRecord.uid);
                await deleteDoc(docRef);
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }
    
            setDeleting(false);
    
            navigate(`/apparatus/${uid}/weekly`);
        };

        enqueueSnackbar('Are you sure you want to delete this truck check?', {
            variant: 'warning',
            action: key => {
                return (
                    <>
                        <Button onClick={() => {
                            closeSnackbar(key);
                            onDelete();
                        }}>
                            Delete
                        </Button>
                        <Button onClick={() => closeSnackbar(key)}>
                            Cancel
                        </Button>
                    </>
                );
            }
        });
    }, [db, enqueueSnackbar, navigate, uid, closeSnackbar, checkRecord]);

    useDebouncedEffect(async() => {
        await handleSave();
    }, 1000, [lastSave]);

    useDebouncedEffect(async() => {
        const {dirtyFields} = formState;

        if (dirtyFields.members) {
            await handleSave();
        }
    }, 1000, [formState, handleSave]);

    return (
        <Box>
            <Box sx={{pb: 2}}>
                <Typography variant="h4" sx={{flex: 1}}>
                    Weekly Truck Check
                </Typography>
                
                <Box sx={{display: 'flex'}}>
                    <Box sx={{flex: 1}}>
                        <Typography variant="caption">
                            <strong>Started:</strong> {moment(checkRecord && checkRecord.createdAt ? (checkRecord.createdAt.toDate ? checkRecord.createdAt.toDate() : checkRecord.createdAt) : new Date()).format(dateFormatLong)}
                        </Typography>
                    </Box>
                    {(saving || loading) && (
                        <Box sx={{display: 'flex'}}>
                            {!isSmall && (
                                <Typography sx={{mr: 1}} variant="caption">{!saving && loading ? 'loading' : 'saving'}</Typography>
                            )}
                            <CircularProgress size={20} />
                        </Box>
                    )}
                </Box>

                <Divider sx={{mt: 1}} />
            </Box>

            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <FormProvider {...methods}>
                    <UserAutocompleteField
                        label="Members"
                        name="members"
                        limitToStation
                        disabled={!isSavable}
                        sx={{flex: 1}}
                    />
                </FormProvider>
            </Box>

            <Divider sx={{mt: 1, mb: 2}} />
            
            {checks.map((check, index) => {
                const {text} = check;
                const isCompleted = completed[index] === true;
                const isSkipped = skipped[index] === true;
                const isExpanded = index === expandedIndex;
                const note = notes[index];

                let accordionSx = undefined;
                
                if (isCompleted && note) {
                    accordionSx = {
                        // backgroundColor: lighten(theme.palette.primary.main, 0.95),
                        borderLeft: `4px solid ${theme.palette.warning.main}`
                    };
                } else if (isSkipped) {
                    accordionSx = {
                        // backgroundColor: lighten(theme.palette.secondary.main, 0.95),
                        borderLeft: `4px solid ${theme.palette.info.main}`
                    };
                }

                return (
                    <Accordion key={index} expanded={isExpanded}>
                        <AccordionSummary sx={accordionSx}>
                            <Box sx={{display: 'flex', flex: 1, alignItems: 'center'}}>
                                <Box sx={{flex: 1, alignItems: 'center'}} onClick={() => handleExpandedToggle(index)}>
                                    <Typography
                                        variant={isSmall ? 'h6' : 'h6'}
                                        sx={{
                                            flex: 1,
                                            ...((isCompleted || isSkipped) && {
                                                opacity: 0.7,
                                                textDecoration: 'line-through'
                                            })
                                        }}
                                    >
                                        {text}
                                    </Typography>
                                    {isCompleted && note && (
                                        <Typography variant="caption" component="div">{note}</Typography>
                                    )}
                                    {isSkipped && (
                                        <Typography variant="caption" component="div">Skipped</Typography>
                                    )}
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={{pb: 1}}>
                            <Box sx={{display: 'flex'}}>
                                <TextField
                                    placeholder="Additional Notes"
                                    value={notes[index]}
                                    disabled={saving}
                                    onChange={e => handleNoteChange(index, e.target.value)}
                                    sx={{flex: 1, mr: !isSmall ? 1 : 0}}
                                />
                                {!isSmall && (
                                    <>
                                        <CompleteButton
                                            isCompleted={isCompleted}
                                            isSavable={isSavable}
                                            onClick={() => handleChange(index, 'COMPLETE')}
                                            sx={{mr: 1}}
                                        />
                                        <SkipButton
                                            isSkipped={isSkipped}
                                            isSavable={isSavable}
                                            onClick={() => handleChange(index, 'SKIP')}
                                        />
                                    </>
                                )}
                            </Box>
                            {isSmall && (
                                <Box sx={{display: 'flex', alignItems: 'center', pt: 1}}>
                                    <CompleteButton
                                        isCompleted={isCompleted}
                                        isSavable={isSavable}
                                        onClick={() => handleChange(index, 'COMPLETE')}
                                        sx={{mr: 1}}
                                        fullWidth
                                    />
                                    <SkipButton
                                        isSkipped={isSkipped}
                                        isSavable={isSavable}
                                        onClick={() => handleChange(index, 'SKIP')}
                                        fullWidth
                                    />
                                </Box>
                            )}
                        </AccordionDetails>
                    </Accordion>
                );
            })}
            
            {isSavable && (
                <LoadingButton
                    variant="contained"
                    sx={{mt: 2}}
                    onClick={handleFinish}
                    loading={completing}
                    disabled={deleting || completing}
                    size="large"
                    fullWidth
                >
                    Finish Truck Check
                </LoadingButton>
            )}

            {isOfficer && (
                <LoadingButton
                    variant="outlined"
                    sx={{mt: 2}}
                    onClick={handleDelete}
                    loading={deleting}
                    disabled={deleting || completing}
                    fullWidth
                >
                    Delete Truck Check
                </LoadingButton>
            )}
        </Box>
    );
};

export default EditChecks;