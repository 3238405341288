import React from 'react';
import {Typography, Box} from '@mui/material';

export default function App() {
    return (
        <Box sx={{my: 4}}>
            <Typography variant="h4" component="h1" gutterBottom>
                Nothing found
            </Typography>
        </Box>
    );
}
