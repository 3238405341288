import React, {useContext} from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';

import {UserContext} from './contexts/User';
import {hasFeature} from './features.js';

import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Manage from './pages/Manage';

import Stations from './pages/Stations';
import Settings from './pages/Settings';

import Dashboard from './pages/Dashboard';

import Incidents from './pages/Incidents';
import Incident from './pages/Incident';
import ImportIncidents from './pages/incidents/Import';

import Members from './pages/Members';
import Member from './pages/Member';
import QualificationMatrix from './pages/QualificationMatrix';
import TrainingMatrix from './pages/TrainingMatrix';

import Apparatuses from './pages/Apparatuses';
import EditApparatus from './pages/EditApparatus';
import Apparatus from './pages/Apparatus';

import Checks from './pages/Checks';
import EditChecks from './pages/EditChecks';
import EditFullChecks from './pages/EditFullChecks';
import Check from './pages/Check';
import FullCheck from './pages/FullCheck';

import Calendar from './pages/Calendar';

import Trainings from './pages/Trainings';
import Training from './pages/Training';
import ImportTraining from './pages/training/Import';
import TrainingKeywords from './pages/training/Keywords';

// import Gear from './pages/Gear';
// import Sheet from './pages/Sheet';

import Respond from './pages/respond';
import RespondMap from './pages/respond/Map';

import Activities from './pages/vs/Activities';

import NFPA from './pages/NFPA';

import Gear from './pages/Gear';
import GearCollection from './pages/GearCollection';
import GearItem from './pages/GearItem';

import {verifyOfficer} from './data/utils';

export default function() {
    const {currentUser} = useContext(UserContext);
    const isOfficer = verifyOfficer(currentUser);
    const {isAdmin = false, isStation} = currentUser || {};

    const reRouteIfAuthenticated = destination => {
        if (currentUser) {
            return <Navigate to="/" />;
        }

        return destination;
    };

    const reRouteIfUnauthenticated = destination => {
        if (!currentUser) {
            return <Navigate to="/login" />;
        }

        return destination;
    };

    const generateGearRoutes = (maxDepth = 10) => {
        const routes = [
            {path: 'gear/:id', element: 'collection'}
        ];

        let path = 'gear/:collectionId';

        for (let depth = 1; depth <= maxDepth; depth++) {
            if (depth === 1) {
                // First level: gear/:collectionId/:id with GearItem component
                routes.push({path: `${path}/:id`, element: 'item'});
            } else if (depth % 2 === 0) {
                // Even depth: gear/.../gear/:id with GearCollection component
                path += '/gear';
                routes.push({path: `${path}/:id`, element: 'collection'});
            } else {
                // Odd depth: gear/.../:subCollectionId/:id with GearItem component
                path += `/:subCollectionId${depth - 2}`;
                routes.push({path: `${path}/:id`, element: 'item'});
            }
        }

        // Convert route objects to <Route /> components
        return routes.map(route => (
            <Route key={route.path} path={route.path} element={route.element === 'collection' ? <GearCollection /> : <GearItem />} />
        ));
    };

    return (
        <Routes>
            <Route path="/login" element={reRouteIfAuthenticated(<Login />)} />
            <Route path="/manage" element={reRouteIfAuthenticated(<Manage />)} />
            <Route path="*" element={reRouteIfUnauthenticated(<NotFound />)} />

            {currentUser && (
                <Route path="*">
                    <Route index element={<Dashboard />} />

                    {!isStation && (
                        <>
                            <Route path="profile" element={<Member isProfile />} />
                            <Route path="profile/skills" element={<Member isProfile activeTab="skills" />} />
                            <Route path="profile/statistics" element={<Member isProfile activeTab="statistics" />} />
                            <Route path="profile/transcript" element={<Member isProfile activeTab="transcript" />} />
                        </>
                    )}

                    {isOfficer && (
                        <Route path="profile/jibcConsent" element={<Member isProfile activeTab="jibcConsent" />} />
                    )}

                    <Route path="apparatus">
                        <Route index element={<Apparatuses />} />
                        <Route path=":id" element={<Apparatus />} />
                        <Route path=":id/edit" element={<EditApparatus />} />
                        <Route path="new" element={<EditApparatus />} />

                        <Route path=":id/:type" element={<Checks />} />
                        <Route path=":id/weekly/:checkId" element={<Check />} />
                        <Route path=":id/full/:checkId" element={<FullCheck />} />
                        {isOfficer && (
                            <>
                                <Route path=":id/weekly/edit" element={<EditChecks />} />
                                <Route path=":id/full/edit" element={<EditFullChecks />} />
                            </>
                        )}
                    </Route>

                    <Route path="matrix" element={<Navigate to="/" replace />} />
                    <Route path="matrix/qualification" element={<QualificationMatrix />} />
                    <Route path="matrix/training" element={<TrainingMatrix />} />

                    <Route path="calendar" element={<Calendar />} />

                    <Route path="nfpa" element={<NFPA />} />
                    <Route path="nfpa/:standard" element={<NFPA />} />
                    <Route path="nfpa/:standard/:edition" element={<NFPA />} />

                    <Route path="gear" element={<Gear />} />
                    {generateGearRoutes()}
                                                
                    {!isStation && hasFeature('incidents') && (
                        <Route path="incidents">
                            <Route index element={<Incidents />} />

                            {isOfficer && (
                                <>
                                    <Route path=":id" element={<Incident />} />
                                    <Route path="import" element={<ImportIncidents />} />
                                </>
                            )}
                        </Route>
                    )}

                    {isAdmin && hasFeature('training') && (
                        <Route path="training">
                            <Route index element={<Trainings />} />
                            <Route path=":id" element={<Training />} />
                            <Route path="import" element={<ImportTraining />} />
                            <Route path="keywords" element={<TrainingKeywords />} />
                        </Route>
                    )}

                    {isAdmin && hasFeature('vectorSolutionsSync') && (
                        <Route path="vs">
                            <Route path="activities" element={<Activities />} />
                        </Route>
                    )}

                    {isAdmin && (
                        <>
                            <Route path="stations" element={<Stations />} />
                            <Route path="settings" element={<Settings />} />
                        </>
                    )}

                    {isOfficer && (
                        <>
                            <Route path="users">
                                <Route path=":id" element={<Member />} />
                                <Route path=":id/skills" element={<Member activeTab="skills" />} />
                                <Route path=":id/statistics" element={<Member activeTab="statistics" />} />
                                <Route path=":id/transcript" element={<Member activeTab="transcript" />} />
                                <Route path=":id/jibcConsent" element={<Member activeTab="jibcConsent" />} />
                                <Route index element={<Members />} />
                                <Route path="new" element={<Member />} />
                            </Route>
                        </>
                    )}

                    <Route path="respond">
                        <Route index element={<Respond />} />
                        <Route path=":id" element={<RespondMap />} />
                    </Route>
                </Route>
            )}
        </Routes>
    );
};