import React, {useContext, useCallback} from 'react';
import {useNavigate, NavLink} from 'react-router-dom';
import {Typography, ListItemButton, ListItemIcon, ListItemText, List, Stack, Divider, ListItem} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PeopleIcon from '@mui/icons-material/People';
import VerifiedIcon from '@mui/icons-material/Verified';
import SchoolIcon from '@mui/icons-material/School';
import FireTruckIcon from '@mui/icons-material/FireTruck';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import ConstructionIcon from '@mui/icons-material/Construction';
import DescriptionIcon from '@mui/icons-material/Description';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import TableRowsIcon from '@mui/icons-material/TableRows';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import SettingsIcon from '@mui/icons-material/Settings';

import {UserContext} from '../../contexts/User';
import {hasFeature} from '../../features';

import DevModeAlert from './DevModeAlert';

export default function MenuContent({toggleDrawer}) {
    const navigate = useNavigate();
    const {currentUser} = useContext(UserContext);
    const {isOfficer, isStation, isAdmin = false} = currentUser || {};

    const handleClick = useCallback(item => {
        if (toggleDrawer) {
            toggleDrawer();
        }

        navigate(item.to);
    }, [navigate, toggleDrawer]);

    const menuItems = [
        {label: 'Dashboard', to: '/', icon: <HomeIcon />},
        {divider: true},
        {label: 'Apparatus', to: '/apparatus', icon: <LocalShippingIcon />}
    ];

    if (!isStation && hasFeature('incidents')) {
        menuItems.push({label: 'Incidents', to: '/incidents', icon: <WhatshotIcon />});
    }

    menuItems.push({divider: true});

    menuItems.push({label: 'Qualification Matrix', to: '/matrix/qualification', icon: <VerifiedIcon />});
    menuItems.push({label: 'Training Matrix', to: '/matrix/training', icon: <SchoolIcon />});

    if (isOfficer) {
        if (hasFeature('training')) {
            menuItems.push({label: 'Training Records', to: '/training', icon: <TableRowsIcon />});
            menuItems.push({label: 'Training Keywords', to: '/training/keywords', icon: <LocalLibraryIcon />});
        }

        if (hasFeature('respond')) {
            menuItems.push({label: 'Respond', to: '/respond', icon: <FireTruckIcon />});
        }
    }

    // if (isAdmin && hasFeature('ropes')) {
    //     menuItems.push({label: 'Ropes', to: '/ropes', icon: <HikingIcon />});
    // }
    
    if (isAdmin) {
        if (hasFeature('vectorSolutionsSync')) {
            menuItems.push({divider: true});

            menuItems.push({label: 'VS Activities', to: '/vs/activities', icon: <ChangeHistoryIcon />});
        }
    }

    menuItems.push({divider: true});
    menuItems.push({label: 'Gear', to: '/gear', icon: <ConstructionIcon />});

    menuItems.push({divider: true});
    menuItems.push({label: 'NFPA Standards', to: '/nfpa', icon: <DescriptionIcon />});

    const settingsItems = [
        {label: 'Settings', to: '/settings', icon: <SettingsIcon />},
        {label: 'Users', to: '/users', icon: <PeopleIcon />},
        {label: 'Stations', to: '/stations', icon: <LocationCityIcon />}
    ];

    return (
        <>
            <Stack sx={{flexGrow: 1, p: 1, justifyContent: 'space-between'}}>
                <List sx={{gap: 1}} dense>
                    {menuItems.map((item, index) => {
                        const {divider} = item;

                        if (divider) {
                            return <Divider key={`menu-divider-${index}`} />;
                        }

                        return (
                            <NavLink key={`menu-item-${index}`} style={{textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)'}} to={item.to}>
                                {({isActive}) => (
                                    <ListItem disablePadding>
                                        <ListItemButton
                                            selected={isActive}
                                            onClick={() => handleClick(item)}
                                        >
                                            <ListItemIcon>{item.icon}</ListItemIcon>
                                            <ListItemText sx={{fontSize: '1rem'}} primary={item.label} />
                                        </ListItemButton>
                                    </ListItem>
                                )}
                            </NavLink>
                        );
                    })}
                </List>

                {isAdmin && (
                    <List dense>
                        <ListItem disablePadding sx={{px: 1, opacity: 0.5}}>
                            <Typography variant="overline">Administration</Typography>
                        </ListItem>
                        {settingsItems.map((item, index) => (
                            <NavLink key={`settings-menu-item-${index}`} style={{textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)'}} to={item.to}>
                                {({isActive}) => (
                                    <ListItem disablePadding sx={{mt: 1, '&:first-of-type': {mt: 0}}}>
                                        <ListItemButton
                                            selected={isActive}
                                            onClick={() => handleClick(item)}
                                        >
                                            <ListItemIcon>{item.icon}</ListItemIcon>
                                            <ListItemText sx={{fontSize: '1rem'}} primary={item.label} />
                                        </ListItemButton>
                                    </ListItem>
                                )}
                            </NavLink>
                        ))}
                    </List>
                )}
            </Stack>

            <DevModeAlert />
        </>
    );
}