import React from 'react';
import {IconButton} from '@mui/material';
import Badge, {badgeClasses} from '@mui/material/Badge';

export default function({showBadge = false, ...props}) {
    return (
        <Badge
            color="error"
            variant="dot"
            invisible={!showBadge}
            sx={{[`& .${badgeClasses.badge}`]: {right: 2, top: 2}}}
        >
            <IconButton sx={{fontSize: '1.8rem'}} {...props} />
        </Badge>
    );
}