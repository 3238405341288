import React, {useState, useEffect, useCallback, useContext} from 'react';
import {useNavigate, Link as RouterLink} from 'react-router-dom';
import {Button, Box, FormControlLabel, Checkbox, useMediaQuery} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useTheme} from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import moment from 'moment';
import {useForm, FormProvider} from 'react-hook-form';
import parsePhoneNumber from 'libphonenumber-js';

import {db} from '../firebase';
import {hasFeature} from '../features';
import {UserContext} from '../contexts/User';

import {Ranks, getSnapshot, populateKeyFromCollection} from '../data/utils';

import StatefulDataGrid from '../components/StatefulDataGrid';

import UserAvatar from '../components/UserAvatar';

import TextField from '../form/TextField';

const Members = () => {
    const [loading, setLoading] = useState(true);
    const [includeDeactivated, setIncludeDeactivated] = useState(false);
    const [docs, setDocs] = useState([]);
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const {currentUser} = useContext(UserContext);
    const {isOfficer, isAdmin} = currentUser;
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const methods = useForm({
        defaultValues: {
            filter: '',
            includeDeactivated: false
        },
        mode: 'onChange'
    });
    const {watch} = methods;
    const filter = watch('filter');

    useEffect(() => {
        const unsubscribe = getSnapshot(db, 'users', async docs => {
            let filtered = docs;
            filtered = await populateKeyFromCollection(db, docs, 'station', 'stations');

            if (!includeDeactivated) {
                filtered = filtered.filter(doc => doc.deactivated !== true);
            }

            setDocs(filtered);
            setLoading(false);
        });
   
        return unsubscribe;
    }, [enqueueSnackbar, db, includeDeactivated]);

    const renderImageCell = useCallback(params => {
        const {row} = params;

        return (
            <Box sx={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                <UserAvatar user={row} />
            </Box>
        );
    }, []);

    const columns = [
        {field: 'imageUrl', headerName: '', align: 'center', width: isSmall ? 40 : 60, renderCell: renderImageCell, sortable: false},
        {
            field: 'fullName',
            headerName: 'Name',
            flex: 2,
            renderCell: params => {
                const {row} = params || {};
                const {fullName, email} = row || {};
                return fullName || email;
            }
        },
        {field: 'email', headerName: 'Email', flex: 2, sortable: false},
        {
            field: 'station',
            headerName: 'Station',
            width: 100,
            valueGetter: value => {
                const {name} = value || {};
                return name || '-';
            }
        },
        {
            field: 'role',
            headerName: 'Rank',
            width: 190,
            valueFormatter: value => {
                return Ranks[value];
            }
        }
    ];

    if (isOfficer) {
        columns.push({
            field: 'employeeId',
            headerName: 'Employee ID',
            width: 100,
            valueFormatter: value => {
                return (value || '').toUpperCase()
            }
        });

        columns.push({
            field: 'dob',
            headerName: 'DOB',
            width: 100,
            valueFormatter: value => {
                if (!value) {
                    return '';
                }

                return moment(value.toDate()).format('DD/MM/YYYY');
            }
        });

        columns.push({
            field: 'jibcNumber',
            headerName: 'JIBC #',
            width: 100,
            valueFormatter: value => {
                return (value || '').toUpperCase()
            }
        });

        columns.push({
            field: 'phone',
            headerName: 'Phone #',
            width: 130,
            valueFormatter: value => {
                const parsed = parsePhoneNumber(`${(value || '')}`, 'CA');

                if (parsed) {
                    return parsed.formatNational();
                }

                return '';
            }
        });

        columns.push({
            field: 'transcriptDate',
            headerName: 'Transcript Updated',
            width: 120,
            valueFormatter: value => {
                return value ? moment(value.toDate()).format('L') : '-';
            }
        });

        if (hasFeature('hasJIBCConsent')) {
            columns.push({
                field: 'jibcConsentDate',
                headerName: 'JIBC Consent',
                width: 140,
                sortable: true,
                align: 'center',
                renderCell: params => {
                    const {value} = params || {};
                    if (!value) {
                        return undefined;
                    }

                    return <CheckIcon />;
                }
            });
        }
    }

    if (isAdmin) {
        columns.push({
            field: 'age',
            headerName: 'Age',
            width: 100,
            valueGetter: (value, row) => {
                const {dob} = row || {};
                if (!dob) {
                    return '-';
                }

                return moment().diff(dob.toDate(), 'years');
            }
        });

        columns.push({
            field: 'daysUntilDob',
            headerName: 'Days until DOB',
            width: 140,
            valueGetter: (value, row) => {
                let {dob} = row || {};
                if (!dob) {
                    return '-';
                }

                const year = moment().year();
                dob = moment(dob.toDate());
                dob.set('year', year);

                let diff = moment(dob).diff(moment(), 'days');
                if (diff < 0) {
                    dob.set('year', year + 1);
                    diff = moment(dob).diff(moment(), 'days');
                }

                return diff;
            }
        });

        columns.push({
            field: 'registered',
            headerName: 'Registered',
            width: 100,
            sortable: true,
            align: 'center',
            renderCell: params => {
                const {value} = params || {};
                if (!value) {
                    return '';
                }

                return (
                    <Box sx={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                        <CheckIcon />
                    </Box>
                );
            }
        });

        // if (hasFeature('authIssues')) {
        //     columns.push({
        //         field: 'hasAuthIssues',
        //         headerName: 'Auth Issues',
        //         width: 100,
        //         sortable: true,
        //         align: 'center',
        //         valueGetter: (value, row) => {
        //             return row && row.uid !== row.id;
        //         },
        //         renderCell: params => {
        //             const {value} = params || {};
        //             if (!value) {
        //                 return '';
        //             }

        //             return <CheckIcon />;
        //         }
        //     });
        // }
    }

    const onRowClick = useCallback((params, e) => {
        const {metaKey} = e;
        const {row} = params;

        const url = `/users/${row.id}`;
        if (metaKey) {
            window.open(url);
            return;
        }

        navigate(url);
    }, [navigate]);

    const search = filter.trim().toLowerCase();
    const filtered = docs.filter(doc => {
        const {fullName = ''} = doc;
        
        if (fullName.toLowerCase().includes(search)) {
            return true;
        }

        return false;
    });

    return (
        <FormProvider {...methods}>
            <Box sx={{display: 'flex', mb: 1}}>
                <TextField
                    fullWidth
                    size="small"
                    label="Search"
                    name="filter"
                    sx={{flex: 1}}
                />

                {isOfficer && (
                    <FormControlLabel
                        control={(
                            <Checkbox
                                onChange={() => setIncludeDeactivated(!includeDeactivated)}
                                checked={includeDeactivated}
                            />
                        )}
                        label="Include Deactivated"
                        sx={{ml: 1}}
                    />
                )}
            </Box>

            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <StatefulDataGrid
                    stateId="members"
                    initialState={{
                        sorting: {
                            sortModel: [
                                {field: 'station', sort: 'asc'},
                                {field: 'lastName', sort: 'asc'}
                            ]
                        },
                        columns: {
                            columnVisibilityModel: {
                                email: false,
                                dob: false,
                                jibcNumber: false,
                                transcriptDate: false,
                                jibcConsentDate: false,
                                employeeId: false,
                                age: false,
                                daysUntilDob: false,
                                registered: false
                            }
                        }
                    }}
                    loading={loading}
                    autoHeight
                    rows={filtered}
                    columns={columns.map(column => ({...column, disableColumnMenu: true}))}
                    pageSizeOptions={[]}
                    disableRowSelectionOnClick
                    disableColumnFilter
                    onRowClick={onRowClick}
                    slotProps={{
                        loadingOverlay: {
                            variant: 'linear-progress',
                            noRowsVariant: 'skeleton'
                        }
                    }}
                    getRowClassName={params => {
                        const {row} = params;
                        const {deactivated = false} = row || {};
                        const classNames = [];

                        if (deactivated) {
                            classNames.push('deactivated');
                        }

                        return classNames.join(' ');
                    }}
                    sx={{
                        '& .deactivated': {
                            opacity: 0.6
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none'
                        }
                    }}
                />
            </Box>

            {isOfficer && (
                <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                    <Button component={RouterLink} variant="contained" to="/users/new">Add User</Button>
                </Box>
            )}
        </FormProvider>
    );
};

export default Members;