import React, {useState, useEffect, useMemo, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {Grid2 as Grid, Button, IconButton, Box, Typography, Paper, Skeleton} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useParams} from 'react-router-dom';
import {doc, updateDoc, deleteDoc, onSnapshot} from 'firebase/firestore';
import {useForm, FormProvider} from 'react-hook-form';
import {get, omit} from 'lodash';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import {ref, getDownloadURL} from 'firebase/storage';

import {db, storage} from '../firebase';
import {UserContext} from '../contexts/User';

import useDocumentTitle from '../hooks/useDocumentTitle';

import {getCollectionDoc, processRawDoc, ensureJSDates, ensureMomentDates, verifyOfficer, uploadImage} from '../data/utils';

import RecordsGrid from './gear/RecordsGrid';
import TasksGrid from './gear/TasksGrid';
import ItemDialog from './gear/ItemDialog';

const HeaderItem = ({label, value, loading, ...rest}) => (
    <Grid {...rest} size={{xs: 12, md: 6, lr: 4}}>
        <Typography variant="caption" color="text.secondary">{loading ? <Skeleton /> : label}</Typography>
        <Typography variant="h6">{loading ? <Skeleton /> : value}</Typography>
    </Grid>
);

export default function GearItem() {
    const params = useParams();
    const {id: uid, ...otherParams} = params;
    const [loadingItem, setLoadingItem] = useState(true);
    const [loadingParent, setLoadingParent] = useState(true);
    const [editing, setEditing] = useState(false);
    const [parent, setParent] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    const loading = loadingItem || loadingParent;

    const navigate = useNavigate();
    const {currentUser} = useContext(UserContext);
    const isOfficer = verifyOfficer(currentUser);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';

    const methods = useForm({
        defaultValues: {
            name: '',
            identifier: ''
        },
        mode: 'onChange'
    });
    
    const {watch, reset} = methods;
    const item = watch();
    const {name, image} = item;

    const {additionalFields = []} = parent || {};

    const deleteUrl = useMemo(() => {
        let parts = [];

        Object.entries(otherParams).forEach(([key, value]) => {
            if (key !== '*' && value) {
                parts.push('gear', value);
            }
        });

        return `/${parts.join('/')}`;
    }, [otherParams]);

    const parentRefArgs = useMemo(() => {
        const otherParams = omit(params, 'id');
        let refArgs = [db];

        Object.entries(otherParams).forEach(([key, param]) => {
            if (key !== '*' && param) {
                refArgs.push('gear', param);
            }
        });

        return refArgs;
    }, [db, params]);

    const refArgs = useMemo(() => {
        let refArgs = [...parentRefArgs];

        refArgs.push('items', uid);

        return refArgs;
    }, [uid, parentRefArgs]);

    useEffect(() => {
        setLoadingParent(true);

        const ref = doc(...parentRefArgs);
        const unsubscribe = onSnapshot(ref, async snapshot => {
            const row = processRawDoc(snapshot);

            setParent(row);
            setLoadingParent(false);
        });
        
        return () => {
            unsubscribe();
        };
    }, [db, reset, uid, parentRefArgs]);

    useEffect(() => {
        setLoadingItem(true);

        const ref = doc(...refArgs);
        const unsubscribe = onSnapshot(ref, async snapshot => {
            const record = processRawDoc(snapshot);
            if (!record) {
                return;
            }
            
            const {apparatus: apparatusUid, ...rest} = record;
            const apparatus = await getCollectionDoc(db, 'apparatus', apparatusUid);

            reset({
                ...rest,
                apparatus
            });

            setLoadingItem(false);
        });
        
        return () => {
            unsubscribe();
        };
    }, [db, reset, uid, refArgs]);

    const onUpdate = async data => {
        setLoadingItem(true);

        const {apparatus, imageFile, ...rest} = data;

        let image;
        if (imageFile) {
            const path = refArgs.slice(1).join('/');
            image = await uploadImage(path, imageFile);
        } else if (imageFile === null) {
            image = null;
        }

        const toUpdate = ensureJSDates({
            ...rest,
            image
        });

        if (apparatus) {
            toUpdate.apparatus = apparatus.uid || apparatus;
        } else {
            toUpdate.apparatus = null;
        }

        if (Object.keys(toUpdate)) {
            const update = {
                ...toUpdate,
                updatedAt: new Date()
            };

            await updateDoc(doc(...refArgs), update);

            const {apparatus: apparatusUid} = update;
            if (apparatusUid) {
                const apparatus = await getCollectionDoc(db, 'apparatus', apparatusUid);
                
                reset({
                    ...ensureMomentDates(update),
                    image,
                    apparatus
                });
            } else {
                reset({
                    ...ensureMomentDates(update),
                    image
                });
            }
        }

        setEditing(false);
        setLoadingItem(false);
    };

    const handleDelete = () => {
        const onDelete = async() => {
            setLoadingItem(true);

            const ref = doc(...refArgs);
            await deleteDoc(ref);

            navigate(deleteUrl);
        };

        enqueueSnackbar('Are you sure you want to delete this item?', {
            variant: 'warning',
            action: key => {
                return (
                    <>
                        <Button onClick={() => {
                            closeSnackbar(key);
                            onDelete();
                        }}>
                            Delete
                        </Button>
                        <Button onClick={() => closeSnackbar(key)}>
                            Cancel
                        </Button>
                    </>
                );
            }
        });
    };

    useDocumentTitle(name);

    useEffect(() => {
        const {filePath, thumbnailPath} = image || {};

        let isSubscribed = true;

        const fetch = async() => {
            if (thumbnailPath) {
                const url = await getDownloadURL(ref(storage, thumbnailPath));
                if (isSubscribed) {
                    setImageUrl(url)
                }

                return;
            }

            if (filePath) {
                const url = await getDownloadURL(ref(storage, filePath));
                if (isSubscribed) {
                    setImageUrl(url)
                }

                return;
            }
        };

        setImageUrl(null);
        fetch();

        return () => isSubscribed = false;
    }, [image]);

    return (
        <FormProvider {...methods}>
            <Box>
                {isOfficer && (
                    <ItemDialog parent={parent} item={item} onSubmit={onUpdate} onDelete={handleDelete} open={editing} handleClose={() => setEditing(false)} />
                )}
                
                <Paper variant="outlined" sx={{p: 2, position: 'relative', mb: 1}}>
                    <Grid spacing={2} container>
                        {imageUrl && (
                            <Grid
                                size={{xs: 12, md: 4}}
                                sx={{aspectRatio: '4/3', borderRadius: 1, backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover'}}
                                onClick={() => window.open(imageUrl, '_blank')}
                            />
                        )}

                        <Grid size={{xs: 12, md: imageUrl ? 8 : 12}} spacing={1} container>
                            <HeaderItem label="Name" loading={loading} value={name} />
                        
                            {additionalFields.map((field, index) => {
                                const {key, label, type} = field;
                                const rawValue = item[key];
                                let value;

                                if (rawValue) {
                                    if (type === 'date') {
                                        value = moment(rawValue).format(dateFormat);
                                    } else if (type === 'apparatus') {
                                        const apparatus = item.apparatus || {};
                                        value = apparatus.tag;
                                    } else {
                                        value = rawValue;
                                    }
                                }

                                return (
                                    <HeaderItem key={`additional-fields-${index}`} label={label} loading={loading} value={value || '-'} />
                                );
                            })}
                        </Grid>
                    </Grid>
                
                    {isOfficer && (
                        <IconButton disabled={loading} sx={{ml: 1, position: 'absolute', bottom: 10, right: 10}} onClick={() => setEditing(true)}>
                            <EditIcon />
                        </IconButton>
                    )}
                </Paper>

                <TasksGrid sx={{mt: 3}} type="items" />

                <RecordsGrid sx={{mt: 3}} />
            </Box>
        </FormProvider>
    );
};