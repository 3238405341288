import React from 'react';
import {Typography} from '@mui/material';

import CategoriesGrid from './gear/CategoriesGrid';

export default function Gear() {
    return (
        <>
            <Typography variant="h5" gutterBottom>Gear</Typography>
            <CategoriesGrid />
        </>
    );
};