import React, {useState, useEffect, useContext} from 'react';
import {Grid, Skeleton, Box, Backdrop, CircularProgress, Button, Divider, Typography, Chip, Card, CardMedia} from '@mui/material';
import {doc, getDoc} from 'firebase/firestore';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import HideImageIcon from '@mui/icons-material/HideImage';
import {ref, getDownloadURL} from 'firebase/storage';

import {UserContext} from '../contexts/User';
import {db, storage} from '../firebase';

import useDocumentTitle from '../hooks/useDocumentTitle';

export default function Apparatus() {
    const {id: uid} = useParams();
    const isNew = !uid;

    const [loading, setLoading] = useState(!isNew);
    const [record, setRecord] = useState(null);
    const navigate = useNavigate();
    const {currentUser} = useContext(UserContext);
    const {isOfficer} = currentUser;

    useEffect(() => {
        let isSubscribed = true;

        const fetch = async() => {
            if (uid) {
                const ref = doc(db, 'apparatus', uid);
                const raw = await getDoc(ref);
                
                if (isSubscribed) {
                    if (!raw.exists()) {
                        navigate('/apparatus');
                    }

                    setRecord({
                        id: uid,
                        uid,
                        ...raw.data()
                    });

                    setLoading(false);
                }
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [db, uid, navigate]);

    useDocumentTitle(record ? record.tag : 'Apparatus');

    const {tag, color, image} = record || {};
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        const {filePath, thumbnailPath} = image || {};

        let isSubscribed = true;

        const fetch = async() => {
            if (thumbnailPath) {
                const url = await getDownloadURL(ref(storage, thumbnailPath));
                if (isSubscribed) {
                    setImageUrl(url)
                }

                return;
            }

            if (filePath) {
                const url = await getDownloadURL(ref(storage, filePath));
                if (isSubscribed) {
                    setImageUrl(url)
                }

                return;
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [image]);

    if (loading) {
        return (
            <Backdrop invisible open>
                <CircularProgress color="primary" />
            </Backdrop>
        );
    }

    const colorMap = {
        red: '#ce1618',
        yellow: '#fdd835'
    };

    const colorTextMap = {
        red: '#fff'
    };

    const colorVariantMap = {
        white: 'outlined'
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Box sx={{display: 'flex', pb: 1, alignItems: 'center'}}>
                <Typography variant="h5" sx={{flex: 1}}>{tag ? tag : <Skeleton width={180} />}</Typography>
                {color && (
                    <Chip
                        label={color.toUpperCase()}
                        sx={{
                            background: colorMap[color] || color,
                            '& .MuiChip-label': {
                                color: colorTextMap[color] || null
                            }
                        }}
                        variant={colorVariantMap[color] || 'filled'}
                    />
                )}
            </Box>

            <Divider sx={{mb: 2}} />

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Card sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <CardMedia
                            sx={{aspectRatio: '16/9', width: '100%', borderRadius: 0.5, backgroundColor: 'background.paper'}}
                            image={imageUrl}
                            title={tag}
                        >
                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', color: 'text.secondary', opacity: 0.5}}>
                                {!image && (
                                    <HideImageIcon sx={{color: 'grey.500', fontSize: 40}} />
                                )}
                            </Box>
                        </CardMedia>
                    </Card>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                    <Grid container gap={1} sx={{display: 'flex', flexDirection: 'column'}}>
                        <Button variant="outlined" onClick={() => navigate(`/apparatus/${uid}/weekly`)}>Weekly Truck Check</Button>
                        {/* <Button sx={{mt: 2}} variant="outlined" disabled onClick={() => navigate(`/apparatus/${uid}/full`)}>Full Truck Check</Button> */}

                        {isOfficer && (
                            <>
                                <Button variant="contained" onClick={() => navigate(`/apparatus/${uid}/edit`)}>Edit Apparatus</Button>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}