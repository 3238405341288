import React, {useState, useEffect, useCallback, useContext} from 'react';
import {Box, Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@mui/material';
import {useSnackbar} from 'notistack';
import {DateRangePicker} from '@mui/x-date-pickers-pro/DateRangePicker';
import {SingleInputDateRangeField} from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import {collection, query, where} from 'firebase/firestore';
import moment from 'moment';
import {useForm, FormProvider} from 'react-hook-form';
import {LoadingButton} from '@mui/lab';
import {useNavigate} from 'react-router-dom';
import {httpsCallable} from 'firebase/functions';
import {get} from 'lodash';

import {useGridApiRef} from '@mui/x-data-grid-pro';

import {db, functions} from '../firebase';
import {UserContext} from '../contexts/User';

import {getCollection, populateUsers, populateKeyFromCollection, exportGrid} from '../data/utils';

import TrainingGrid from './training/Grid';

import VSSync from '../dialogs/VSSync';

import Filter from '../components/Filter';
import Import from './training/Import';

const ImportFromIAR = props => {
    const [importing, setImporting] = useState(false);
    const [training, setTraining] = useState([]);
    const [open, setOpen] = useState(false);
    const [importDialogOpen, setImportDialogOpen] = useState(false);
    const [dateRange, setDateRange] = useState([props.startDate ? moment(props.startDate) : moment().subtract(1, 'month'), moment()]);
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const importTrainingFromIAR = httpsCallable(functions, 'importTrainingFromIAR');
    const {currentUser} = useContext(UserContext);
    const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';

    useEffect(() => {
        if (props.startDate) {
            setDateRange([moment(props.startDate), moment()]);
        }
    }, [props.startDate]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleImportClose = () => {
        setImportDialogOpen(false);
    };

    const handleImport = useCallback(async() => {
        setImporting(true);

        try {
            const [startDate, endDate] = dateRange;
            const {data} = await importTrainingFromIAR({
                startDate: startDate.toDate(),
                endDate: endDate.toDate()
            });

            const {training = []} = data;
            // incidents = await populateKeyFromCollection(db, incidents, 'station', 'stations');
            // incidents = await populateKeyFromCollection(db, incidents, 'apparatus', 'apparatus');
            // incidents = populateDocsWithUsers(incidents, users);
            setTraining(training.map(training => {
                const {date, suggestedTypes: types, suggestedInstructors: instructors, ...rest} = training;

                return {
                    ...rest,
                    date: new Date(date),
                    types,
                    instructors
                };
            }));

            setOpen(false);
            setImportDialogOpen(true);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setImporting(false);
    }, [importTrainingFromIAR, enqueueSnackbar, navigate]);

    return (
        <>
            <LoadingButton loading={importing} variant="outlined" sx={{mr: 1}} onClick={() => setOpen(true)}>
                Import latest from IaR
            </LoadingButton>

            <Import training={training} open={importDialogOpen} onClose={handleImportClose} />

            <Dialog
                open={open}
                onClose={importing ? null : handleClose}
                fullWidth
                maxWidth="sm"
                disableEscapeKeyDown={importing}
            >
                <DialogTitle>Import from IamResponding</DialogTitle>
                <DialogContent sx={{display: 'flex'}}>
                    <DateRangePicker
                        sx={{mt: 2, flex: 1}}
                        format={dateFormat}
                        label="Date Range"
                        disabled={importing}
                        slots={{field: SingleInputDateRangeField}}
                        value={dateRange}
                        onChange={range => setDateRange(range)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={importing} onClick={handleClose}>Cancel</Button>
                    <LoadingButton loading={importing} variant="contained" type="submit" onClick={handleImport}>Start Import</LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

const Training = () => {
    const [loading, setLoading] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [training, setTraining] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
    const apiRef = useGridApiRef();
    const {currentUser} = useContext(UserContext);
    const {isAdmin} = currentUser;
    const [dialogOpen, setDialogOpen] = useState(false);
    const [latestTrainingDate, setLatestTrainingDate] = useState(null);

    const methods = useForm({
        defaultValues: {
            dateRange: [moment().startOf('year'), moment()]
        },
        mode: 'onChange'
    });

    const {watch} = methods;
    const dateRange = watch('dateRange');
    const [startDate, endDate] = dateRange || [];

    const fetch = useCallback(async() => {
        setLoading(true);

        try {
            let [startDate, endDate] = dateRange || [];
            startDate = moment(startDate).startOf('day').toDate();
            endDate = moment(endDate).endOf('day').toDate();

            const ref = collection(db, 'training');
            const q = query(ref, where('date', '>=', startDate), where('date', '<=', endDate));
            let training = await getCollection(db, q);
            training = await populateUsers(db, training);
            training = await populateKeyFromCollection(db, training, 'types', 'trainingTypes');

            const orderedTraining = training.sort((a, b) => {
                return moment(b.date).diff(moment(a.date));
            });

            if (orderedTraining.length) {
                setLatestTrainingDate(orderedTraining[0].date);
            }

            setTraining(training);

            setTimeout(() => {
                apiRef.current.autosizeColumns({
                    columns: [
                        'instructors',
                        'type',
                        'raw.type',
                        'date'
                    ],
                    includeHeaders: true,
                    includeOutliers: true,
                    expand: true
                });
            }, 250);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    }, [db, dateRange, enqueueSnackbar]);

    useEffect(() => {
        fetch();
    }, [fetch, dateRange]);

    const handleSyncClose = useCallback(async newTraining => {
        setDialogOpen(false);

        if (newTraining) {
            setTraining(training.map(training => {
                if (training.uid === newTraining.uid) {
                    return {
                        ...training,
                        ...newTraining
                    };
                }

                return training;
            }));
        }
    }, [training]);

    const handleExport = useCallback(async() => {
        setExporting(true);

        try {
            const data = await exportGrid('training', {startDate, endDate});

            const start = moment(startDate).format('YYYY-MM-DD');
            const end = moment(endDate).format('YYYY-MM-DD');

            const href = URL.createObjectURL(data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `Training - ${start} to ${end}.xlsx`);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setExporting(false);
    }, [startDate, endDate, enqueueSnackbar]);

    return (
        <FormProvider {...methods}>
            {isAdmin && !!dialogOpen && (
                <VSSync open={!!dialogOpen} {...dialogOpen} onClose={handleSyncClose} />
            )}

            <Filter loading={loading}>
                {isAdmin && (
                    <ImportFromIAR startDate={latestTrainingDate} />
                )}
            </Filter>
            
            <Box sx={{pb: 2}}>
                <Box style={{display: 'flex', flexDirection: 'column'}}>
                    <TrainingGrid
                        apiRef={apiRef}
                        stateId="training"
                        training={training}
                        loading={loading}
                    />
                </Box>
            </Box>

            {isAdmin && (
                <Box sx={{pb: 2, display: 'flex', justifyContent: 'flex-end'}}>
                    <LoadingButton disabled={loading || !training.length} loading={exporting} variant="contained" sx={{ml: 1}} onClick={handleExport}>
                        Export Attendance
                    </LoadingButton>
                </Box>
            )}
        </FormProvider>
    );
};

export default Training;