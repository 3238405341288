import React from 'react';
import {Typography} from '@mui/material';

import Apparatuses from './apparatuses/Grid';

export default function() {
    return (
        <>
            <Typography variant="h5" gutterBottom>Apparatus</Typography>

            <Apparatuses />
        </>
    );
};