import {get, sortedUniq} from 'lodash';
import {flatten} from 'flat';

import NFPA from './standards/NFPA';

export {NFPA};

export const NFPAStandardsMap = (() => {
    const result = [];

    // Recursive function to traverse through the data structure
    const traverse = (obj, standard, edition, path = '') => {
        // Ensure the object exists and is valid for traversal
        if (!obj || typeof obj !== 'object') return;

        Object.keys(obj).forEach(key => {
            // Skip the 'other' property
            if (key === 'other') return;

            // Build the path (e.g., '1001:2019:4.1.1')
            const newPath = path ? `${path}.${key}` : key;
            
            // Check if the current section has a title or text (not all may have both)
            if (obj[key].title || obj[key].text) {
                const fullKey = `${standard}:${edition}:${newPath}`;
                result.push({
                    value: fullKey,
                    label: `NFPA ${standard} (${edition}): ${newPath}`
                });
            }
            
            // Recurse deeper into the structure
            traverse(obj[key], standard, edition, newPath);
        });
    };

    // Traverse the top-level NFPA object
    Object.keys(NFPA).forEach(standard => {
        const editions = NFPA[standard]?.editions;
        if (editions && typeof editions === 'object') {
            Object.keys(editions).forEach(edition => {
                traverse(editions[edition], standard, edition);
            });
        }
    });

    return result;
})();

export const WorksafeBC = {
    31: {
        2: {
            title: 'Application',
            text: 'This Part applies to employers and to workers who are employed in firefighting activities on a full or part time basis, including volunteer firefighting in municipal service and industrial fire brigades to which the compensation provisions of the Workers Compensation Act apply, but does not apply to forest fire fighting.'
        },
        3: {
            title: 'Health and Safety Committee',
            text: '(1) If an employer is required under 4) must not engage OHS provisions of the Workers Compensation Act to establish a joint commitee or worker health and safety representative, then a fire department or industrial fire brigade operated by the employer must have a separate joint committee or worker health and safety representative, as applicable.\n\n(2) Subsection (1) does not affect any obligation to have a workplace health and safety program for the whole of the employer\'s operations.'
        },
        4: {
            title: 'Instruction and direction',
            text: 'The employer must ensure the adequate instruction and direction of firefighters in the safe performance of their duties.'
        },
        5: {
            title: 'Procedures',
            text: '(1) Written procedures must be established and followed by a fire department or industrial fire brigade to\n\n(a) manage and track firefighters at an emergency incident,\n\n(b) manage exposure to bloodborne pathogens,\n\n(c) manage stress arising from an emergency incident that is likely to cause adverse health effect to firefighters,\n\n(d) provide for effective traffic control at emergency incidents, and\n\n(e) operate firefighting vehicles during emergency and non-emergency travel.\n\n(2) Written procedures must be established and followed by a fire department or industrial fire brigade for the following situations, where applicable:\n\n(a) fires in buildings 7 storeys or over;\n\n(b) firefighting over water and underground;\n\n(c) fires and other emergency incidents involving hazardous substances;\n\n(d) rescue from high angles, confined spaces, trenches, excavations and water;\n\n(e) disaster planning and response;\n\n(f) electrical emergencies.'
        },
        6: {
            title: 'Rest and rehabilitation',
            text: 'The incident commander must make suitable provision for rest and rehabilitation for firefighters at an emergency incident.'
        },
        17: {
            title: 'Fall Protection',
            text: '(1) A firefighter working on an aerial ladder must wear a safety belt and lanyard meeting the requirements of CSA Standard Z259.1-95, Safety Belts and Lanyards, and the securing lanyard must limit a fall to no more than 30 cm (12 in).\n\n(2) A firefighter located on an aerial platform must wear a full body harness and lanyard meeting the requirements of Part 11 (Fall Protection).\n\n(3) Rescue ropes, rappelling lines and safety belts and harnesses including safety hooks, rope grabs, lowering devices, and related equipment must meet the requirements of NFPA 1983, Fire Service Life Safety Rope, Harness and Hardware, 1990 Edition.\n\n(4) The incident commander may depart from the requirements of Part 11 (Fall Protection) to use a fall protection system if, in the incident commander\'s opinion, such compliance is not practicable or may create a greater hazard, but subsections (1) to (3) of this section must be complied with.'
        },
        23: {
            title: 'Entry into buildings',
            text: [
                '(1) When self-contained breathing apparatus must be used to enter a building, or similar enclosed location, the entry must be made by a team of at least 2 firefighters.',
                '(2) Effective voice communication must be maintained between firefighters inside and outside the enclosed location.',
                '(3) During the initial attack stages of an incident at least one firefighter must remain outside.',
                '(4) A suitably equipped rescue team of at least 2 firefighters must be established on the scene before sending in a second entry team and not more than 10 minutes after the initial attack.',
                '(5) The rescue team required by subsection (4) must not engage in any duties that limit their ability to make a prompt response to rescue an endangered firefighter while interior structural firefighting is being conducted.'
            ].join('\n\n')
        },
        37: {
            title: 'Ground ladders',
            text: '(1) A ground ladder used by firefighters must meet the requirements of NFPA 1931, Design of and Design Verification Tests for Fire Department Ground Ladders, 1989 Edition.\n\n(2) A ground ladder must be used, tested and maintained in accordance with the requirements of NFPA 1932, Use, Maintenance, and Service Testing of Fire Department Ground Ladders, 1989 Edition.'
        },
        38: {
            title: 'Flashlights and hand lanterns',
            text: 'Battery operated flashlights and hand lanterns that are CSA approved for hazardous locations classified under the CSA Standard C22.1-94, Canadian Electrical Code Part 1, as Class 1, Division 2, Groups A, B, and C must be provided as follows:\n\n(a) one flashlight for each firefighter;\n\n(b) at least 4 hand lanterns for each firefighting vehicle.'
        },
        39: {
            title: 'Plaster hooks and pike poles',
            text: 'Plaster hooks and pike poles must be fitted with electrically non-conductive shafts.'
        }
    }
};

export const WorksafeBCMap = (() => {
    const result = [];

    for (const part in WorksafeBC) {
        if (Object.hasOwn(WorksafeBC, part)) {
            for (const section in WorksafeBC[part]) {
                if (Object.hasOwn(WorksafeBC[part], section)) {
                    const sectionData = WorksafeBC[part][section];
                    result.push({
                        value: `WorksafeBC:${part}.${section}`,
                        label: `WorkSafeBC: ${part}.${section} ${sectionData.title}`
                    });
                }
            }
        }
    }

    return result;
})();

export const Competencies = {
    generalKnowledge: {
        label: 'General Knowledge, Safety, and Communications',
        shortLabel: 'General Knowledge'
    },
    ppeSCBA: {
        label: 'Personal Protective Clothing & Equipment (SCBA)',
        shortLabel: 'PPE & SCBA',
        otherRequirements: ['WorkSafeBC OHS Part 31 (31.10 to 31.26 & 31.38)']
    },
    waterSupply: {
        label: 'Water Supply'
    },
    hoseLinesNozzles: {
        label: 'Hose Lines, Nozzles, Appliances, and Fire Streams',
        shortLabel: 'Hose Lines & Nozzles'
    },
    groundLadders: {
        label: 'Ground Ladders',
        otherRequirements: ['WorkSafeBC OHS Part 31 (31.37)']
    },
    sceneLighting: {
        label: 'Scene Lighting and Utilities',
        shortLabel: 'Scene Lighting'
    },
    ropesKnots: {
        label: 'Ropes and Knots – Hoisting Tools and Equipment',
        otherRequirements: ['WorkSafeBC OHS Part 31 (31.39)'],
        shortLabel: 'Ropes & Knots'
    },
    buildingConstruction: {
        label: 'Building Construction and Fire Behaviour',
        shortLabel: 'Building Construction'
    },
    gasElectricity: {
        label: 'Gas & Electrical Safety for Firefighters',
        shortLabel: 'Gas & Electricity'
    },
    safeWorkAreas: {
        label: 'Establish Safe Work Areas, Traffic, and Scene Control',
        otherRequirements: ['WorkSafeBC OHS Part 31 (31.1(d), 18)'],
        shortLabel: 'Safe Work Areas'
    },
    forcibleEntry: {
        label: 'Forcible Entry'
    },
    ventilation: {
        label: 'Ventilation – Horizontal',
        shortLabel: 'Ventilation'
    },
    fireAttackExtinguishment: {
        label: 'Exterior Fire Attack/Extinguishment',
        shortLabel: 'Fire Attack'
    },
    hazmatAwareness: {
        label: 'HAZMAT Awareness'
    },
    ics100: {
        label: 'ICS 100'
    },

    // Driver/Operator
    evo: {
        label: 'Emergency Vehicle Operations (EVO)',
        shortLabel: 'EVO'
    },

    // Interior
    communications: {
        label: 'Communications'
    },
    exitHazardousArea: {
        label: 'Exit a Hazardous Area'
    },
    searchRescue: {
        label: 'Conduct a Search and Rescue',
        shortLabel: 'Search & Rescue'
    },
    overhaulSalvage: {
        label: 'Loss Control – Overhaul and Salvage',
        shortLabel: 'Overhaul & Salvage'
    },
    preIncidentPlanning: {
        label: 'Pre-Incident Planning'
    },
    interiorFireAttack: {
        label: 'Interior Fire Attack/Extinguishment',
        shortLabel: 'Interior Fire Attack'
    },
    rapidInterventionTeam: {
        label: 'Rapid Intervention Team Member (RIT)',
        shortLabel: 'RIT'
    },

    driverOperator: {
        label: 'Driver Operator'
    },
    incidentCommander: {
        label: 'Incident Commander',
        shortLabel: 'IC'
    },

    incidentManagement: {
        label: 'Incident Management'
    },
    personnelManagement: {
        label: 'Personnel Management and Supervision',
        shortLabel: 'Personnel Management'
    },
    sizeUp: {
        label: 'Task Size-Up'
    },
    actionPlanning: {
        label: 'Action Planning'
    },
    personnelAccountability: {
        label: 'Personnel Accountability'
    },
    ics200: {
        label: 'ICS 200'
    },
    emergencyServiceDelivery: {
        label: 'Emergency Service Delivery'
    },
    initiateIcs: {
        label: 'Initiate the Incident Command System',
        shortLabel: 'Initiate ICS'
    },
    iso: {
        label: 'Incident Safety Officer',
        shortLabel: 'ISO'
    },
    ics: {
        label: 'Incident Command System',
        shortLabel: 'ICS'
    },
    riskManagement: {
        label: 'Risk Management'
    },
    implementActionPlan: {
        label: 'Implementation of the Tactical Action Plan',
        shortLabel: 'Action Plan Implementation'
    },
    devlopIap: {
        label: 'Develop an Incident Action Plan',
        shortLabel: 'Develop IAP'
    }
};

export const OFCMinimumStandards = {
    exterior: {
        generalKnowledge: {
            jprs: [
                '1001:2019:4.1.1',
                '1001:2019:4.1.2',
                '1001:2019:4.2.1',
                '1001:2019:4.2.2',
                '1001:2019:4.2.3',
                '1001:2019:4.2.4',
                '1001:2019:4.3.2',
                '1500:2018:8.1',
                '1500:2018:8.2.1',
                '1500:2018:8.2.5.2',
                '1500:2018:8.5'
            ]
        },
        ppeSCBA: {
            jprs: [
                '1001:2019:4.1.2',
                '1001:2019:4.3.1',
                '1001:2019:4.3.2',
                '1001:2019:4.5.1',
                'WorksafeBC:31.10-31.26', // TODO
                'WorksafeBC:31.38'
            ]
        },
        waterSupply: {
            jprs: [
                '1001:2019:4.3.15',
                '1001:2019:4.5.1',
                '1001:2019:4.5.2'
            ]
        },
        hoseLinesNozzles: {
            jprs: [
                '1001:2019:4.3.7',
                '1001:2019:4.3.8',
                '1001:2019:4.5.1',
                '1001:2019:4.5.2'
            ]
        },
        groundLadders: {
            jprs: [
                '1001:2019:4.3.6',
                '1001:2019:4.5.1',
                'WorksafeBC:31.37'
            ]
        },
        sceneLighting: {
            jprs: [
                '1001:2019:4.3.17',
                '1001:2019:4.3.18'
            ]
        },
        ropesKnots: {
            jprs: [
                '1001:2019:4.1.2',
                '1001:2019:4.3.20',
                '1001:2019:4.5.1',
                'WorksafeBC:31.39'
            ]
        },
        buildingConstruction: {
            jprs: [
                '1001:2019:4.3.11',
                '220:2018:4',
                '921:2017:5',
                '5000:2018:7'
            ]
        },
        gasElectricity: {
            // TODO outside BC utility
            jprs: [
                'WorksafeBC:31.5'
            ]
        },
        safeWorkAreas: {
            jprs: [
                '1001:2019:4.3.3'
            ]
        },
        forcibleEntry: {
            jprs: [
                '1001:2019:4.3.4'
            ]
        },
        ventilation: {
            jprs: [
                '1001:2019:4.3.11',
                '1001:2019:4.5.1'
            ]
        },
        fireAttackExtinguishment: {
            jprs: [
                '1001:2019:4.3.5',
                '1001:2019:4.3.7',
                '1001:2019:4.3.8',
                '1001:2019:4.3.16',
                '1001:2019:4.3.19',
                '1001:2019:5.3.1',
                '1001:2019:5.3.3'
            ]
        },
        hazmatAwareness: {
            jprs: [
                '1072:2017:3', // OFC says 2019, but it is actually 2017
                '1072:2017:4', // OFC says 2019, but it is actually 2017
                'WorksafeBC:31.5'
            ]
        },
        ics100: {

        },

        levels: {
            driverOperator: {
                evo: {
                    jprs: [
                        'WorksafeBC:31.5',
                        'WorksafeBC:31.27-31.32',
                        '1002:2017:4.2',
                        '1002:2017:4.3',

                        // EVO
                        '1002:2017:4.2',
                        '1002:2017:5.1',
                        '1002:2017:5.2'
                    ]
                },
                waterSupply: {
                    jprs: [
                        '1002:2017:4.2',
                        '1002:2017:10.1',
                        '1002:2017:10.2'
                    ]
                }
            },
            teamLeader: {
                incidentManagement: {
                    jprs: [
                        '1001:2019:5.1.1',
                        '1001:2019:5.1.2',
                        '1001:2019:5.2.2',
                        '1001:2019:5.3.4'
                    ]
                },
                personnelManagement: {
                    jprs: [
                        '1021:2020:4.1.1'
                    ]
                },
                sizeUp: {
                    jprs: [
                        '1021:2020:4.2.1',
                        '1021:2020:4.2.2',
                        '1021:2020:4.2.3'
                    ]
                },
                actionPlanning: {
                    jprs: [
                        '1021:2020:4.6.1',
                        '1021:2020:4.6.2'
                    ]
                },
                personnelAccountability: {
                    jprs: [
                        '1500:2018:8.5.2',
                        '1500:2018:8.5.3',
                        '1500:2018:8.5.5',
                        '1500:2018:8.5.6',
                        '1500:2018:8.5.7',
                        '1500:2018:8.5.8',
                        '1500:2018:8.5.9',
                        'WorksafeBC:31.5'
                    ]
                },
                ics100: {

                }
            },
            incidentCommander: {
                personnelManagement: {
                    jprs: [
                        '1021:2020:4.1.1'
                    ]
                },
                sizeUp: {
                    jprs: [
                        '1021:2020:4.5.2',
                        '1021:2020:4.5.3'
                    ]
                },
                actionPlanning: {
                    jprs: [
                        '1021:2020:4.6.1',
                        '1021:2020:4.6.2'
                    ]
                },
                emergencyServiceDelivery: {
                    jprs: [
                        '1021:2020:4.1.2'
                    ]
                },
                initiateIcs: {
                    jprs: [
                        '1500:2018:8.1.5',
                        '1500:2018:8.1.6',
                        '1500:2018:8.1.7',
                        '1500:2018:8.1.8'
                    ]
                },
                personnelAccountability: {
                    jprs: [
                        '1500:2018:8.3',
                        '1500:2018:8.4',
                        '1500:2018:8.5.4',
                        '1500:2018:8.5.12',
                        '1500:2018:8.6',
                        '1500:2018:8.7',
                        '1500:2018:8.9',
                        'WorksafeBC:31.5'
                    ]
                },
                iso: {
                    jprs: [
                        '1521:2020:5.2.1',
                        '1521:2020:5.2.2',
                        '1521:2020:5.2.3',
                        '1521:2020:5.2.4',
                        '1521:2020:5.2.5',
                        '1521:2020:5.2.6',
                        '1521:2020:5.2.7',
                        '1521:2020:5.2.8',
                        '1521:2020:5.2.9',
                        '1521:2020:5.2.10',
                        '1521:2020:5.2.11',
                        '1521:2020:5.2.12',
                        '1521:2020:5.2.13',
                        '1521:2020:5.2.14',
                        '1521:2020:5.2.15',
                        'WorksafeBC:31.6'
                    ]
                },
                ics200: {
                    
                }
            }
        }
    },
    
    interior: {
        communications: {
            jprs: [
                '1001:2019:5.2.2'
            ]
        },
        exitHazardousArea: {
            jprs: [
                '1001:2019:4.3.5'
            ]
        },
        searchRescue: {
            jprs: [
                '1001:2019:4.3.9'
            ]
        },
        overhaulSalvage: {
            jprs: [
                '1001:2019:4.3.13',
                '1001:2019:4.3.14'
            ]
        },
        preIncidentPlanning: {
            jprs: [
                '1001:2019:5.5.3',
                '1620:2020'
            ]
        },
        interiorFireAttack: {
            jprs: [
                '1001:2019:4.3.9',
                '1001:2019:4.3.10',
                '1001:2019:4.3.13',
                '1001:2019:5.3.1',
                '1001:2019:5.3.3'
            ]
        },
        rapidInterventionTeam: {
            jprs: [
                '1407:2015:4',
                '1407:2015:5',
                '1407:2015:7',
                '1407:2015:8',
                'WorksafeBC:31.23'
            ]
        },
        levels: {
            driverOperator: {
                evo: {
                    jprs: [
                        'WorksafeBC:31.5',
                        'WorksafeBC:31.27-31.32',
                        '1002:2017:4.2',
                        '1002:2017:4.3',

                        // EVO
                        '1002:2017:4.2',
                        '1002:2017:5.1',
                        '1002:2017:5.2'
                    ]
                },
                waterSupply: {
                    jprs: [
                        '1002:2017:4.2',
                        '1002:2017:10.1',
                        '1002:2017:10.2'
                    ]
                }
            },
            teamLeader: {
                incidentManagement: {
                    jprs: [
                        '1001:2019:5.1.1',
                        '1001:2019:5.1.2',
                        '1001:2019:5.2.1'
                    ]
                },
                personnelManagement: {
                    jprs: [
                        '1021:2020:4.1.1',
                        '1021:2020:4.2.1',
                        '1021:2020:4.2.3',
                        '1021:2020:4.2.3'
                    ]
                },
                sizeUp: {
                    jprs: [
                        '1021:2020:4.5.2',
                        '1021:2020:4.5.3'
                    ]
                },
                riskManagement: {
                    jprs: [
                        '1500:2018:4.2',
                        '1500:2018:8.4',
                        '1500:2018:8.5'
                    ]
                },
                actionPlanning: {
                    jprs: [
                        '1001:2019:5.3.2',
                        '1021:2020:4.6.1',
                        '1021:2020:4.6.2'
                    ]
                },
                implementActionPlan: {
                    jprs: [
                        '1001:2019:5.3.4',
                        '1500:2018:8.6.4',
                        '1500:2018:8.6.5',
                        '1500:2018:8.6.6'
                    ]
                },
                communications: {
                    jprs: [
                        '1001:2019:5.3.2'
                    ]
                },
                rapidInterventionTeam: {
                    jprs: [
                        '1500:2018:8.8'
                    ]
                },
                personnelAccountability: {
                    jprs: [
                        '1500:2018:8.5.2',
                        '1500:2018:8.5.3',
                        '1500:2018:8.5.4',
                        '1500:2018:8.5.5',
                        '1500:2018:8.5.6',
                        '1500:2018:8.5.7',
                        '1500:2018:8.5.8',
                        '1500:2018:8.5.9',
                        '1500:2018:8.5.10',
                        '1500:2018:8.5.11',
                        'WorksafeBC:31.5'
                    ]
                },
                iso: {
                    jprs: [
                        '1521:2020:5.2'
                    ]
                },
                ics200: {

                }
            },
            ics: {
                ics: {
                    jprs: [
                        '1521:2020:5.3.1',
                        '1521:2020:5.3.2',
                        '1521:2020:5.3.3',
                        '1521:2020:5.3.4'
                    ]
                }
            },
            incidentCommander: {
                riskManagement: {
                    jprs: [
                        '1500:2018:8.6.8.1',
                        '1500:2018:8.7.1',
                        '1500:2018:8.7.4',
                        '1500:2018:8.7.4.4.1',
                        '1500:2018:8.7.4.4.4',
                        '1500:2018:8.7.5',
                        '1500:2018:8.8.1',
                        '1500:2018:8.8.4.1',
                        '1500:2018:8.8.7'
                    ]
                },
                initiateIcs: {
                    jprs: [
                        '1500:2018:8.1.1',
                        '1500:2018:8.1.2',
                        '1500:2018:8.1.3',
                        '1500:2018:8.1.5',
                        '1500:2018:8.1.6',
                        '1500:2018:8.1.7',
                        '1500:2018:8.1.8'
                    ]
                },
                devlopIap: {
                    jprs: [
                        '1021:2020:4.6.1'
                    ]
                },
                implementActionPlan: {
                    jprs: [
                        '1021:2020:4.6.2',
                        '1021:2020:8.6.1.3',
                        '1021:2020:8.6.13.1',
                        '1021:2020:8.6.13.2'
                    ]
                },
                communications: {
                    jprs: [
                        '1500:2018:8.6.15.6',
                        '1500:2018:8.7.2',
                        '1500:2018:8.7.3'
                    ]
                },
                personnelAccountability: {
                    jprs: [
                        'WorksafeBC:31.5'
                    ]
                }
            }
        }
    }
};

export const getStandardForKey = key => {
    if (!key || typeof key !== 'string') {
        return;
    }

    if (/worksafebc/i.test(key)) {
        const [, ...rest] = key.split(':');
        const {title, text, ...data} = get(WorksafeBC, rest.join('.')) || {};
        
        return {
            standard: {
                title: 'WorksafeBC',
                shortTitle: 'WorksafeBC'
            },
            key: rest.join('.'),
            title,
            shortTitle: `WorksafeBC: ${rest.join('.')}`,
            text,
            data
        };
    }

    const [standard, year, ...rest] = key.split(':');
    const {title: standardTitle} = get(NFPA, standard) || {};
    if (!standardTitle) {
        console.warn('Missing Standard Title:', standard);
        return;
    }

    const path = `${standard}.editions.${year}.${rest.join('.')}`;

    const standardData = get(NFPA, path);
    if (!standardData) {
        if (standardTitle) {
            // return {
            //     standard: {
            //         title: [`NFPA ${standard}`, standardTitle].filter(Boolean).join(': '),
            //         shortTitle: `NFPA ${standard}`,
            //         standard,
            //         edition: year,
            //     },
            //     key: [standard, year].join('.')
            // };
        }

        return;
    }
    
    const sectionData = get(NFPA, `${standard}.editions.${year}.${rest.join('.')}`);
    if (!sectionData) {
        return;
    }
    
    const {title, text, ...data} = sectionData;

    return {
        standard: {
            title: [`NFPA ${standard}`, standardTitle].filter(Boolean).join(': '),
            shortTitle: `NFPA ${standard}`,
            standard,
            edition: year
        },
        key: rest.join('.'),
        title,
        shortTitle: `NFPA ${standard}: ${rest.join('.')}`,
        text,
        data,
        parent: get(NFPA, path.split('.').slice(0, -1).join('.'))
    };
};

export const Standards = [...NFPAStandardsMap, ...WorksafeBCMap].map(({value, ...rest}) => ({
    ...getStandardForKey(value),
    ...rest,
    value
}));

const allMinimumStandards = sortedUniq(Object.values(flatten(OFCMinimumStandards)).filter(value => typeof value === 'string'));
const missingStandards = allMinimumStandards.map(key => {
    return {
        key,
        standard: getStandardForKey(key)
    };
}).filter(({standard}) => !standard).map(({key}) => key);

if (missingStandards.length) {
    console.warn('Missing Standards:', missingStandards);
}