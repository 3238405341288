import React from 'react';
import {Controller} from 'react-hook-form';
import {FormControlLabel, Checkbox} from '@mui/material';

export default function ControlledCheckboxField(props) {
    const {name, label, rules, margin, sx, labelPlacement = 'start', ...rest} = props;

    if (label === false) {
        return (
            <Controller
                name={name}
                rules={rules}
                render={({field}) => {
                    const {value, ...restField} = field;

                    return (
                        <Checkbox
                            checked={value || false}
                            sx={sx}
                            {...restField}
                            {...rest}
                        />
                    );
                }}
            />
        );
    }

    return (
        <Controller
            name={name}
            rules={rules}
            render={({field}) => {
                const {value, ...restField} = field;

                return (
                    <FormControlLabel
                        margin={margin}
                        sx={sx}
                        labelPlacement={labelPlacement}
                        control={
                            <Checkbox
                                checked={value || false}
                                {...restField}
                                {...rest}
                            />
                        }
                        label={label}
                    />
                );
            }}
        />
    );
};
