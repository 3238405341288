import React, {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer, {drawerClasses} from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import {signOut} from 'firebase/auth';

import {auth} from '../../firebase';
import {UserContext} from '../../contexts/User';

import {Ranks} from '../../data/utils';

import MenuContent from './MenuContent';
import UserAvatar from '../UserAvatar';

export default function({open, toggleDrawer}) {
    const navigate = useNavigate();
    const {currentUser} = useContext(UserContext);
    const {email, fullName, role, isStation} = currentUser || {};

    const onLogOut = async() => {
        await signOut(auth);

        navigate('/login');
    };

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={toggleDrawer(false)}
            sx={{
                zIndex: theme => theme.zIndex.drawer + 1,
                [`& .${drawerClasses.paper}`]: {
                    minWidth: '70vw',
                    backgroundImage: 'none',
                    backgroundColor: 'background.paper'
                }
            }}
        >
            <Stack
                sx={{
                    maxWidth: '70dvw',
                    height: '100%'
                }}
            >
                <Stack direction="row" sx={{p: 2, pb: 0, gap: 1}}>
                    <Stack
                        direction="row"
                        sx={{gap: 1, alignItems: 'center', flexGrow: 1, p: 1}}
                    >
                        <UserAvatar user={currentUser} sizes="small" sx={{width: 36, height: 36}} />
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Typography variant="h6" sx={{lineHeight: 1}}>
                                {fullName || email}
                            </Typography>
                            {!isStation && (
                                <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                    {Ranks[role]}
                                </Typography>
                            )}
                        </Box>
                    </Stack>
                </Stack>
                <Divider />
                <Stack sx={{flexGrow: 1}}>
                    <MenuContent toggleDrawer={toggleDrawer(false)} />
                    <Divider />
                </Stack>
                <Stack sx={{p: 2}}>
                    <Button variant="outlined" onClick={onLogOut} fullWidth startIcon={<LogoutRoundedIcon />}>
                        Logout
                    </Button>
                </Stack>
            </Stack>
        </Drawer>
    );
}
