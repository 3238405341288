import React, {useState, useEffect, useCallback} from 'react';
import {Box} from '@mui/material';
import {useSnackbar} from 'notistack';
import {DataGridPro} from '@mui/x-data-grid-pro';
import {collection, getDocs, query} from 'firebase/firestore';
import {useForm, FormProvider} from 'react-hook-form';
import {LoadingButton} from '@mui/lab';
import {httpsCallable} from 'firebase/functions';

import {db, functions} from '../../firebase.js';

// import DatePickerField from '../form/DatePickerField.js';
// import SelectField from '../form/SelectField.js';

// import IncidentsGrid from './incidents/Grid';
// import Stats from './incidents/Stats';

// const ImportFromIAR = () => {
//     const [importing, setImporting] = useState(false);
//     const [open, setOpen] = useState(false);
//     const [startDate, setStartDate] = useState(new Date());
//     const navigate = useNavigate();
//     const {enqueueSnackbar} = useSnackbar();
//     const importIncidentsFromIAR = httpsCallable(functions, 'importIncidentsFromIAR');

//     const handleClose = () => {
//         setOpen(false);
//     };

//     const handleImport = useCallback(async() => {
//         setImporting(true);

//         try {
//             const {data} = await importIncidentsFromIAR({startDate});
//             const {incidents = []} = data;

//             navigate('/incidents/import', {state: {
//                 incidents: incidents.map(incident => {
//                     const{date, ...rest} = incident;

//                     return {
//                         ...rest,
//                         date: new Date(date)
//                     };
//                 })
//             }});
//         } catch(e) {
//             enqueueSnackbar(e.message, {variant: 'error'});
//         }

//         setImporting(false);
//     }, [importIncidentsFromIAR, enqueueSnackbar, navigate, startDate]);

//     return (
//         <>
//             <LoadingButton loading={importing} variant="contained" sx={{ml: 1}} onClick={() => setOpen(true)}>
//                 Import latest from IAR
//             </LoadingButton>

//             <Dialog
//                 open={open}
//                 onClose={handleClose}
//                 PaperProps={{
//                     component: 'form',
//                     onSubmit: e => {
//                         e.preventDefault();

//                         handleImport();
//                     }
//                 }}
//             >
//                 <DialogTitle>Import from IamResponding</DialogTitle>
//                 <DialogContent>
//                     <DatePicker
//                         label={"Start Date"}
//                         inputFormat="DD/MM/yyyy"
//                         value={startDate}
//                         onChange={date => setStartDate(date && date.toDate())}
//                         renderInput={params => (
//                             <TextField
//                                 fullWidth
//                                 name="startDate"
//                                 margin="dense"
//                                 {...params}
//                             />
//                         )}
//                     />
//                 </DialogContent>
//                 <DialogActions>
//                     <Button disabled={importing} onClick={handleClose}>Cancel</Button>
//                     <LoadingButton loading={importing} type="submit">Start Import</LoadingButton>
//                 </DialogActions>
//             </Dialog>
//         </>
//     );
// };

// const Filter = ({loading, onSubmit}) => {
//     const {handleSubmit, watch, reset} = useFormContext();
//     const dateType = watch('dateType');

//     useEffect(() => {
//         if (dateType === 'thisYear') {
//             const startDate = moment().startOf('year').toDate();
//             const endDate = moment().endOf('year').toDate();

//             reset({dateType, startDate, endDate});
//         } else if (dateType === 'lastYear') {
//             const startDate = moment().subtract(1, 'year').startOf('year').toDate();
//             const endDate = moment().subtract(1, 'year').endOf('year').toDate();

//             reset({dateType, startDate, endDate});
//         } else if (dateType === 'thisQuarter') {
//             const startDate = moment().startOf('quarter').toDate();
//             const endDate = moment().endOf('quarter').toDate();

//             reset({dateType, startDate, endDate});
//         } else if (dateType === 'lastQuarter') {
//             const startDate = moment().startOf('quarter').subtract(1, 'quarter').toDate();
//             const endDate = moment().endOf('quarter').subtract(1, 'quarter').toDate();

//             reset({dateType, startDate, endDate});
//         } else if (dateType === 'thisMonth') {
//             const startDate = moment().startOf('month').toDate();
//             const endDate = moment().endOf('month').toDate();

//             reset({dateType, startDate, endDate});
//         }
//     }, [dateType, reset]);

//     return (
//         <Card sx={{mb: 2}}>
//             <CardContent sx={{p: 2}}>
//                 <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end', pt: 1}} component="form" onSubmit={handleSubmit(onSubmit)}>
//                     <Box sx={{flex: 1}}>
//                         <SelectField
//                             sx={{mr: 1, width: 250}}
//                             label="Search Queries"
//                             name="dateType"
//                             disabled={loading}
//                             options={[
//                                 {label: 'This Year', value: 'thisYear'},
//                                 {label: 'This Quarter', value: 'thisQuarter'},
//                                 {label: 'This Month', value: 'thisMonth'},
//                                 {label: 'Last Year', value: 'lastYear'},
//                                 {label: 'Last Quarter', value: 'lastQuarter'}
//                             ]}
//                         />

//                         <DatePickerField
//                             sx={{mr: 1, width: 250}}
//                             label="End Date"
//                             name="startDate"
//                             disabled={loading}
//                         />
//                         <DatePickerField
//                             sx={{mr: 1, width: 250}}
//                             label="End Date"
//                             name="endDate"
//                             disabled={loading}
//                         />
//                     </Box>

//                     <LoadingButton
//                         type="submit"
//                         variant="contained"
//                         onClick={handleSubmit(onSubmit)}
//                         disabled={loading}
//                         loading={loading}
//                     >
//                         Search
//                     </LoadingButton>
//                 </Box>
//             </CardContent>
//         </Card>
//     );
// };

const Activities = () => {
    const [loading, setLoading] = useState(true);
    const [syncing, setSyncing] = useState(false);
    const [activities, setActivities] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
    const vsSyncActivities = httpsCallable(functions, 'vsSyncActivities');

    // const defaultValues = useMemo(() => ({
    //     dateType: 'thisYear',
    //     startDate: moment().startOf('year').toDate(),
    //     endDate: moment().toDate()
    // }));

    const methods = useForm({
        // defaultValues,
        mode: 'onChange'
    });

    const onSubmit = useCallback(async () => {
        // let {startDate, endDate} = data;
        // if (!startDate || !endDate) {
        //     return;
        // }

        async function fetch() {
            setLoading(true);

            try {
                const ref = collection(db, 'vsActivities');
                const q = query(ref); //, where('date', '>=', startDate), where('date', '<=', endDate)
                const raw = await getDocs(q);
                let activities = [];

                raw.forEach(doc => {
                    const data = doc.data();

                    activities.push({
                        id: doc.id,
                        uid: doc.id,
                        ...data
                    });
                });

                setActivities(activities);
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }

            setLoading(false);
        }

        fetch();
    }, [enqueueSnackbar, db]);

    useEffect(() => {
        onSubmit();
    }, []);

    // <Filter loading={loading} onSubmit={onSubmit} />

    const handleSync = useCallback(async() => {
        setSyncing(true);

        try {
            await vsSyncActivities();

            enqueueSnackbar('Synced', {variant: 'success'});

            onSubmit();
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setSyncing(false);
    }, [enqueueSnackbar, vsSyncActivities, onSubmit]);
    
    const columns = [
        {
            field: 'title',
            headerName: 'Title',
            sortable: true,
            flex: 1
        }
    ];

    // <ImportFromIAR />
    return (
        <FormProvider {...methods}>
            <Box sx={{mb: 2, display: 'flex', justifyContent: 'flex-end'}}>
                <LoadingButton loading={syncing} variant="contained" onClick={handleSync}>Sync</LoadingButton>
            </Box>

            <Box sx={{opacity: loading ? .5 : 1}}>
                <DataGridPro
                    initialState={{
                        sorting: {
                            sortModel: [
                                {field: 'title', sort: 'asc'}
                            ]
                        }
                    }}
                    loading={loading}
                    autoHeight
                    rows={activities}
                    columns={columns}
                    pageSizeOptions={[]}
                    disableRowSelectionOnClick
                />
            </Box>
        </FormProvider>
    );
};

export default Activities;