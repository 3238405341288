import React, {useEffect, useState, useContext, useMemo} from 'react';
import {Box, Typography, Breadcrumbs, Link, Skeleton, useMediaQuery} from '@mui/material';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import {collection, doc, getDoc} from 'firebase/firestore';
import moment from 'moment';
import {get, castArray} from 'lodash';
import {useTheme} from '@mui/material/styles';

import {db} from '../firebase';

import {populateCollectionIds, populateKeyFromCollection, processRawDoc} from '../data/utils';

import useDocumentTitle from '../hooks/useDocumentTitle';

import {UserContext} from '../contexts/User';

const LinkRouter = props => <Link {...props} component={RouterLink} />;

const generateDynamicBreadcrumbs = (collection, field) => {
    const collections = castArray(collection);

    return ({match}) => {
        const {currentUser} = useContext(UserContext);
        
        const {params} = match;
        const keys = Object.keys(params);
        const [record, setRecord] = useState(null);

        const args = useMemo(() => [
            db,
            ...keys.reduce((acc, key, index) => {
                acc.push(collections[index]);
                acc.push(params[key]);

                return acc;
            }, [])
        ], [db, keys]);
    
        useEffect(() => {
            let isSubscribed = true;
    
            const fetch = async() => {
                const ref = doc(...args);
                const raw = await getDoc(ref);
                
                if (isSubscribed) {
                    let doc = processRawDoc(raw);
                    const apparatus = get(doc, 'apparatus');
                    if (apparatus) {
                        doc = await populateKeyFromCollection(db, doc, 'apparatus', 'apparatus');
                    }

                    setRecord(doc);
                }
            };
    
            fetch();
    
            return () => isSubscribed = false;
        }, []);
    
        if (!record) {
            return <Skeleton variant="text" width={60} />;
        }

        if (typeof field === 'string') {
            const value = get(record, field);
            if (!value) {
                return get(record, 'id');
            }

            if (value.toDate) {
                const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';
                return moment(value.toDate()).format(dateFormat);
            }

            return value;
        }

        return field(record);
    };
};

const DynamicApparatusChecksBreadcrumbs = ({match}) => {
    const {params} = match;
    const {apparatusId, id} = params;
    const [doc, setDoc] = useState(null);
    const {currentUser} = useContext(UserContext);
    const dateFormatLong = get(currentUser, 'settings.dateFormatLong') || 'LLL';

    useEffect(() => {
        let isSubscribed = true;

        const fetch = async() => {
            const ref = collection(db, 'apparatus', apparatusId, 'checks');
            const docs = await populateCollectionIds(db, ref, [id]);
            
            if (isSubscribed) {
                setDoc(docs[id]);
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [db, id, apparatusId]);

    let {createdAt} = doc || {};
    if (createdAt) {
        createdAt = moment(createdAt.toDate()).format(dateFormatLong);
    }

    if (!doc) {
        return <Skeleton variant="text" width={60} />;
    }

    return createdAt || id;
};

const generateGearBreadcrumbPaths = (maxDepth = 10) => {
    const paths = [
        {path: 'gear/:id', breadcrumb: generateDynamicBreadcrumbs(['gear'], 'name')}
    ];

    let path = 'gear/:collectionId';
    let collectionNames = ['gear'];

    for (let depth = 1; depth <= maxDepth; depth++) {
        if (depth === 1) {
            // First level: gear/:collectionId/:id with breadcrumb as ['gear', 'items']
            paths.push({
                path: `${path}/:id`,
                breadcrumb: generateDynamicBreadcrumbs([...collectionNames, 'items'], 'name')
            });
        } else if (depth % 2 === 0) {
            // Even depth: add `/gear` for collection, then `/gear/:id` with breadcrumb ['gear', ..., 'items']
            path += '/gear';
            paths.push({
                path: `${path}`,
                breadcrumb: null
            });

            paths.push({
                path: `${path}/:id`,
                breadcrumb: generateDynamicBreadcrumbs([...collectionNames, 'gear', 'items'], record => {
                    const {name, apparatus} = record || {};
                    const tag = get(apparatus, 'tag');
                    if (tag) {
                        return `${name} (${tag})`;
                    }
                    
                    return name;
                })
            });
        } else {
            // Odd depth: add `/:subCollectionIdX/:id` for item breadcrumb with ['gear', ..., 'items']
            path += `/:subCollectionId${Math.floor((depth - 2) / 2)}`;
            paths.push({
                path: `${path}/:id`,
                breadcrumb: generateDynamicBreadcrumbs([...collectionNames, 'items'], 'name')
            });
        }

        // Update collectionNames to add 'gear' for each collection depth, ending with 'items' as needed
        if (depth % 2 !== 0) {
            collectionNames.push('gear');
        }
    }

    return paths;
};

const routes = [
    {path: '/users/:id', breadcrumb: generateDynamicBreadcrumbs('users', 'fullName')},
    {path: '/users/:id/skills', breadcrumb: 'Skills'},
    {path: '/users/:id/statistics', breadcrumb: 'Statistics'},
    {path: '/users/:id/transcript', breadcrumb: 'Transcript'},
    {path: '/users/:id/jibcConsent', breadcrumb: 'JIBC Consent Form'},
    {path: '/apparatus/:id', breadcrumb: generateDynamicBreadcrumbs('apparatus', 'tag')},
    {path: '/apparatus/new', breadcrumb: 'New'},
    {path: '/apparatus/:apparatusId/weekly', breadcrumb: 'Weekly Truck Check'},
    {path: '/apparatus/:apparatusId/full', breadcrumb: 'Full Truck Checks'},
    {path: '/apparatus/:apparatusId/edit', breadcrumb: 'Edit Apparatus'},
    {path: '/apparatus/:apparatusId/:type/:id', breadcrumb: DynamicApparatusChecksBreadcrumbs},
    {path: '/apparatus/:apparatusId/:type/edit', breadcrumb: 'Edit'},
    {path: '/apparatus/:apparatusId/:type/start', breadcrumb: 'Start'},
    {path: '/matrix', breadcrumb: 'Matrix'},
    {path: '/matrix/qualification', breadcrumb: 'Qualification'},
    {path: '/matrix/training', breadcrumb: 'Training'},
    {path: '/calendar', breadcrumb: 'Calendar'},
    {path: '/profile', breadcrumb: 'Profile'},
    {path: '/profile/skills', breadcrumb: 'Skills'},
    {path: '/profile/statistics', breadcrumb: 'Statistics'},
    {path: '/profile/transcript', breadcrumb: 'Transcript'},
    {path: '/users', breadcrumb: 'Users'},
    {path: '/users/new', breadcrumb: 'New'},
    {path: '/ropes', breadcrumb: 'Ropes'},
    {path: '/incidents', breadcrumb: 'Incidents'},
    {path: '/incidents/:id', breadcrumb: generateDynamicBreadcrumbs('incidents', 'incidentNumber')},
    {path: '/incidents/import', breadcrumb: 'Import'},
    {path: '/training', breadcrumb: 'Training Records'},
    {path: '/training/:id', breadcrumb: generateDynamicBreadcrumbs('training', 'raw.type')},
    {path: '/training/keywords', breadcrumb: 'Keywords'},
    {path: '/training/import', breadcrumb: 'Import'},
    {path: '/vs', breadcrumb: 'Vector Solutions'},
    {path: '/vs/activities', breadcrumb: 'Activities'},
    {path: '/nfpa', breadcrumb: 'NFPA'},
    {path: '/gear', breadcrumb: 'Gear'},
    {path: '/respond', breadcrumb: 'Respond'},
    {path: '/respond/:id', breadcrumb: generateDynamicBreadcrumbs('responses', 'location')},
    ...generateGearBreadcrumbPaths()
];

const HeaderBreadcrumbs = () => {
    let breadcrumbs = useBreadcrumbs(routes);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const {pathname} = useLocation();

    if (pathname === '/') {
        breadcrumbs = [];
    }

    let text;
    if (breadcrumbs.length > 0) {
        const {breadcrumb} = breadcrumbs[breadcrumbs.length - 1];
        text = get(breadcrumb, 'props.children');
    }

    useDocumentTitle(text);

    if (!breadcrumbs.length) {
        return (
            <Box sx={{pt: 2}} />
        );
    }

    return (
        <Breadcrumbs aria-label="breadcrumb" sx={{mb: isSmall ? 2 : 3}}>
            {breadcrumbs.map((item, index) => {
                const {breadcrumb, key} = item;
                const last = breadcrumbs.length - 1 === index;
                const ignoreLink = last || key === '/matrix';

                return ignoreLink ? (
                    <Typography color="textPrimary" key={key}>
                        {breadcrumb}
                    </Typography>
                ) : (
                    <LinkRouter color="inherit" to={key} key={key}>
                        {breadcrumb}
                    </LinkRouter>
                );
            })}
        </Breadcrumbs>
    );
};

export default HeaderBreadcrumbs;
