import React, {useState, useCallback, useEffect, useContext} from 'react';
import {Box, Link, Typography, Card, CardContent, Skeleton} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import moment from 'moment';
import {useSnackbar} from 'notistack';
import {Viewer, Worker} from '@react-pdf-viewer/core';
import {ref, getDownloadURL} from 'firebase/storage';
import {get} from 'lodash';

import {storage} from '../../firebase.js';
import {UserContext} from '../../contexts/User';

import {uploadFile} from '../../data/utils';

const PDF = ({url}) => {
    if (!url) {
        return null;
    }

    return (
        <Worker workerUrl={'https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'}>
            <Viewer fileUrl={url} />
        </Worker>
    );
};

export default function User(props) {
    const {user = {}} = props;

    const {uid, files = []} = user || {};
    const consentFile = files.find(file => file.grouping === 'jibcConsent');
    const [consetUrl, setConsentUrl] = useState(null);

    const [loading, setLoading] = useState(false);
    const [loadingFile, setLoadingFile] = useState(consentFile);
    const {enqueueSnackbar} = useSnackbar();
    const {currentUser} = useContext(UserContext);
    const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';

    const fetchTranscript = useCallback(async () => {
        const {filePath} = consentFile || {};
        if (!filePath) {
            setConsentUrl(null);
            return;
        }

        const url = await getDownloadURL(ref(storage, consentFile.filePath));
        setConsentUrl(url);
        setLoadingFile(false);
    }, [consentFile]);

    useEffect(() => {
        fetchTranscript();
    }, [consentFile]);

    const handleFileUpload = useCallback(async file => {
        if (!file || !uid) {
            return;
        }

        setLoading(true);
        
        try {
            await uploadFile(`users/${uid}`, file, 'jibcConsent', true);

            setLoading(false);
            
            enqueueSnackbar('Consent form uploaded successfully!', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e.message || 'Error uploading transcript', {variant: 'error'});
        }

        setLoading(false);
    }, [uid, enqueueSnackbar]);

    return (
        <Box>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 2}}>
                {consentFile && (
                    <Typography variant="caption" sx={{mr: 2}}>
                        {`Uploaded ${moment(consentFile.createdAt).format(dateFormat)}`}
                    </Typography>
                )}
                <LoadingButton loading={loading} variant="contained" component="label">
                    Upload JIBC Transcript
                    <input
                        type="file"
                        hidden
                        accept="application/pdf"
                        onChange={e => {
                            const file = e.target.files[0];
                            handleFileUpload(file);
                            e.target.value = '';
                        }}
                    />
                </LoadingButton>
            </Box>

            {!!consentFile && (
                <Card variant="outlined" sx={{mt: 2}}>
                    {loadingFile ? (
                        <Skeleton variant="rectangular" sx={{height: 500}} />
                    ) : (
                        <Box sx={{py: 2}}>
                            <PDF url={consetUrl} />
                        </Box>
                    )}
                </Card>
            )}
        </Box>
    );
}