const features = {
    authIssues: true,
    jibcConsent: true,
    memberStatistics: false,
    // impersonateUser: false,
    vectorSolutionsSync: false,
    ropes: false,
    respond: false,
    training: true,
    trainingStatistics: false,
    incidents: true,
    importIncidentsFromIAR: true
};

export const hasFeature = feature => {
    return features[feature] === true;
};