import React from 'react';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {inputsCustomizations} from './customizations/inputs';
import {feedbackCustomizations} from './customizations/feedback';
import {navigationCustomizations} from './customizations/navigation';
import {surfacesCustomizations} from './customizations/surfaces';
import {dataGridCustomizations} from './customizations/dataGrid';
import {datePickersCustomizations} from './customizations/datePickers';
import {dataDisplayCustomizations} from './customizations/dataDisplay';
// import { chartsCustomizations } from './customizations/charts';
// import { treeViewCustomizations } from './customizations/treeView';
import {colorSchemes, typography, shadows, shape} from './themePrimitives';

export default function AppTheme({children}) {
    const theme = React.useMemo(() => {
        return createTheme({
            cssVariables: {
                colorSchemeSelector: 'data-mui-color-scheme',
                cssVarPrefix: 'template'
            },
            colorSchemes,
            typography,
            shadows,
            shape,
            components: {
                ...inputsCustomizations,
                ...feedbackCustomizations,
                ...navigationCustomizations,
                ...dataGridCustomizations,
                ...surfacesCustomizations,
                ...datePickersCustomizations,
                ...dataDisplayCustomizations

                // ...chartsCustomizations,
                // ...treeViewCustomizations,
            }
        });
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
}
