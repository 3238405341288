import React, {useState, useMemo, useEffect, useCallback, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Grid2 as Grid, IconButton, Box, Typography, Paper, Skeleton} from '@mui/material';
import {useSnackbar} from 'notistack';
import {get, isString, omit} from 'lodash';
import {useParams} from 'react-router-dom';
import {doc, updateDoc, deleteDoc, onSnapshot} from 'firebase/firestore';
import EditIcon from '@mui/icons-material/Edit';
import {grey} from '@mui/material/colors';
import * as MaterialIcons from '@mui/icons-material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {ref, getDownloadURL} from 'firebase/storage';

import * as Icons from '../components/Icons';

import {db, storage} from '../firebase';
import {UserContext} from '../contexts/User';

import {processRawDoc, getCollectionDoc, verifyOfficer, ensureJSDates, uploadImage} from '../data/utils';

import useDocumentTitle from '../hooks/useDocumentTitle';

import CategoryDialog from './gear/CategoryDialog';
import CategoriesGrid from './gear/CategoriesGrid';
import ItemsGrid from './gear/ItemsGrid';
import TasksGrid from './gear/TasksGrid';

import Placement from './gear/collection/Placement';

import {useItems} from './gear/ItemsGrid';

const ContentTabs = ({items = [], imageUrl}) => {
    const hasPlacement = imageUrl && items.length && items.some(item => item.position);
    const [activeTab, setActiveTab] = useState(hasPlacement ? 'placement' : 'items');
    const {currentUser} = useContext(UserContext);
    const isOfficer = verifyOfficer(currentUser);

    useEffect(() => {
        if (hasPlacement) {
            setActiveTab('placement');
        } else {
            setActiveTab('items');
        }
    }, [hasPlacement]);

    let value = activeTab;
    if (!hasPlacement && value === 'placement') {
        value = 'items';
    }

    return (
        <>
            <Box sx={{borderBottom: 1, borderColor: 'divider', mt: 2, mb: 1}}>
                <Tabs
                    value={value}
                    onChange={(e, newValue) => setActiveTab(newValue)}
                    TabIndicatorProps={{
                        style: {transition: 'none'}
                    }}
                >
                    {isOfficer && <Tab label="Tasks" value="tasks" />}
                    <Tab label="Items" value="items" />
                    {hasPlacement && <Tab label="Location" value="placement" />}
                </Tabs>
            </Box>
        
            {activeTab === 'tasks' && <TasksGrid />}
            {activeTab === 'items' && <ItemsGrid />}
            {activeTab === 'placement' && <Placement imageUrl={imageUrl} />}
        </>
    );
};

export default function GearCollection() {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [row, setRow] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    const navigate = useNavigate();
    const {currentUser} = useContext(UserContext);
    const isOfficer = verifyOfficer(currentUser);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {items} = useItems();

    const {name, icon, apparatus, image} = row || {};
    const Icon = icon && icon.value && (Icons[icon.value] || MaterialIcons[icon.value]);

    const refArgs = useMemo(() => {
        let refArgs = [db];

        Object.entries(params).forEach(([key, param]) => {
            if (key !== '*' && param) {
                refArgs.push('gear', param);
            }
        });

        return refArgs;
    }, [db, params]);

    const deleteUrl = useMemo(() => {
        let parts = [];

        Object.entries(params).forEach(([key, value]) => {
            if (key !== '*' && value) {
                parts.push('gear', value);
            }
        });

        if (parts.length === 2) {
            return '/gear';
        }

        return `/${parts.slice(0, -2).join('/')}`;
    }, [params]);
    
    useEffect(() => {
        setLoading(true);

        const ref = doc(...refArgs);
        const unsubscribe = onSnapshot(ref, async snapshot => {
            if (!snapshot.exists()) {
                navigate(deleteUrl);
                return;
            }

            const row = processRawDoc(snapshot);
            if (!row) {
                return;
            }

            const {icon, apparatus: rawApparatus, ...rest} = row;
            const apparatusUid = isString(rawApparatus) ? rawApparatus : get(rawApparatus, 'uid');
            let apparatus = await getCollectionDoc(db, 'apparatus', apparatusUid);

            setRow({
                ...rest,
                icon: icon ? {value: icon, label: icon} : null,
                apparatus
            });

            setLoading(false);
        });
        
        return () => {
            unsubscribe();
        };
    }, [db, refArgs, deleteUrl, navigate]);

    useDocumentTitle(name);

    const onUpdate = async data => {
        setLoading(true);

        const {icon, imageFile, apparatus, ...rest} = omit(data, 'uid');

        let image;
        if (imageFile) {
            const path = refArgs.slice(1).join('/');
            image = await uploadImage(path, imageFile);
        } else if (imageFile === null) {
            image = null;
        }

        const toUpdate = ensureJSDates({
            ...rest,
            image
        });

        toUpdate.icon = icon ? (icon.value || icon) : null;

        if (apparatus) {
            toUpdate.apparatus = typeof apparatus === 'string' ? apparatus : apparatus.uid;
        } else {
            toUpdate.apparatus = null;
        }

        if (Object.keys(toUpdate)) {
            await updateDoc(doc(...refArgs), {
                ...toUpdate,
                updatedAt: new Date()
            });

            if (toUpdate.apparatus) {
                toUpdate.apparatus = await getCollectionDoc(db, 'apparatus', toUpdate.apparatus);
            }

            setRow({
                ...toUpdate,
                image,
                ...icon && {icon: {value: icon, label: icon}}
            });
        }

        setLoading(false);

        return true;
    };

    const handleDelete = useCallback(() => {
        const onDelete = async() => {
            setLoading(true);

            const ref = doc(...refArgs);
            await deleteDoc(ref);

            navigate(deleteUrl);
        };

        return new Promise(resolve => {
            enqueueSnackbar(`Are you sure you want to delete ${name}? All information, items and categories will be deleted.`, {
                variant: 'warning',
                action: key => {
                    return (
                        <>
                            <Button onClick={() => {
                                resolve(true);
                                closeSnackbar(key);
                                onDelete();
                            }}>
                                Delete
                            </Button>
                            <Button onClick={() => {
                                resolve(false);
                                closeSnackbar(key);
                            }}>
                                Cancel
                            </Button>
                        </>
                    );
                }
            });
        });
    }, [refArgs, enqueueSnackbar, closeSnackbar, navigate, deleteUrl, name]);

    useEffect(() => {
        const {filePath, thumbnailPath} = image || {};

        let isSubscribed = true;

        const fetch = async() => {
            if (thumbnailPath) {
                const url = await getDownloadURL(ref(storage, thumbnailPath));
                if (isSubscribed) {
                    setImageUrl(url)
                }

                return;
            }

            if (filePath) {
                const url = await getDownloadURL(ref(storage, filePath));
                if (isSubscribed) {
                    setImageUrl(url)
                }

                return;
            }
        };
        
        setImageUrl(null);
        fetch();

        return () => isSubscribed = false;
    }, [image]);

    const hasTabs = isOfficer || !!items.length || (!!items.length && imageUrl);

    return (
        <Box>
            {isOfficer && (
                <CategoryDialog onSubmit={onUpdate} onDelete={handleDelete} item={row} open={open} handleClose={() => setOpen(false)} />
            )}

            <Paper variant="outlined" sx={{p: 2, position: 'relative', mb: 1}}>
                <Grid spacing={2} container>
                    {imageUrl && (
                        <Grid
                            size={{xs: 3}}
                            sx={{aspectRatio: '4/3', borderRadius: 1, backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center'}}
                            onClick={() => window.open(imageUrl, '_blank')}
                        />
                    )}
                    
                    <Grid sx={{display: 'flex', flexDirection: 'column'}} size={{xs: imageUrl ? 9 : 12}}>
                        <Box>
                            <Typography variant="caption" color="text.secondary">{loading ? <Skeleton /> : 'Name'}</Typography>
                            <Typography variant="h6">{loading ? <Skeleton /> : name}</Typography>
                        </Box>

                        {Icon && (
                            <Box>
                                <Typography variant="caption" color="text.secondary">{loading ? <Skeleton /> : 'Icon'}</Typography>
                                <Typography variant="h6">
                                    {loading ? <Skeleton /> : (
                                        <Icon fontSize="large" sx={{background: grey[50], borderRadius: 4}} />
                                    )}
                                </Typography>
                            </Box>
                        )}

                        {apparatus && (
                            <Box>
                                <Typography variant="caption" color="text.secondary">{loading ? <Skeleton /> : 'Apparatus'}</Typography>
                                <Typography variant="h6">
                                    {loading ? <Skeleton /> : get(apparatus, 'tag')}
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                </Grid>

                {isOfficer && (
                    <IconButton disabled={loading} sx={{position: 'absolute', bottom: 10, right: 10}} onClick={() => setOpen(true)}>
                        <EditIcon />
                    </IconButton>
                )}
            </Paper>

            <CategoriesGrid />

            {!loading && hasTabs && (
                <ContentTabs items={items} imageUrl={imageUrl} />
            )}
        </Box>
    );
};