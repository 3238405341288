import React, {createRef, useState, useEffect} from 'react';
import {Card, CardActionArea, Typography} from '@mui/material';
import {useFormContext} from 'react-hook-form';
import {ref, getDownloadURL} from 'firebase/storage';

import {storage} from '../firebase';

import HiddenFileField from './HiddenFileField';

export default function ImageCard(props) {
    const {name = 'image', sx, ...rest} = props;
    const {watch, setValue} = useFormContext();
    const data = watch(name);
    const file = watch(`${name}File`);
    const imageUploadRef = createRef();
    const {filePath, thumbnailPath} = data || {};
    const [url, setUrl] = useState(null);

    useEffect(() => {
        let isSubscribed = true;

        const fetch = async() => {
            if (thumbnailPath) {
                const url = await getDownloadURL(ref(storage, thumbnailPath));
                if (isSubscribed) {
                    setUrl(url)
                }

                return;
            }

            if (filePath) {
                const url = await getDownloadURL(ref(storage, filePath));
                if (isSubscribed) {
                    setUrl(url)
                }

                return;
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [filePath, thumbnailPath]);

    const handleImageUpload = () => {
        if (!file && !url) {
            imageUploadRef.current.click();
            return;
        }

        setUrl(null);
        setValue(`${name}File`, null);
    };

    const backgroundImageUrl = url || (file && URL.createObjectURL(file));

    return (
        <Card
            variant="outlined"
            {...rest}
            sx={{
                display: 'flex',
                ...backgroundImageUrl && {backgroundImage: `url(${backgroundImageUrl})`},
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                aspectRatio: '4/3',
                ...sx
            }}>
            <CardActionArea
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1
                }}
                onClick={handleImageUpload}
            >
                {!file && !url && (
                    <Typography variant="button">UPLOAD IMAGE</Typography>
                )}
            </CardActionArea>
            <HiddenFileField ref={imageUploadRef} accept="image/*" name={`${name}File`} />
        </Card>
    );
};