const NFPA = {
    220: {
        title: 'Standard on Types of Building Construction',
        editions: {
            2018: {
                4: {
                    title: 'Types of Construction'
                }
            }
        }
    },
    472: {
        title: 'Standard for Competence of Responders to Hazardous Materials/Weapons of Mass Destruction Incidents'
    },
    921: {
        title: 'Guide for Fire and Explosion Investigations',
        editions: {
            2017: {
                5: {
                    title: 'Basic Fire Science'
                }
            }
        }
    },
    1001: {
        title: 'Fire Fighter Professional Qualifications',
        editions: {
            2019: {
                4: {
                    title: 'Fire Fighter I',
                    1: {
                        title: 'General',
                        text: 'For qualification at Level I, the fire fighter candidate shall meet the general knowledge requirements in 4.1.1, the general skill requirements in 4.1.2, the JPRs defined in Sections 4.2 through 4.5 of this standard, knowledge of the incident management system, and the requirements defined in Chapter 5 as well as mission-specific competencies in Section 6.2, Personal Protective Equipment, and Section 6.6, Product Control, of NFPA 1072.',
                        1: {
                            title: 'General Knowledge Requirements',
                            text: 'The organization of the fire department; the role of the Fire Fighter I in the organization; the mission of fire service; the fire department’s standard operating procedures (SOPs) and rules and regulations as they apply to the Fire Fighter I; the value of fire and life safety initiatives in support of the fire department mission and to reduce fire fighter line-of-duty injuries and fatalities; the role of other agencies as they relate to the fire department; the signs and symptoms of behavioral and emotional distress; aspects of the fire department’s member assistance program; the importance of physical fitness and a healthy lifestyle to the performance of the duties of a fire fighter; the critical aspects of NFPA 1500.'
                        },
                        2: {
                            title: 'General Skill Requirements',
                            text: 'The ability to don personal protective clothing, doff personal protective clothing, perform field reduction of contaminants and prepare for reuse, hoist tools and equipment using ropes and the correct knot, and locate information in departmental documents and standard or code materials.'
                        }
                    },
                    2: {
                        title: 'Fire Department Communications',
                        text: 'This duty shall involve initiating responses, receiving telephone calls, and using fire department communications equipment to correctly relay verbal or written information, according to the JPRs in 4.2.1 through 4.2.4.',
                        1: {
                            text: 'Initiate the response to a reported emergency, given the report of an emergency, fire department SOPs, and communications equipment, so that all necessary information is obtained, communications equipment is operated correctly, and the information is relayed promptly and accurately to the dispatch center.',
                            other: [{
                                title: '(A) Requisite Knowledge',
                                text: 'Procedures for reporting an emergency; departmental SOPs for taking and receiving alarms, radio codes, or procedures; and information needs of dispatch center'
                            }, {
                                title: '(B) Requisite Skills',
                                text: 'The ability to operate fire department communications equipment, relay information, and record information.'
                            }]
                        },
                        2: {
                            text: 'Receive a telephone call, given a fire department phone, so that procedures for answering the phone are used and the caller’s information is relayed.',
                            other: [{
                                title: '(A) Requisite Knowledge',
                                text: 'Fire department procedures for answering nonemergency telephone calls.'
                            }, {
                                title: '(B) Requisite Skills',
                                text: 'The ability to operate fire station telephone and intercom equipment'
                            }]
                        },
                        3: {
                            text: 'Transmit and receive messages via the fire department radio, given a fire department radio and operating procedures, so that the information is accurate, complete, clear, and relayed within the time established by the AHJ.',
                            other: [{
                                title: '(A) Requisite Knowledge',
                                text: 'Departmental radio procedures and etiquette for routine traffic, emergency traffic, and emergency evacuation signals.'
                            }, {
                                title: '(B) Requisite Skills',
                                text: 'The ability to operate radio equipment and discriminate between routine and emergency traffic.'
                            }]
                        },
                        4: {
                            text: 'Activate an emergency call for assistance, given vision-obscured conditions, PPE, and department SOPs, so that the fire fighter can be located and rescued.',
                            other: [{
                                title: '(A) Requisite Knowledge',
                                text: 'Personnel accountability systems, emergency communication procedures, and emergency evacuation methods.'
                            }, {
                                title: '(B) Requisite Skills',
                                text: 'The ability to initiate an emergency call for assistance in accordance with the AHJ’s procedures, the ability to use other methods of emergency calls for assistance.'
                            }]
                        }
                    },
                    3: {
                        title: 'Fireground Operations',
                        text: 'This duty shall involve performing activities necessary to ensure life safety, fire control, andproperty conservation, according to the JPRs in 4.3.1 through4.3.21.',
                        1: {
                            text: 'Use self-contained breathing apparatus (SCBA) during emergency operations, given SCBA and other PPE, so that the 4.3.5* SCBA is correctly donned, the SCBA is correctly worn, control‐ led breathing techniques are used, emergency procedures are enacted if the SCBA fails, all low-air warnings are recognized, respiratory protection is not intentionally compromised, and hazardous areas are exited prior to air depletion.',
                            other: [{
                                title: '(A) Requisite Knowledge',
                                text: 'Conditions that require respiratory protection, uses and limitations of SCBA, components of SCBA, donning procedures, breathing techniques, indications for and emergency procedures used with SCBA, and physical requirements of the SCBA wearer.'
                            }, {
                                title: '(B) Requisite Skills',
                                text: 'The ability to control breathing, replace SCBA air cylinders, use SCBA to exit through restricted passages, initiate and complete emergency procedures in the event of SCBA failure or air depletion, and complete donning procedures.'
                            }]
                        },
                        2: {
                            text: 'Respond on apparatus to an emergency scene, given personal protective clothing and other necessary PPE, so that the apparatus is correctly mounted and dismounted, seat belts are used while the vehicle is in motion, and other personal protective equipment is correctly used',
                            other: [{
                                title: '(A) Requisite Knowledge',
                                text: 'Mounting and dismounting procedures for riding fire apparatus, hazards and ways to avoid hazards associated with riding apparatus, prohibited practices, and types of department PPE and the means for usage.'
                            }, {
                                title: '(B) Requisite Skills',
                                text: 'The ability to use each piece of provided safety equipment.'
                            }]
                        },
                        3: {
                            text: 'Establish and operate in work areas at emergency scenes, given protective equipment, traffic and scene control devices, structure fire and roadway emergency scenes, traffic hazards and downed electrical wires, photovoltaic power systems, battery storage systems, an assignment, and SOPs, so that procedures are followed, protective equipment is worn, protected work areas are established as directed using traffic and scene control devices, and the fire fighter performs assigned tasks only in established, protected work areas.',
                            other: [{
                                title: '(A) Requisite Knowledge',
                                text: 'Potential hazards involved in operating on emergency scenes including vehicle traffic, utilities, and environmental conditions; proper procedures for dismounting apparatus in traffic; procedures for safe operation at emergency scenes; and the protective equipment available for members’ safety on emergency scenes and work zone desig‐ nations.'
                            }, {
                                title: '(B) Requisite Skills',
                                text: 'The ability to use personal protective clothing, deploy traffic and scene control devices, dismount apparatus, and operate in the protected work areas as directed.'
                            }]
                        },
                        4: {
                            text: 'Force entry into a structure, given PPE, tools, and an assignment, so that the tools are used as designed, the barrier is removed, and the opening is in a safe condition and ready for entry.',
                            other: [{
                                title: '(A) Requisite Knowledge',
                                text: 'Basic construction of typical doors, windows, and walls within the department’s community or serv‐ ice area; operation of doors, windows, and locks; and the dangers associated with forcing entry through doors, windows, and walls.'
                            }, {
                                title: '(B) Requisite Skills',
                                text: 'The ability to transport and operate hand and power tools and to force entry through doors, windows, and walls using assorted methods and tools.'
                            }]
                        },
                        5: {
                            text: 'Exit a hazardous area as a team, given vision-obscured conditions, so that a safe haven is found before exhausting the air supply, others are not endangered, and the team integrity is maintained.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Personnel accountability systems, communication procedures, emergency evacuation methods, what constitutes a safe haven, elements that create or indicate a hazard, and emergency procedures for loss of air supply.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to operate as a team member in vision-obscured conditions, locate and follow a guideline, conserve air supply, and evaluate areas for hazards and identify a safe haven.'
                                }
                            ]
                        },
                        6: {
                            text: 'Set up, mount, ascend, dismount, and descend ground ladders, given single and extension ladders, an assignment, and team members if needed, so that hazards are assessed, the ladder is stable, the angle is correct for climbing, extension ladders are extended to the necessary height with the fly locked, the top is placed against a reliable structural component, and the assignment is accomplished.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Parts of a ladder, hazards associated with setting up ladders, what constitutes a stable foundation for ladder placement, different angles for various tasks, climbing techniques, safety limits to the degree of angulation, and what constitutes a reliable structural component for top placement.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to carry ladders, raise ladders, extend ladders and lock flies, determine that a wall and roof will support the ladder, judge extension ladder height requirements, and place the ladder to avoid obvious hazards, mount, ascend, dismount, and descend the ladder.'
                                }
                            ]
                        },
                        7: {
                            text: 'Attack a passenger vehicle fire operating as a member of a team, given PPE, an attack line, and hand tools, so that hazards are avoided, leaking flammable liquids are identified and controlled, protection from flash fires is maintained, all vehicle compartments are overhauled, and the fire is extinguished.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Principles of fire streams as they relate to fighting automobile fires; precautions to be followed when advancing hose lines toward an automobile; observable results that a fire stream has been properly applied; identifying alternative fuels and the hazards associated with them; dangerous conditions created during an automobile fire; common types of accidents or injuries related to fighting automobile fires and how to avoid them; how to access locked passenger, trunk, and engine compartments; and methods for overhauling an automobile.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to identify automobile fuel type; assess and control fuel leaks; open, close, and adjust the flow and pattern on nozzles; apply water for maximum effectiveness while maintaining flash fire protection; advance 1½ in. (38 mm) or larger diameter attack lines; and expose hidden fires by opening all automobile compartments.'
                                }
                            ]
                        },
                        8: {
                            text: 'Extinguish fires in exterior Class A materials, given fires in stacked or piled and small unattached structures or storage containers that can be fought from the exterior, attack lines, hand tools and master stream devices, and an assignment, so that exposures are protected, the spread of fire is stopped, collapse hazards are avoided, water application is effective, the fire is extinguished, and signs of the origin area(s) and arson are preserved.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Types of attack lines and water streams appropriate for attacking stacked, piled materials and outdoor fires; dangers — such as collapse — associated with stacked and piled materials; various extinguishing agents and their effect on different material configurations; tools and methods to use in breaking up various types of materials; the difficulties related to complete extinguishment of stacked and piled materials; water application methods for exposure protection and fire extinguishment; dangers such as exposure to toxic or hazardous materials associated with storage building and container fires; obvious signs of origin and cause; and techniques for the preservation of fire cause evidence.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to recognize inherent hazards related to the material’s configuration, operate handlines or master streams, break up material using hand tools and water streams, evaluate for complete extinguishment, operate hose lines and other water application devices, evaluate and modify water application for maximum penetration, search for and expose hidden fires, assess patterns for origin determination, and evaluate for complete extinguishment.'
                                }
                            ]
                        },
                        9: {
                            text: 'Conduct a search and rescue in a structure operating as a member of a team, given an assignment, obscured vision conditions, personal protective equipment, a flashlight, forcible entry tools, hose lines, and ladders when necessary, so that ladders are correctly placed when used, all assigned areas are searched, all victims are located and removed, team integrity is maintained, and team members’ safety — including respiratory protection — is not compromised.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Use of forcible entry tools during rescue operations, ladder operations for rescue, psychological effects of operating in obscured conditions and ways to manage them, methods to determine if an area is tenable, primary and secondary search techniques, team members’ roles and goals, methods to use and indicators of finding victims, victim removal methods (including various carries), and considerations related to respiratory protection.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to use SCBA to exit through restricted passages, set up and use different types of ladders for various types of rescue operations, rescue a fire fighter with functioning respiratory protection, rescue a fire fighter whose respiratory protection is not functioning, rescue a person who has no respiratory protection, and assess areas to determine tenability.'
                                }
                            ]
                        },
                        10: {
                            text: 'Attack an interior structure fire operating as a member of a team, given an attack line, ladders when needed, personal protective equipment, tools, and an assignment, so that team integrity is maintained, the attack line is deployed for advancement, ladders are correctly placed when used, access is gained into the fire area, effective water application practices are used, the fire is approached correctly, attack techniques facilitate suppression given the level of the fire, hidden fires are located and controlled, the correct body posture is maintained, hazards are recognized and managed, and the fire is brought under control.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Principles of fire streams; types, design, operation, nozzle pressure effects, and flow capabilities of nozzles; precautions to be followed when advancing hose lines to a fire; observable results that a fire stream has been properly applied; dangerous building conditions created by fire; principles of exposure protection; potential long-term consequences of exposure to products of combustion; physical states of matter in which fuels are found; common types of accidents or injuries and their causes; and the application of each size and type of attack line, the role of the backup team in fire attack situations, attack and control techniques for grade level and above and below grade levels, and exposing hidden fires.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to prevent water hammers when shutting down nozzles; open, close, and adjust nozzle flow and patterns; apply water using direct, indirect, and combination attacks; advance charged and uncharged 1½ in. (38 mm) diameter or larger hose lines up ladders and up and down interior and exterior stairways; extend hose lines; replace burst hose sections; operate charged hose lines of 1½ in. (38 mm) diameter or larger while secured to a ground ladder; couple and uncouple various handline connections; carry hose; attack fires at grade level and above and below grade levels; and locate and suppress interior wall and subfloor fires.'
                                }
                            ]
                        },
                        11: {
                            text: 'Perform horizontal ventilation on a structure operating as part of a team, given an assignment, PPE, ventilation tools, equipment, and ladders, so that the ventilation openings are free of obstructions, tools are used as designed, ladders are correctly placed, ventilation devices are correctly placed, and the structure is cleared of smoke.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'The principles, advantages, limitations, and effects of horizontal, mechanical, and hydraulic ventilation; safety considerations when venting a structure; fire behavior in a structure; the products of combustion found in a structure fire; the signs, causes, effects, and prevention of backdrafts; and the relationship of oxygen concentration to life safety and fire growth.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to transport and operate ventilation tools and equipment and ladders, and to use safe procedures for breaking window and door glass and removing obstructions.'
                                }
                            ]
                        },
                        12: {
                            text: 'Perform vertical ventilation on a structure as part of a team, given an assignment, PPE, ground and roof ladders, and tools, so that ladders are positioned for ventilation, a specified opening is created, all ventilation barriers are removed, structural integrity is not compromised, products of combustion are released from the structure, and the team retreats from the area when ventilation is accomplished.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'The methods of heat transfer; the principles of thermal layering within a structure on fire; the techniques and safety precautions for venting flat roofs, pitched roofs, and basements; basic indicators of potential collapse or roof failure; the effects of construction type and elapsed time under fire conditions on structural integrity; and the advantages and disadvantages of vertical and trench/strip ventilation.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to transport and operate ventilation tools and equipment; hoist ventilation tools to a roof; cut roofing and flooring materials to vent flat roofs, pitched roofs, and basements; sound a roof for integrity; clear an opening with hand tools; select, carry, deploy, and secure ground ladders for ventilation activities; deploy roof ladders on pitched roofs while secured to a ground ladder; and carry ventilation-related tools and equipment while ascending and descending ladders.'
                                }
                            ]
                        },
                        13: {
                            text: 'Overhaul a fire scene, given PPE, an attack line, hand tools, a flashlight, and an assignment, so that structural integrity is not compromised, all hidden fires are discovered, fire cause evidence is preserved, and the fire is extinguished.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Types of fire attack lines and water application devices most effective for overhaul, water application methods for extinguishment that limit water damage, types of tools and methods used to expose hidden fire, dangers associated with overhaul, obvious signs of area of origin or signs of arson, and reasons for protection of fire scene.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to deploy and operate an attack line; remove flooring, ceiling, and wall components to expose void spaces without compromising structural integrity; apply water for maximum effectiveness; expose and extinguish hidden fires in walls, ceilings, and subfloor spaces; recognize and preserve obvious signs of area of origin and arson; and evaluate for complete extinguishment.'
                                }
                            ]
                        },
                        14: {
                            text: 'Conserve property as a member of a team, given salvage tools and equipment and an assignment, so that the building and its contents are protected from further damage.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'The purpose of property conservation and its value to the public, methods used to protect property, types of and uses for salvage covers, operations at properties protected with automatic sprinklers, how to stop the flow of water from an automatic sprinkler head, identification of the main control valve on an automatic sprinkler system, forcible entry issues related to salvage, and procedures for protecting possible areas of origin and potential evidence.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to cluster furniture; deploy covering materials; roll and fold salvage covers for reuse; construct water chutes and catch-alls; remove water; cover building openings, including doors, windows, floor openings, and roof openings; separate, remove, and relocate charred material to a safe location while protecting the area of origin for cause determination; stop the flow of water from a sprinkler with sprinkler wedges or stoppers; and operate a main control valve on an automatic sprinkler system.'
                                }
                            ]
                        },
                        15: {
                            text: 'Connect a fire department pumper to a water supply as a member of a team, given supply or intake hose, hose tools, and a fire hydrant or static water source, so that connections are tight and water flow is unobstructed.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Loading and off-loading procedures for mobile water supply apparatus; fire hydrant operation; and suitable static water supply sources, procedures, and protocol for connecting to various water sources.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to hand lay a supply hose, connect and place hard suction hose for drafting operations, deploy portable water tanks as well as the equipment necessary to transfer water between and draft from them, make hydrant-to-pumper hose connections for forward and reverse lays, connect supply hose to a hydrant, and fully open and close the hydrant.'
                                }
                            ]
                        },
                        16: {
                            text: 'Extinguish incipient Class A, Class B, and Class C fires, given a selection of portable fire extinguishers, so that the correct extinguisher is chosen, the fire is completely extinguished, and correct extinguisher-handling techniques are followed.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'The classifications of fire; the types of, rating systems for, and risks associated with each class of fire; and the operating methods of and limitations of portable extinguishers.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to operate portable fire extinguishers, approach fire with portable fire extinguishers, select an appropriate extinguisher based on the size and type of fire, and safely carry portable fire extinguishers.'
                                }
                            ]
                        },
                        17: {
                            text: 'Operate emergency scene lighting, given fire service lighting equipment, power supply, and an assignment, so that emergency scene lighting equipment is operated within the manufacturer’s listed safety precautions.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Safety principles and practices, power supply capacity and limitations, and light deployment methods.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to operate department power supply and lighting equipment, deploy cords and connectors, reset ground-fault interrupter (GFI) devices, and locate lights for best effect.'
                                }
                            ]
                        },
                        18: {
                            text: 'Turn off building utilities, given tools and an assignment, so that the assignment is safely completed.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Properties, principles, and safety concerns for electricity, gas, and water systems; utility disconnect methods and associated dangers; and use of required safety equipment.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to identify utility control devices, operate control valves or switches, and assess for related hazards.'
                                }
                            ]
                        },
                        19: {
                            text: 'Combat a ground cover fire operating as a member of a team, given protective clothing, SCBA (if needed), hose lines, extinguishers or hand tools, and an assignment, so that threats to property are reported, threats to personal safety are recognized, retreat is quickly accomplished when warranted, and the assignment is completed.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Types of ground cover fires, parts of ground cover fires, methods to contain or suppress, and safety principles and practices.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to determine exposure threats based on fire spread potential, protect exposures, construct a fire line or extinguish with hand tools, maintain integrity of established fire lines, and suppress ground cover fires using water.'
                                }
                            ]
                        },
                        20: {
                            text: 'Tie a knot appropriate for hoisting tools, given PPE, tools, ropes, and an assignment, so that the knots used are appropriate for hoisting tools securely and as directed.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Knot types and usage; the difference between life safety and utility rope; reasons for placing rope out of service; the types of knots to use for given tools, ropes, or situations; hoisting methods for tools and equipment; and using rope to support response activities.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to hoist tools using specific knots based on the type of tool.'
                                }
                            ]
                        },
                        21: {
                            text: 'Operate an air-monitoring instrument, given an air monitor and an assignment or task, so that the device is operated and the fire fighter recognizes the high- or low-level alarms of the air monitor and takes action to mitigate the hazard.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Knowledge of the various uses for an air monitor, the basic operation of an air monitor, and recognition and emergency actions to be taken upon the activation of the high- or low-level alarms of the air monitor.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to operate the air monitor, recognize the alarms, and react to the alarms of the air monitor.'
                                }
                            ]
                        }
                    },
                    4: {
                        title: 'Rescue Operations',
                        text: 'This duty shall involve no requirements for Fire Fighter I.'
                    },
                    5: {
                        title: 'Preparedness and Maintenance',
                        text: 'This duty shall involve performing activities that reduce the loss of life and property due to fire through response readiness, according to the JPRs in 4.5.1 and 4.5.2.',
                        1: {
                            text: 'Clean and check ladders, ventilation equipment, SCBA, ropes, salvage equipment, and hand tools, given cleaning tools, cleaning supplies, and an assignment, so that equipment is clean and maintained according to manufacturer’s or departmental guidelines, maintenance is recorded, and equipment is placed in a ready state or reported otherwise.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Types of cleaning methods for various tools and equipment, correct use of cleaning solvents, and manufacturer’s or departmental guidelines for cleaning equipment and tools.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to select correct tools for various parts and pieces of equipment, follow guidelines, and complete recording and reporting procedures.'
                                }
                            ]
                        },
                        2: {
                            text: 'Clean, inspect, and return fire hose to service, given washing equipment, water, detergent, tools, and replacement gaskets, so that damage is noted and corrected, the hose is clean, and the equipment is placed in a ready state for service.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Departmental procedures for noting a defective hose and removing it from service, cleaning methods, and hose rolls and loads.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to clean different types of hose; operate hose washing and drying equipment; mark defective hose; and replace coupling gaskets, roll hose, and reload hose.'
                                }
                            ]
                        }
                    }
                },
                5: {
                    title: 'Fire Fighter II',
                    1: {
                        title: 'General',
                        text: 'For qualification at Level II, the Fire Fighter II shall meet the general knowledge requirements in 5.1.1, the general skill requirements in 5.1.2, the JPRs defined in Sections 5.2 through 5.5, the requirements defined in Chapter 4, and knowledge of the Incident Management System sections of ICS 200 as described.',
                        1: {
                            title: 'General Knowledge Requirements',
                            text: 'Responsibilities of the Fire Fighter II in assuming and transferring command within an incident management system, performing assigned duties in conformance with applicable NFPA and other safety regulations and AHJ procedures, and the role of a Fire Fighter II within the organization.'
                        },
                        2: {
                            title: 'General Skill Requirements',
                            text: 'The ability to determine the need for command, organize and coordinate an incident management system until command is transferred, and function within an assigned role in an incident management system.'
                        }
                    },
                    2: {
                        title: 'Fire Department Communications',
                        text: 'This duty shall involve performing activities related to initiating and reporting responses, according to the JPRs in 5.2.1 and 5.2.2.',
                        1: {
                            text: 'Complete a basic incident report, given the report forms, guidelines, and information, so that all pertinent information is recorded, the information is accurate, and the report is complete.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Content requirements for basic incident reports, the purpose and usefulness of accurate reports, consequences of inaccurate reports, how to obtain necessary information, and required coding procedures.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to determine necessary codes, proof reports, and operate fire department computers or other equipment necessary to complete reports.'
                                }
                            ]
                        },
                        2: {
                            text: 'Communicate the need for team assistance, given fire department communications equipment, SOPs, and a team, so that the supervisor is consistently informed of team needs, departmental SOPs are followed, and the assignment is accomplished safely.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'SOPs for alarm assignments and fire department radio communication procedures.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to operate fire department communications equipment.'
                                }
                            ]
                        }
                    },
                    3: {
                        title: 'Fireground Operations',
                        text: 'This duty shall involve performing activities necessary to ensure life safety, fire control, and property conservation, according to the JPRs in 5.3.1 through 5.3.4.',
                        1: {
                            text: 'Extinguish an ignitable liquid fire, operating as a member of a team, given an assignment, an attack line, PPE, a foam proportioning device, a nozzle, foam concentrates, and a water supply, so that the correct type of foam concentrate is selected for the given fuel and conditions, a properly proportioned foam stream is applied to the surface of the fuel to create and maintain a foam blanket, fire is extinguished, reignition is prevented, team protection is maintained with a foam stream, and the hazard is faced until retreat to safe haven is reached.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Methods by which foam prevents or controls a hazard; principles by which foam is generated; causes for poor foam generation and corrective measures; difference between hydrocarbon and polar solvent fuels and the concentrates that work on each; the characteristics, uses, and limitations of fire-fighting foams; the advantages and disadvantages of using fog nozzles versus foam nozzles for foam application; foam stream application techniques; hazards associated with foam usage; and methods to reduce or avoid hazards.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to prepare a foam concentrate supply for use, assemble foam stream components, master various foam application techniques, and approach and retreat from spills as part of a coordinated team.'
                                }
                            ]
                        },
                        2: {
                            text: 'Coordinate an interior attack line for a team’s accomplishment of an assignment in a structure fire, given attack lines, personnel, PPE, and tools, so that crew integrity is established; attack techniques are selected for the given level of the fire (e.g., attic, grade level, upper levels, or basement); attack techniques are communicated to the attack teams; constant team coordination is maintained; fire growth and development is continuously evaluated; search, rescue, and ventilation requirements are communicated or managed; hazards are recognized and acted upon; and incident command is apprised of changing conditions.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Selection of the nozzle and hose for fire attack, given different fire situations; selection of adapters and appliances to be used for specific fireground situations; dangerous building conditions created by fire and fire suppression activities; indicators of building collapse; the effects of fire and fire suppression activities on wood, masonry (brick, block, stone), cast iron, steel, reinforced concrete, gypsum wallboard, glass, and plaster on lath; search and rescue and ventilation procedures; indicators of structural instability; suppression approaches and practices for various types of structural fires; and the association between specific tools and special forcible entry needs.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to assemble a team, choose attack techniques for various levels of a fire (e.g., attic, grade level, upper levels, or basement), evaluate and forecast a fire’s growth and development, select tools for forcible entry, incorporate search and rescue procedures and ventilation procedures in the completion of the attack team efforts, and determine developing hazardous building or fire conditions.'
                                }
                            ]
                        },
                        3: {
                            text: 'Control a flammable gas cylinder fire, operating as a member of a team, given an assignment, a cylinder outside of a structure, an attack line, PPE, and tools, so that crew integrity is maintained, contents are identified, safe havens are identified prior to advancing, open valves are closed, flames are not extinguished unless the leaking gas is eliminated, the cylinder is cooled, cylinder integrity is evaluated, hazardous conditions are recognized and acted upon, and the cylinder is faced during approach and retreat.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Characteristics of pressurized flammable gases, elements of a gas cylinder, effects of heat and pressure on closed cylinders, boiling liquid expanding vapor explosion (BLEVE) signs and effects, methods for identifying contents, how to identify safe havens before approaching flammable gas cylinder fires, water stream usage and demands for pressurized cylinder fires, what to do if the fire is prematurely extinguished, valve types and their operation, alternative actions related to various hazards, and when to retreat.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to execute effective advances and retreats, apply various techniques for water application, assess cylinder integrity and changing cylinder conditions, operate control valves, and choose effective procedures when conditions change.'
                                }
                            ]
                        },
                        4: {
                            text: 'Protect evidence of fire cause and origin, given a flashlight and overhaul tools, so that the evidence is noted and protected from further disturbance until investigators can arrive on the scene.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Methods to assess origin and cause; types of evidence; means to protect various types of evidence; the role and relationship of Fire Fighter IIs, criminal investigators, and insurance investigators in fire investigations; and the effects and problems associated with removing property or evidence from the scene.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to locate the fire’s origin area, recognize possible causes, and protect the evidence.'
                                }
                            ]
                        }
                    },
                    4: {
                        title: 'Rescue Operations',
                        text: 'This duty shall involve performing activities related to accessing and disentangling victims from motor vehicle accidents and helping special rescue teams, according to the JPRs in 5.4.1 and 5.4.2.',
                        1: {
                            text: 'Extricate a victim entrapped in a motor vehicle as part of a team, given stabilization and extrication tools, so that the vehicle is stabilized, the victim is disentangled without further injury, and hazards are managed.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'The fire department’s role at a vehicle accident, points of strength and weakness in auto body construction, dangers associated with vehicle components and systems, the uses and limitations of hand and power extrication equipment, and safety procedures when using various types of extrication equipment.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to operate hand and power tools used for forcible entry and rescue as designed; use cribbing and shoring material; and choose and apply appropriate techniques for moving or removing vehicle roofs, doors, windshields, windows, steering wheels or columns, and the dashboard.'
                                }
                            ]
                        },
                        2: {
                            text: 'Assist rescue operation teams, given standard operating procedures, necessary rescue equipment, and an assignment, so that procedures are followed, rescue items are recognized and retrieved in the time as prescribed by the AHJ, and the assignment is completed.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'The fire fighter’s role at a technical rescue operation, the hazards associated with technical rescue operations, types and uses for rescue tools, and rescue practices and goals.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to identify and retrieve various types of rescue tools, establish public barriers, and assist rescue teams as a member of the team when assigned.'
                                }
                            ]
                        }
                    },
                    5: {
                        title: 'Fire and Life Safety Initiatives, Preparedness, and Maintenance',
                        text: 'This duty shall involve performing activities related to reducing the loss of life and property due to fire through hazard identification, inspection, and response readiness, according to the JPRs in 5.5.1 through 5.5.5.',
                        1: {
                            text: 'Perform a fire safety survey in an occupied structure, given survey forms and procedures, so that fire and life safety hazards are identified, recommendations for their correction are made to the occupant, and unresolved issues are referred to the proper authority.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Organizational policy and procedures, common causes of fire and their prevention, the importance of a fire safety survey and public fire education programs to fire department public relations and the community, and referral procedures.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to complete forms, recognize hazards, match findings to preapproved recommendations, and effectively communicate findings to occupants or referrals.'
                                }
                            ]
                        },
                        2: {
                            text: 'Present fire safety information to station visitors or small groups, given prepared materials, so that all information is presented, the information is accurate, and questions are answered or referred.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Parts of informational materials and how to use them, basic presentation skills, and departmental standard operating procedures for giving fire station tours.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to document presentations and to use prepared materials.'
                                }
                            ]
                        },
                        3: {
                            text: 'Prepare a preincident survey, given forms, necessary tools, and an assignment, so that all required occupancy information is recorded, items of concern are noted, and accurate sketches or diagrams are prepared.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'The sources of water supply for fire protection; the fundamentals of fire suppression and detection systems; common symbols used in diagramming construction features, utilities, hazards, and fire protection systems; departmental requirements for a preincident survey and form completion; and the importance of accurate diagrams.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to identify the components of fire suppression and detection systems; sketch the site, buildings, and special features; detect hazards and special considerations to include in the preincident sketch; and complete all related departmental forms.'
                                }
                            ]
                        },
                        4: {
                            text: 'Maintain power plants, power tools, and lighting equipment, given tools and manufacturers’ instructions, so that equipment is clean and maintained according to manufacturer and departmental guidelines, maintenance is recorded, and equipment is placed in a ready state or reported otherwise.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Types of cleaning methods, correct use of cleaning solvents, manufacturer and departmental guidelines for maintaining equipment and its documentation, and problem-reporting practices.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to select correct tools; follow guidelines; complete recording and reporting procedures; and operate power plants, power tools, and lighting equipment.'
                                }
                            ]
                        },
                        5: {
                            text: 'Perform an annual service test on fire hose, given a pump, a marking device, pressure gauges, a timer, record sheets, and related equipment, so that procedures are followed, the condition of the hose is evaluated, any damaged hose is removed from service, and the results are recorded.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Procedures for safely conducting hose service testing, indicators that dictate any hose be removed from service, and recording procedures for hose test results.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to operate hose testing equipment and nozzles and to record results.'
                                }
                            ]
                        }
                    }
                }
            }
        }
    },
    1002: {
        title: 'Standard for Fire Apparatus Driver/Operator Professional Qualifications',
        editions: {
            2017: {
                4: {
                    title: 'General Requirements',
                    1: {
                        title: 'General',
                        text: 'Prior to operating fire department vehicles, the fire apparatus driver/operator shall meet the job performance requirements defined in Sections 4.2 and 4.3.'
                    },
                    2: {
                        title: 'Preventive Maintenance',
                        1: {
                            text: 'Perform visual and operational checks on the systems and components specified in the following list, given a fire department vehicle, its manufacturer’s specifications, and policies and procedures of the jurisdiction, so that the operational status of the vehicle is verified:\n(1) Battery(ies)\n(2) Braking system\n(3) Coolant system\n(4) Electrical system\n(5) Fuel\n(6) Hydraulic fluids\n(7) Oil\n(8) Tires\n(9) Steering system\n(10) Belts\n(11) Tools, appliances, and equipment\n(12) Built-in safety features',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Manufacturer specifications and requirements, policies, and procedures of the jurisdiction.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to use hand tools, recognize system problems, and correct any deficiency noted according to policies and procedures.'
                                }
                            ]
                        },
                        2: {
                            text: 'Document the visual and operational checks, given maintenance and inspection forms, so that all items are checked for operation and deficiencies are reported.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Departmental requirements for documenting maintenance performed and the importance of keeping accurate records.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to use tools and equipment and complete all related departmental forms.'
                                }
                            ]
                        }
                    },
                    3: {
                        title: 'Driving/Operating',
                        1: {
                            text: 'Operate a fire apparatus, given a vehicle and a predetermined route on a public way that incorporates the maneuvers and features that the driver/operator is expected to encounter during normal operations, so that the vehicle is operated in compliance with all applicable state and local laws and departmental rules and regulations.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'The importance of donning passenger restraint devices and ensuring crew safety; the common causes of fire apparatus accidents and the recognition that drivers of fire apparatus are responsible for the safe and prudent operation of the vehicle under all conditions.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to operate passenger restraint devices; maintain safe following distances; maintain control of the vehicle while accelerating, decelerating, and turning, given road, weather, and traffic conditions.'
                                }
                            ]
                        },
                        2: {
                            text: 'Back a vehicle from a roadway into restricted spaces on both the right and left sides of the vehicle, given a fire apparatus; a spotter where the spotter assists the driver in performing the maneuver; and restricted spaces 12 ft (3.7 m) in width, requiring 90-degree right-hand and left-hand turns from the roadway, so that the vehicle is parked within the restricted areas without having to stop and pull forward and without striking obstructions.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Vehicle dimensions, turning characteristics, spotter signaling, and principles of safe vehicle operation.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to use mirrors and judge vehicle clearance.'
                                }
                            ]
                        },
                        3: {
                            text: 'Maneuver a vehicle around obstructions on a roadway while moving forward and in reverse, given a fire apparatus; a spotter where the spotter assists the driver in performing the maneuver; and a roadway with obstructions, so that the vehicle is maneuvered through the obstructions without stopping to change the direction of travel and without striking the obstructions.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Vehicle dimensions, turning characteristics, the effects of liquid surge, spotter signaling, and principles of safe vehicle operation.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to use mirrors and judge vehicle clearance.'
                                }
                            ]
                        },
                        4: {
                            text: 'Turn a fire apparatus 180 degrees within a confined space, given a fire apparatus, a spotter for backing up, and an area in which the vehicle cannot perform a U-turn without stopping and backing up, so that the vehicle is turned 180 degrees without striking obstructions within the given space.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Vehicle dimensions, turning characteristics, the effects of liquid surge, spotter signaling, and principles of safe vehicle operation.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to use mirrors and judge vehicle clearance.'
                                }
                            ]
                        },
                        5: {
                            text: 'Maneuver a fire apparatus in areas with restricted horizontal and vertical clearances, given a fire apparatus and a course that requires the operator to move through areas of restricted horizontal and vertical clearances, so that the operator judges the ability of the vehicle to pass through the openings and so that no obstructions are struck.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Vehicle dimensions, turning characteristics, the effects of liquid surge, spotter signaling, and principles of safe vehicle operation.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to use mirrors and judge vehicle clearance.'
                                }
                            ]
                        }
                    }
                },
                5: {
                    title: 'Apparatus Equipped with Fire Pump',
                    1: {
                        title: 'General',
                        text: 'The job performance requirements defined in Sections 5.1 and 5.2 shall be met prior to qualifying as a fire department driver/operator — pumper.',
                        1: {
                            title: 'General Knowledge Requirements',
                            text: 'The organization of the fire department; the role of the driver/operator in the organization; the mission of fire service; the fire department’s standard operating procedures (SOPs) and rules and regulations as they apply to the driver/operator; the value of fire and life safety initiatives in support of the fire department mission and to reduce fire fighter line-of-duty injuries and fatalities; the role of other agencies as they relate to the fire department; the importance of physical fitness and a healthy lifestyle to the performance of the duties of a fire fighter; the critical aspects of NFPA 1500.'
                        },
                        2: {
                            title: 'Perform Visual and Operational Checks',
                            text: 'Perform the visual and operational checks on the systems and components specified in the following list in addition to those in 4.2.1, given a fire department pumper, its manufacturer’s specifications, and policies and procedures of the jurisdiction, so that the operational status of the pumper is verified:\n(1) Water tank and other extinguishing agent levels (if applicable)\n(2) Pumping systems\n(3) Foam systems',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Manufacturer\'s specifications and requirements, and policies and procedures of the jurisdiction.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to use hand tools, recognize system problems, and correct any deficiency noted according to policies and procedures.'
                                }
                            ]
                        }
                    },
                    2: {
                        title: 'Operations',
                        1: {
                            text: 'Respond on apparatus to an emergency scene, given safety equipment as provided by the AHJ, so that the apparatus is correctly mounted and dismounted and seat belts are used while the vehicle is in motion.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Mounting and dismounting procedures for riding fire apparatus, hazards and ways to avoid hazards associated with riding apparatus, prohibited practices.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to use each piece of provided safety equipment.'
                                }
                            ]
                        },
                        2: {
                            text: 'Establish and operate in work areas at emergency and nonemergency scenes, given safety equipment, traffic and scene control devices, emergency and nonemergency scenes, traffic and other hazards, an assignment, and SOPs, so that procedures are followed, safety equipment is utilized, protected work areas are established as directed using traffic and scene control devices, and the driver/operator performs assigned tasks only in established, protected work areas.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Potential hazards involved in operating on emergency and nonemergency scenes including vehicle traffic, utilities, and environmental conditions; proper procedures for dismounting apparatus in traffic; procedures for safe operation at emergency and nonemergency scenes; and the safety equipment available for members on emergency and nonemergency scenes.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to use safety equipment, deploy traffic and scene control devices, dismount apparatus, establish and operate in the protected work areas as directed.'
                                }
                            ]
                        },
                        3: {
                            text: 'Connect a fire department pumper to a water supply as a member of a team, given supply or intake hose, hose tools, and a fire hydrant or static water source, so that connections are tight and water flow is unobstructed.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Loading and off-loading procedures for mobile water supply apparatus; fire hydrant operation; and suitable static water supply sources, procedures, and protocol for connecting to various water sources.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to hand lay a supply hose, connect and place hard suction hose for drafting operations, deploy portable water tanks as well as the equipment necessary to transfer water between and draft from them, make hydrant-to-pumper hose connections for forward and reverse lays, connect supply hose to a hydrant, and fully open and close the hydrant.'
                                }
                            ]
                        },
                        4: {
                            text: 'Produce effective hand or master streams, given the sources specified in the following list, so that the pump is engaged, all pressure control and vehicle safety devices are set, the rated flow of the nozzle is achieved and maintained, and the apparatus is monitored for potential problems:\n(1) Internal tank\n(2) Pressurized source\n(3) Static source\n(4) Transfer from internal tank to external source',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Hydraulic calculations for friction loss and flow using both written formulas and estimation methods, safe operation of the pump, problems related to small-diameter or dead-end mains, low-pressure and private water supply systems, hydrant coding systems, and reliability of static sources.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to position a fire department pumper to operate at a fire hydrant and at a static water source, power transfer from vehicle engine to pump, draft, operate pumper pressure control systems, operate the volume/pressure transfer valve (multistage pumps only), operate auxiliary cooling systems, make the transition between internal and external water sources, and assemble hose lines, nozzles, valves, and appliances.'
                                }
                            ]
                        },
                        5: {
                            text: 'Pump a supply line of 2½ in. (65 mm) or larger, given a relay pumping evolution the length and size of the line and the desired flow and intake pressure, so that the correct pressure and flow are provided to the next pumper in the relay.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Hydraulic calculations for friction loss and flow using both written formulas and estimation methods, safe operation of the pump, problems related to small-diameter or dead-end mains, low-pressure and private water supply systems, hydrant coding systems, and reliability of static sources.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to position a fire department pumper to operate at a fire hydrant and at a static water source, power transfer from vehicle engine to pump, draft, operate pumper pressure control systems, operate the volume/pressure transfer valve (multistage pumps only), operate auxiliary cooling systems, make the transition between internal and external water sources, and assemble hose lines, nozzles, valves, and appliances.'
                                }
                            ]
                        },
                        6: {
                            text: 'Produce a foam fire stream, given foam-producing equipment, so that proportioned foam is provided.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Proportioning rates and concentrations, equipment assembly procedures, foam system limitations, and manufacturer\'s specifications.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to operate foam proportioning equipment and connect foam stream equipment.'
                                }
                            ]
                        }
                    }
                },
                10: {
                    title: 'Mobile Water Supply Apparatus',
                    1: {
                        title: 'General',
                        text: 'The job performance requirements defined in Sections 10.1 and 10.2 shall be met prior to qualifying as a fire department driver/operator — mobile water supply apparatus.',
                        1: {
                            text: 'Perform visual and operational checks on the systems and components specified in the following list, in addition to those specified in 4.2.1, given a fire department mobile water supply apparatus, and policies and procedures of the jurisdiction, so that the operational readiness of the mobile water supply apparatus is verified:\n(1) Water tank and other extinguishing agent levels (if applicable)\n(2) Pumping system (if applicable)\n(3) Rapid dump system (if applicable)\n(4) Foam system (if applicable)',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Manufacturer’s specifications and requirements, and policies and procedures of the jurisdiction.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to use hand tools, recognize system problems, and correct any deficiency noted according to policies and procedures.'
                                }
                            ]
                        }
                    },
                    2: {
                        title: 'Operations',
                        1: {
                            text: 'Maneuver and position a mobile water supply apparatus at a water shuttle fill site, given a fill site location and one or more supply hose, so that the apparatus is positioned, supply hose are attached to the intake connections without having to stretch additional hose, and no objects are struck at the fill site.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Local procedures for establishing a water shuttle fill site, method for marking the stopping position of the apparatus, and location of the water tank intakes on the apparatus.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to determine a correct position for the apparatus, maneuver apparatus into that position, and avoid obstacles to operations.'
                                }
                            ]
                        },
                        2: {
                            text: 'Maneuver and position a mobile water supply apparatus at a water shuttle dump site, given a dump site and a portable water tank, so that all of the water being discharged from the apparatus enters the portable tank and no objects are struck at the dump site.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Local procedures for operating a water shuttle dump site and location of the water tank discharges on the apparatus.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to determine a correct position for the apparatus, maneuver apparatus into that position, avoid obstacles to operations, and operate the fire pump or rapid water dump system.'
                                }
                            ]
                        },
                        3: {
                            text: 'Establish a water-shuttle dump site, given two or more portable water tanks, low-level strainers, water transfer equipment, fire hose, and a fire apparatus equipped with a fire pump, so that the tank being drafted from is kept full at all times, the tank being dumped into is emptied first, and the water is transferred from one tank to the next.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Local procedures for establishing a water shuttle dump site and principles of water transfer between multiple portable water tanks.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to deploy portable water tanks, connect and operate water transfer equipment, and connect a strainer and suction hose to the fire pump.'
                                }
                            ]
                        }
                    }
                }
            }
        }
    },
    1006: {
        title: 'Standard for Technical Rescue Personnel Professional Qualifications',
        editions: {
            2021: {
                8: {
                    title: 'Common Passenger Vehicle Rescue',
                    1: {
                        title: 'Awareness Level',
                        text: 'Prior to qualification at the awareness level in common passenger vehicle rescue, the individual shall meet the requirements defined in Section 8.1.',
                        1: {
                            text: 'Establish scene safety zones, given a common passenger vehicle incident, scene security barriers, incident location, incident information, and personal protective equipment (PPE), so that the scene and responders are visible to approaching common passenger vehicles, safety zones are designated, zone perimeters are consistent with incident requirements, perimeter markings can be recognized and understood by others, zone boundaries are communicated to incident command, and traffic flow is controlled.',
                            other: [
                                {
                                    title: 'Requisite Knowledge',
                                    text: 'Use and selection of PPE, zone or area control flow and concepts, types of control devices and tools, types of existing and potential hazards, methods of hazard mitigation, organizational standard operating procedure, and staffing requirements.'
                                },
                                {
                                    title: 'Requisite Skills',
                                    text: 'The ability to select and use PPE, apply crowd control concepts, position zone control devices, identify and mitigate existing or potential hazards, and maintain personal safety techniques.'
                                }
                            ]
                        },
                        2: {
                            text: 'Size up an incident, given an incident, background information and applicable reference materials, so that the operational mode is defined, resource availability and response time, types of rescues are determined, the number of victims are identified, the last reported location of all victims are established, witnesses and reporting parties are identified and interviewed, resource needs are assessed, search parameters are identified, and information required to develop an incident action plan is obtained.',
                            other: [
                                {
                                    title: 'Requisite Knowledge',
                                    text: 'Types of reference materials and their uses, risk benefit assessment, availability and capability of the resources, elements of an action plan and related information, relationship of size-up to the incident management system, and information gathering techniques and how that information is used in the size-up process.'
                                },
                                {
                                    title: 'Requisite Skills',
                                    text: 'The ability to read specific rescue reference materials, interview and gather information, relay information, manage witnesses, and use information sources.'
                                }
                            ]
                        },
                        3: {
                            text: 'Recognize incident hazards and initiate isolation procedures, given scene control barriers, personal protective equipment (PPE), requisite equipment, and available specialized resources, so that all hazards are identified; resource application fits the operational requirements; hazard isolation is considered; risks to rescuers, bystanders, and victims are minimized; and rescue time constraints are taken into account.',
                            other: [
                                {
                                    title: 'Requisite Knowledge',
                                    text: 'Resource capabilities and limitations; types and nature of incident hazards; equipment types and their uses; isolation terminology, methods, equipment, and implementation; operational requirement concerns; common types of rescuer and victim risks; risk/benefit analysis methods and practices; hazard recognition, isolation methods, and terminology; methods for controlling access to the scene; and types of technical references.'
                                },
                                {
                                    title: 'Requisite Skills',
                                    text: 'The ability to identify resource capabilities and limitations, identify incident hazards, assess potential hazards to rescuers and bystanders, place scene control barriers, and operate control and mitigation equipment.'
                                }
                            ]
                        }
                    },
                    2: {
                        title: 'Operations Level',
                        text: 'The job performance requirements defined in Sections 8.1 and 8.2 shall be met prior to or during operations-level qualification in common passenger vehicle rescue.',
                        1: {
                            text: 'Create an incident action plan for a common passenger vehicle incident, given agency guidelines, planning forms, and an operations-level common passenger vehicle incident or simulation, so that a standard approach is used during training and operational scenarios, emergency situation hazards are identified, isolation methods and scene security measures are considered, fire suppression and safety measures are identified, common passenger vehicle stabilization needs are evaluated, and resource needs are identified and documented for future use.',
                            other: [
                                {
                                    title: 'Requisite Knowledge',
                                    text: 'Operational protocols, specific planning forms, types of common passenger vehicles within the AHJ boundaries, common passenger vehicle hazards, incident support operations and resources, common passenger vehicle anatomy, and fire suppression and safety measures.'
                                },
                                {
                                    title: 'Requisite Skills',
                                    text: 'The ability to apply operational protocols, select specific planning forms based on the position of the common passenger vehicle, identify and evaluate various types of common passenger vehicles within the AHJ boundaries, request support and resources, and determine the required fire suppression and safety measures.'
                                }
                            ]
                        },
                        2: {
                            text: 'Stabilize a common passenger vehicle that has come to rest on its wheels, given a common passenger vehicle tool kit and PPE, so that the common passenger vehicle is prevented from moving during the rescue operations; entry, exit, and tool placement points are not compromised; anticipated rescue activities will not compromise common passenger vehicle stability; selected stabilization points are structurally sound; stabilization equipment can be monitored; and the risk to rescuers is minimized.',
                            other: [
                                {
                                    title: 'Requisite Knowledge',
                                    text: 'Types and rated capacities of stabilization devices, mechanism of vehicle movement, types of stabilization points, types of stabilization surfaces, AHJ policies and procedures, and types of vehicle construction components as they apply to stabilization.'
                                },
                                {
                                    title: 'Requisite Skills',
                                    text: 'The ability to select, operate, and monitor stabilization devices.'
                                }
                            ]
                        },
                        3: {
                            text: 'Disentangle victim(s), given an extrication incident, a vehicle tool kit, PPE, and specialized equipment, so that undue victim injury is prevented, victim protection is provided, and stabilization is maintained.',
                            other: [
                                {
                                    title: 'Requisite Knowledge',
                                    text: 'Tool selection and application, stabilization systems, protection methods, disentanglement points and techniques, and dynamics of disentanglement.'
                                },
                                {
                                    title: 'Requisite Skills',
                                    text: 'The ability to operate disentanglement tools, initiate protective measures, identify and eliminate points of entrapment, and maintain incident stability and scene safety.'
                                }
                            ]
                        }
                    },
                    3: {
                        title: 'Technician Level',
                        text: 'The job performance requirements defined in Sections 8.2 and 8.3 shall be met prior to or during technician-level qualification in common passenger vehicle rescue.',
                        1: {
                            text: 'Create an incident action plan for an incident where a common passenger vehicle has come to rest on its roof, given agency guidelines, planning forms, and a technician-level vehicle incident or simulation, so that a standard approach is used during training and operational scenarios, emergency situation hazards are identified, isolation methods and scene security measures are considered, fire suppression and safety measures are identified, and resource needs are identified and documented for future use.',
                            other: [
                                {
                                    title: 'Requisite Knowledge',
                                    text: 'Operational protocols, specific planning forms, types of common passenger vehicles within the AHJ boundaries, vehicle hazards, incident support operations and resources, vehicle anatomy, and fire suppression and safety measures.'
                                },
                                {
                                    title: 'Requisite Skills',
                                    text: 'The ability to apply operational protocols, select specific planning forms based on the position of the common passenger vehicle, identify and evaluate various types of vehicles within the AHJ boundaries, request support and resources, and determine the required fire suppression and safety measures.'
                                }
                            ]
                        },
                        2: {
                            text: 'Stabilize a common passenger vehicle that has come to rest on its roof, given a technician-level common passenger vehicle tool kit and PPE, so that the vehicle is prevented from moving during the rescue operations; entry, exit, and tool placement points are not compromised; anticipated rescue activities will not compromise vehicle stability; selected stabilization points are structurally sound; stabilization equipment can be monitored; and the risk to rescuers is minimized.',
                            other: [
                                {
                                    title: 'Requisite Knowledge',
                                    text: 'Types of stabilization devices, mechanism of vehicle movement, types of stabilization points, types of stabilization surfaces, AHJ policies and procedures, and types of vehicle construction components as they apply to stabilization.'
                                },
                                {
                                    title: 'Requisite Skills',
                                    text: 'The ability to select, operate, and monitor stabilization devices.'
                                }
                            ]
                        },
                        3: {
                            text: 'Create access and egress openings for rescue from a common passenger vehicle that has come to rest on its roof, given a technician-level common passenger vehicle tool kit, specialized tools and equipment, PPE, and an assignment, so that the movement of rescuers and equipment complements victim care and removal, an emergency escape route is provided, the technique chosen is expedient, victim and rescuer protection is afforded, and common passenger vehicle stability is maintained.',
                            other: [
                                {
                                    title: 'Requisite Knowledge',
                                    text: 'Common passenger vehicle construction and features; electrical, mechanical, hydraulic, and pneumatic systems; alternative access and egress equipment; points and routes of ingress and egress; techniques and hazards; agency policies and procedures; and emergency evacuation and safety signals.'
                                },
                                {
                                    title: 'Requisite Skills',
                                    text: 'The ability to identify common passenger vehicle construction features, select and operate tools and equipment, apply tactics and strategy based on assignment, apply victim care and stabilization devices, perform hazard control based on techniques selected, and demonstrate safety procedures and emergency evacuation signals.'
                                }
                            ]
                        },
                        4: {
                            text: 'Stabilize a common passenger vehicle that has come to rest on its side, given a technician-level common passenger vehicle tool kit and PPE, so that the vehicle is prevented from moving during the rescue operations; entry, exit, and tool placement points are not compromised; anticipated rescue activities will not compromise vehicle stability; selected stabilization points are structurally sound; stabilization equipment can be monitored; and the risk to rescuers is minimized.',
                            other: [
                                {
                                    title: 'Requisite Knowledge',
                                    text: 'Types and rated capacities of stabilization devices, mechanism of vehicle movement, types of stabilization points, types of stabilization surfaces, AHJ policies and procedures, and types of vehicle construction components as they apply to stabilization.'
                                },
                                {
                                    title: 'Requisite Skills',
                                    text: 'The ability to select, operate, and monitor stabilization devices.'
                                }
                            ]
                        },
                        5: {
                            text: 'Disentangle victim(s), given a technician-level extrication incident, a vehicle tool kit, PPE, and specialized equipment, so that undue victim injury is prevented, victim protection is provided, and stabilization is maintained.',
                            other: [
                                {
                                    title: 'Requisite Knowledge',
                                    text: 'Tool selection and application, stabilization systems, protection methods, disentanglement points and techniques, and dynamics of disentanglement.'
                                },
                                {
                                    title: 'Requisite Skills',
                                    text: 'The ability to operate disentanglement tools, initiate protective measures, identify and eliminate points of entrapment, and maintain incident stability and scene safety.'
                                }
                            ]
                        }
                    }
                }
            }
        }
    },
    1021: {
        title: 'Standard for Fire Officer Professional Qualifications',
        editions: {
            2020: {
                4: {
                    title: 'Fire Officer I',
                    1: {
                        title: 'General',
                        text: 'For qualification at Fire Officer Level I, the candidate shall meet the requirements of Fire Fighter II as defined in NFPA 1001, Fire Instructor I as defined in NFPA 1041, and the job performance requirements defined in Sections 4.2 through 4.7 of this standard.',
                        1: {
                            title: 'General Prerequisite Knowledge',
                            text: 'The organizational structure of the department; geographical configuration and characteristics of response districts; departmental operating procedures for administration, emergency operations, incident management system and safety; fundamentals of leadership; departmental budget process; information management and recordkeeping; the fire prevention and building safety codes and ordinances applicable to the jurisdiction; current trends, technologies, and socioeconomic and political factors that affect the fire service; cultural diversity; methods used by supervisors to obtain cooperation within a group of subordinates; the rights of management and members; agreements in force between the organization and members; generally accepted ethical practices, including a professional code of ethics; and policies and procedures regarding the operation of the department as they involve supervisors and members.'
                        },
                        2: {
                            title: 'General Prerequisite Skills',
                            text: 'The ability to effectively communicate in writing utilizing technology provided by the AHJ; write reports, letters, and memos; operate in an information management system; and effectively operate at all levels in the incident management system utilized by the AHJ.'
                        }
                    },
                    2: {
                        title: 'Human Resource Management',
                        text: 'This duty involves managing human resources to accomplish assignments in accordance with safety plans and in an efficient manner. This duty also involves evaluating member performance and supervising personnel during emergency and nonemergency work periods, according to the following job performance requirements.',
                        1: {
                            text: 'Assign tasks or responsibilities to unit members, given an assignment at an emergency incident, so that the instructions are complete, clear, and concise; safety considerations are addressed; and the desired outcomes are conveyed.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Verbal communications during emergency incidents, characteristics of leadership, techniques used to make assignments under stressful situations, and methods of confirming understanding.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to condense instructions for frequently assigned unit tasks based on training and standard operating procedures.'
                                }
                            ]
                        },
                        2: {
                            text: 'Assign tasks or responsibilities to unit members, given an assignment under nonemergency conditions at a station or other work location, so that the instructions are complete, clear, and concise; safety considerations are addressed; and the desired outcomes are conveyed.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Verbal communications under nonemergency situations, characteristics of leadership, techniques used to make assignments under routine situations, and methods of confirming understanding.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to issue instructions for frequently assigned unit tasks based on department policy.'
                                }
                            ]
                        },
                        3: {
                            text: 'Direct unit members during a training evolution, given a company training evolution and training policies and procedures, so that the evolution is performed in accordance with safety plans, efficiently, and as directed.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Verbal communication techniques to facilitate learning.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to distribute issue-guided directions to unit members during training evolutions.'
                                }
                            ]
                        },
                        4: {
                            text: 'Recommend action for member-related problems, given a member with a situation requiring assistance and the member assistance policies and procedures, so that the situation is identified and the actions taken are within the established policies and procedures.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'The signs and symptoms of member-related problems (such as behavioral health issues), causes of stress in emergency services personnel, adverse effects of stress on the performance of emergency service personnel, and awareness of AHJ member assistance policies and procedures.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to recommend a course of action for a member in need of assistance.'
                                }
                            ]
                        },
                        5: {
                            text: 'Apply human resource policies and procedures, given an administrative situation requiring action, so that policies and procedures are followed.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Human resource policies, procedures, applicable laws, and legal concepts.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to communicate and to relate interpersonally.'
                                }
                            ]
                        },
                        6: {
                            text: 'Coordinate the completion of assigned tasks and projects by members, given a list of projects and tasks and the job requirements of subordinates, so that the assignments are prioritized, a plan for the completion of each assignment is developed, and members are assigned to specific tasks and both supervised during and held accountable for the completion of the assignments.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Principles of supervision, leadership, and basic human resource management.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to plan and to set priorities.'
                                }
                            ]
                        }
                    },
                    3: {
                        title: 'Community and Government Relations',
                        text: 'This duty involves dealing with inquiries of the community and communicating the role, image, and mission of the department to the public and delivering safety, injury prevention, and fire prevention education programs, according to the following job performance requirements.',
                        1: {
                            text: 'Implement a community risk reduction (CRR) plan at the unit level, given an AHJ CRR plan, and policies and procedures, so that a community need is addressed.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Community demographics and service organizations, verbal and nonverbal communication, and the role and mission of the department and its CRR plan.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Familiarity with public relations and the ability to communicate.'
                                }
                            ]
                        },
                        2: {
                            text: 'Initiate action to a citizen’s concern, given policies and procedures, so that the concern is answered or referred to the correct individual for action and all policies and procedures are complied with.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Interpersonal relationships and verbal and nonverbal communication.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Familiarity with public relations and the ability to communicate.'
                                }
                            ]
                        },
                        3: {
                            text: 'Respond to a public inquiry, given policies and procedures, so that the inquiry is answered accurately, courteously, and in accordance with applicable policies and procedures.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Written and oral communication techniques.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to relate interpersonally and to respond to public inquiries.'
                                }
                            ]
                        }
                    },
                    4: {
                        title: 'Administration',
                        text: 'This duty involves general administrative functions and the implementation of departmental policies and procedures at the unit level, according to the following job performance requirements.',
                        1: {
                            text: 'Recommend changes to existing departmental policies and/or implement a new departmental policy at the unit level, given a new departmental policy, so that the policy is communicated to and understood by unit members.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Written and oral communication.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to relate interpersonally and to communicate change in a positive manner.'
                                }
                            ]
                        },
                        2: {
                            text: 'Execute routine unit-level administrative functions, given forms and record-management systems, so that the reports and logs are complete and files are maintained in accordance with policies and procedures.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Administrative policies and procedures and records management.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to communicate.'
                                }
                            ]
                        },
                        3: {
                            text: 'Prepare a budget request, given a unit level need, so that the request is in the proper format and is supported with data.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Policies and procedures and the revenue sources and budget process.'
                                },
                                {
                                    title: '(B) Requisite Skill',
                                    text: 'The ability to communicate.'
                                }
                            ]
                        },
                        4: {
                            text: 'Explain the purpose of each management component of the organization, given an organization chart, so that the explanation is current and accurate and clearly identifies the purpose and mission of the organization.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Organizational structure of the department and functions of management.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to communicate verbally in a clear and concise manner.'
                                }
                            ]
                        },
                        5: {
                            text: 'Explain the needs and benefits of collecting incident response data, given the goals and mission of the organization, so that incident response reports are timely and accurate.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'The agency\'s records management system.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to communicate.'
                                }
                            ]
                        }
                    },
                    5: {
                        title: 'Inspection and Investigation',
                        text: 'This duty involves conducting inspections to identify hazards and address violations, conducting pre-incident plans, performing a fire investigation to determine area of origin and preliminary cause, securing the incident scene, and preserving evidence, according to the following job performance requirements.',
                        1: {
                            text: 'Describe the procedures of the AHJ for conducting fire inspections, given any of the following occupancies, so that all hazards, including hazardous materials, are identified, approved forms are completed, and approved action is initiated:\n(1) Assembly\n(2) Educational\n(3) Health care\n(4) Detention and correctional\n(5) Residential\n(6) Mercantile\n(7) Business\n(8) Industrial\n(9) Storage\n(10) Unusual structures\n(11) Mixed occupancies',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Inspection procedures; fire detection, alarm, and protection systems; identification of fire and life safety hazards; and marking and identification systems for hazardous materials.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to communicate and to apply the appropriate codes and standards.'
                                }
                            ]
                        },
                        2: {
                            text: 'Identify construction, alarm, detection, and suppression features that contribute to or prevent the spread of fire, heat, and smoke throughout the building or from one building to another, given an occupancy, and the policies and forms of the AHJ so that a pre-incident plan for any of the following occupancies is developed:\n(1) Assembly\n(2) Educational\n(3) Institutional\n(4) Residential\n(5) Business\n(6) Industrial\n(7) Manufacturing\n(8) Storage\n(9) Mercantile\n(10) Special properties\n(11) Mixed occupancies',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Fire behavior; building construction; inspection and incident reports; detection, alarm, and suppression systems; and applicable codes, ordinances, and standards.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to use evaluative methods and to communicate.'
                                }
                            ]
                        },
                        3: {
                            text: 'Direct unit level personnel to secure an incident scene, given rope or barrier tape, so that unauthorized persons can recognize the perimeters of the scene and are kept from restricted areas, and all evidence or potential evidence is protected from damage or destruction.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Types of evidence, the importance of fire scene security, and evidence preservation.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to issue instruction for securing an incident scene.'
                                }
                            ]
                        }
                    },
                    6: {
                        title: 'Emergency Service Delivery',
                        text: 'This duty involves supervising emergency operations and deploying assigned resources in accordance with the local emergency plan and according to the following job performance requirements.',
                        1: {
                            text: 'Develop an initial action plan, given size-up information for an incident and assigned emergency response resources, so that resources are deployed to control the emergency.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Elements of a size-up, standard operating procedures for emergency operations, and fire behavior.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to analyze emergency scene conditions; to activate the local emergency plan, including localized evacuation procedures; to allocate resources; and to communicate orally.'
                                }
                            ]
                        },
                        2: {
                            text: 'Implement an action plan at an emergency operation, given assigned resources, type of incident, and a preliminary plan, so that resources are deployed to mitigate the situation.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Standard operating procedures, resources available for the mitigation of fire and other emergency incidents, an incident management system, scene safety, and a personnel accountability system.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to implement an incident management system, to communicate orally, to manage scene safety, and to supervise and account for assigned personnel under emergency conditions.'
                                }
                            ]
                        },
                        3: {
                            text: 'Develop and conduct a post-incident analysis, given a single unit incident and post-incident analysis policies, procedures, and forms, so that all required critical elements are identified and communicated, and the approved forms are completed and processed in accordance with policies and procedures.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Elements of a fire or rescue-related post-incident analysis, basic building construction, basic fire protection systems and features, basic water supply, basic fuel loading, fire growth and development, and departmental procedures relating to dispatch response tactics and operations, the source of any emergency operations controlling authority, including EMS protocols, if applicable, and customer service.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to write reports, to communicate, and to evaluate skills.'
                                }
                            ]
                        }
                    },
                    7: {
                        title: 'Health and Safety',
                        text: 'This duty involves integrating health and safety plans, policies, procedures, and standards into daily activities as well as the emergency scene, including determining appropriate levels of personal protective equipment to ensure a work environment that is in accordance with health and safety plans for all assigned members, according to the following job performance requirements.',
                        1: {
                            text: 'Apply safety regulations at the unit level, given safety policies, procedures, and standards, so that required reports are completed, in-service training is conducted, and member responsibilities are conveyed.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'The most common causes of personal injury and accident to members; safety policies and procedures; basic workplace safety; the components of an infectious disease control program; and the selection, care, and maintenance of personal protective equipment in accordance with Chapter 7 of NFPA 1851.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to identify safety hazards and exposures, communicate, and complete documentation necessary for exposure reporting and tracking.'
                                }
                            ]
                        },
                        2: {
                            text: 'Conduct an initial accident investigation, given an incident and investigation process, so that the incident is documented and reports are processed in accordance with policies and procedures of the AHJ.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Procedures for conducting an accident investigation and safety policies and procedures.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to communicate and to conduct interviews.'
                                }
                            ]
                        },
                        3: {
                            text: 'Explain the benefits of being physically and medically capable of performing assigned duties and effectively functioning during peak physical demand activities, given current fire service trends and agency policies, so that the need to participate in wellness and fitness programs is explained to members.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'National death and injury statistics, suicide prevention initiatives, fire service safety and wellness initiatives, and agency policies.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'The ability to communicate.'
                                }
                            ]
                        }
                    }
                }
            }
        }
    },
    1035: {
        title: 'Standard for Professional Qualifications for Fire and Life Safety Educator, Public Information Officer, and Juvenile Firesetter Intervention Specialist'
    },
    1072: {
        title: 'Standard for Hazardous Materials/Weapons of Mass Destruction Emergency Response Personnel Professional Qualifications',
        editions: {
            2017: {
                3: {
                    title: 'Definitions'
                },
                4: {
                    title: 'Awareness',
                    1: {
                        title: 'General',
                        1: {
                            text: 'Awareness personnel are those persons who, in the course of their normal duties, could encounter an emergency involving hazardous materials/weapons of mass destruction (WMD) and who are expected to recognize the presence of the hazardous materials/WMD, protect themselves, call for trained personnel, and secure the area.'
                        },
                        2: {
                            text: 'Awareness personnel shall meet the job performance requirements defined in Sections 4.2 through 4.4.'
                        },
                        3: {
                            title: 'General Knowledge Requirements',
                            text: 'Role of awareness personnel at a hazardous materials/WMD incident, location and contents of the AHJ emergency response plan, and standard operating procedures for awareness personnel.'
                        },
                        4: {
                            title: 'General Skills Requirements',
                            text: '(Reserved)'
                        }
                    },
                    2: {
                        title: 'Recognition and Identification',
                        1: {
                            text: 'Recognize and identify the hazardous materials/WMD and hazards involved in a hazardous materials/WMD incident, given a hazardous materials/WMD incident, and approved reference sources, so that the presence of hazardous materials/WMD is recognized and the materials and their hazards are identified.',
                            other: [
                                {
                                    title: 'Requisite Knowledge',
                                    text: 'What hazardous materials and WMD are; basic hazards associated with classes and divisions; indicators to the presence of hazardous materials including container shapes, NFPA 704 markings, globally harmonized system (GHS) markings, placards, labels, pipeline markings, other transportation markings, shipping papers with emergency response information, and other indicators; accessing information from the Emergency Response Guidebook (ERG) (current edition) using name of the material, UN/NA identification number, placard applied, or container identification charts; and types of hazard information available from the ERG, safety data sheets (SDS), shipping papers with emergency response information, and other approved reference sources.'
                                },
                                {
                                    title: 'Requisite Skills',
                                    text: 'Recognizing indicators to the presence of hazardous materials/WMD; identifying hazardous materials/WMD by name, UN/NA identification number, placard applied, or container identification charts; and using the ERG, SDS, shipping papers with emergency response information, and other approved reference sources to identify hazardous materials/WMD and their potential fire, explosion, and health hazards.'
                                }
                            ]
                        }
                    },
                    3: {
                        title: 'Initiate Protective Actions',
                        1: {
                            text: 'Isolate the hazard area and deny entry at a hazardous materials/WMD incident, given a hazardous materials/WMD incident, policies and procedures, and approved reference sources, so that the hazard area is isolated and secured, personal safety procedures are followed, hazards are avoided or minimized, and additional people are not exposed to further harm.',
                            other: [
                                {
                                    title: 'Requisite Knowledge',
                                    text: 'Use of the ERG, SDS, shipping papers with emergency response information, and other approved reference sources to identify precautions to be taken to protect responders and the public; policies and procedures for isolating the hazard area and denying entry; and the purpose of and methods for isolating the hazard area and denying entry.'
                                },
                                {
                                    title: 'Requisite Skills',
                                    text: 'Recognizing precautions for protecting responders and the public; identifying isolation areas, denying entry, and avoiding minimizing hazards.'
                                }
                            ]
                        }
                    },
                    4: {
                        title: 'Notification',
                        1: {
                            text: 'Initiate required notifications at a hazardous materials/WMD incident, given a hazardous materials/WMD incident, policies and procedures, and approved communications equipment, so that the notification process is initiated and the necessary information is communicated.',
                            other: [
                                {
                                    title: 'Requisite Knowledge',
                                    text: 'Policies and procedures for notification, reporting, and communications; types of approved communications equipment; and the operation of that equipment.'
                                },
                                {
                                    title: 'Requisite Skills',
                                    text: 'Operating approved communications equipment and communicating in accordance with policies and procedures.'
                                }
                            ]
                        }
                    }
                },
                5: {
                    title: 'Operations',
                    1: {
                        title: 'General',
                        1: {
                            text: 'Operations level responders are those persons who respond to hazardous materials/weapons of mass destruction (WMD) incidents for the purpose of implementing or supporting actions to protect nearby persons, the environment, or property from the effects of the release.'
                        },
                        2: {
                            text: 'Operations level responders shall meet the job performance requirements defined in Sections 4.2 through 4.4.'
                        },
                        3: {
                            text: 'Operations level responders shall meet the job performance requirements defined in Sections 5.2 through 5.6.'
                        },
                        4: {
                            text: 'Operations level responders shall have additional competencies that are specific to the response mission and expected tasks as determined by the AHJ.'
                        },
                        5: {
                            title: 'General Knowledge Requirements',
                            text: 'Role of operations level responders at a hazardous materials/WMD incident; location and contents of AHJ emergency response plan and standard operating procedures for operations level responders, including those response operations for hazardous materials/WMD incidents.'
                        },
                        6: {
                            title: 'General Skills Requirements',
                            text: '(Reserved)'
                        }
                    },
                    2: {
                        title: 'Identify Potential Hazards',
                        1: {
                            text: 'Identify the scope of the problem at a hazardous materials/WMD incident, given a hazardous materials/WMD incident, an assignment, policies and procedures, and approved reference sources, so that container types, materials, location of any release, and surrounding conditions are identified, hazard information is collected, the potential behavior of a material and its container is identified, and the potential hazards, harm, and outcomes associated with that behavior are identified.',
                            other: [
                                {
                                    title: 'Requisite Knowledge',
                                    text: 'Definitions of hazard classes and divisions; types of containers; container identification markings, including piping and pipeline markings and contacting information; types of information to be collected during the hazardous materials/WMD incident survey; availability of shipping papers in transportation and of safety data sheets (SDS) at facilities; types of hazard information available from and how to contact CHEMTREC, CANUTEC, and SETIQ, governmental authorities, and manufacturers, shippers, and carriers; how to communicate with carrier representatives to reduce impact of a release; basic physical and chemical properties, including boiling point, chemical reactivity, corrosivity (pH), flammable (explosive) range [LFL (LEL) and UFL (UEL)], flash point, ignition (autoignition) temperature, particle size, persistence, physical state (solid, liquid, gas), radiation (ionizing and nonionizing), specific gravity, toxic products of combustion, vapor density, vapor pressure, and water solubility; how to identify the behavior of a material and its container based on the material\'s physical and chemical properties and the hazards associated with the identified behavior; examples of potential criminal and terrorist targets; indicators of possible criminal or terrorist activity for each of the following: chemical agents, biological agents, radiological agents, illicit laboratories (i.e., clandestine laboratories, weapons labs, ricin labs), and explosives; additional hazards associated with terrorist or criminal activities, such as secondary devices; and how to determine the likely harm and outcomes associated with the identified behavior and the surrounding conditions.'
                                },
                                {
                                    title: 'Requisite Skills',
                                    text: 'Identifying container types, materials, location of release, and surrounding conditions at a hazardous materials/WMD incident; collecting hazard information; communicating with pipeline operators or carrier representatives; describing the likely behavior of the hazardous materials or WMD and its container; and describing the potential hazards, harm, and outcomes associated with that behavior and the surrounding conditions.'
                                }
                            ]
                        }
                    },
                    3: {
                        title: 'Identify Action Options',
                        1: {
                            text: 'Identify the action options for a hazardous materials/WMD incident, given a hazardous materials/WMD incident, an assignment, policies and procedures, approved reference sources, and the scope of the problem, so that response objectives, action options, safety precautions, suitability of approved personal protective equipment (PPE) available, and emergency decontamination needs are identified.',
                            other: [
                                {
                                    title: 'Requisite Knowledge',
                                    text: 'Policies and procedures for hazardous materials/WMD incident operations; basic components of an incident action plan (IAP); modes of operation (offensive, defensive, and nonintervention); types of response objectives; types of action options; types of response information available from the Emergency Response Guidebook (ERG), safety data sheets (SDS), shipping papers with emergency response information, and other resources; types of information available from and how to contact CHEMTREC, CANUTEC, and SETIQ, governmental authorities, and manufacturers, shippers, and carriers (highway, rail, water, air, pipeline); safety procedures; risk analysis concepts; purpose, advantages, limitations, and uses of approved PPE to determine if PPE is suitable for the incident conditions; difference between exposure and contamination; contamination types, including sources and hazards of carcinogens at incident scenes; routes of exposure; types of decontamination (emergency, mass, and technical); purpose, advantages, and limitations of emergency decontamination; and procedures, tools, and equipment for performing emergency decontamination.'
                                },
                                {
                                    title: 'Requisite Skills',
                                    text: 'Identifying response objectives and action options based on the scope of the problem and available resources; identifying whether approved PPE is suitable for the incident conditions; and identifying emergency decontamination needs based on the scope of the problem.'
                                }
                            ]
                        }
                    },
                    4: {
                        title: 'Action Plan Implementation',
                        1: {
                            text: 'Perform assigned tasks at a hazardous materials/WMD incident, given a hazardous materials/WMD incident; an assignment with limited potential of contact with hazardous materials/WMD, policies and procedures, the scope of the problem, approved tools, equipment, and PPE, so that protective actions and scene control are established and maintained, on-scene incident command is described, evidence is preserved, approved PPE is selected and used in the proper manner; exposures and personnel are protected; safety procedures are followed; hazards are avoided or minimized; assignments are completed; and gross decontamination of personnel, tools, equipment, and PPE is conducted in the field.',
                            other: [
                                {
                                    title: 'Requisite Knowledge',
                                    text: 'Scene control procedures; procedures for protective actions, including evacuation and sheltering-in-place; procedures for ensuring coordinated communications between responders and to the public; evidence recognition and preservation procedures; incident command organization; purpose, importance, benefits, and organization of incident command at hazardous materials/WMD incidents; policies and procedures for implementing incident command at hazardous materials/WMD incidents; capabilities, limitations, inspection, donning, working in, going through decontamination while wearing, doffing approved PPE; signs and symptoms of thermal stress; safety precautions when working at hazardous materials/WMD incidents; purpose, advantages, and limitations of gross decontamination; the need for gross decontamination in the field based on the task(s) performed and contamination received, including sources and hazards of carcinogens at incident scenes; gross decontamination procedures for personnel, tools, equipment, and PPE; and cleaning, disinfecting, and inspecting tools, equipment, and PPE.'
                                },
                                {
                                    title: 'Requisite Skills',
                                    text: 'Establishing and maintaining scene control; recognizing and preserving evidence; inspecting, donning, working in, going through decontamination while wearing, and doffing approved PPE; isolating contaminated tools, equipment, and PPE; conducting gross decontamination of contaminated personnel, tools, equipment, and PPE in the field; and cleaning, disinfecting, and inspecting approved tools, equipment, and PPE.'
                                }
                            ]
                        }
                    },
                    5: {
                        title: 'Emergency Decontamination',
                        1: {
                            text: 'Perform emergency decontamination at a hazardous materials/WMD incident, given a hazardous materials/WMD incident that requires emergency decontamination; an assignment; scope of the problem; policies and procedures; and approved tools, equipment, and PPE for emergency decontamination, so that emergency decontamination needs are identified, approved PPE is selected and used, exposures and personnel are protected, safety procedures are followed, hazards are avoided or minimized, emergency decontamination is set up and implemented, and victims and responders are decontaminated.',
                            other: [
                                {
                                    title: 'Requisite Knowledge',
                                    text: 'Contamination, cross contamination, and exposure; contamination types; routes of exposure; types of decontamination (emergency, mass, and technical); purpose, advantages, and limitations of emergency decontamination; policies and procedures for performing emergency decontamination; and hazard avoidance for emergency decontamination.'
                                },
                                {
                                    title: 'Requisite Skills',
                                    text: 'Selecting an emergency decontamination method; setting up emergency decontamination in a safe area; using PPE in the proper manner; implementing emergency decontamination; preventing spread of contamination; and avoiding hazards during emergency decontamination.'
                                }
                            ]
                        }
                    },
                    6: {
                        title: 'Progress Evaluation and Reporting',
                        1: {
                            text: 'Evaluate and report the progress of the assigned tasks for a hazardous materials/WMD incident, given a hazardous materials/WMD incident, an assignment, policies and procedures, status of assigned tasks, and approved communication tools and equipment, so that the effectiveness of the assigned tasks is evaluated and communicated to the supervisor, who can adjust the IAP as needed.',
                            other: [
                                {
                                    title: 'Requisite Knowledge',
                                    text: 'Components of progress reports; policies and procedures for evaluating and reporting progress; use of approved communication tools and equipment; signs indicating improving, static, or deteriorating conditions based on the objectives of the action plan; and circumstances under which it would be prudent to withdraw from a hazardous materials/WMD incident.'
                                },
                                {
                                    title: 'Requisite Skills',
                                    text: 'Determining incident status; determining whether the response objectives are being accomplished; using approved communications tools and equipment; and communicating the status of assigned tasks.'
                                }
                            ]
                        }
                    }
                }
            }
        }
    },
    1041: {
        title: 'Standard for Fire Service Instructor Professional Qualifications'
    },
    1407: {
        title: 'Standard for Training Fire Service Rapid Intervention Crews',
        editions: {
            2015: {
                4: {
                    title: 'Rapid Intervention Training Policy and Procedures',
                    1: {
                        title: 'General',
                        1: {
                            text: 'A rapid intervention training program shall be developed for the safety of all fire suppression personnel.'
                        },
                        2: {
                            text: 'Risks to safety of fire department members during training programs shall be kept to a minimum.'
                        }
                    },
                    2: {
                        title: 'Training Policies and Guidelines',
                        text: 'The authority having jurisdiction (AHJ) shall establish written policies for rapid intervention training that meet the requirements of this standard.',
                        1: {
                            text: 'Rapid intervention operations shall be guided by written operational policies or guidelines and reinforced through a comprehensive training program.'
                        },
                        2: {
                            text: 'The training policy shall be consistent with the department operational procedures, including automatic aid and mutual aid departments.'
                        },
                        3: {
                            text: 'Rapid intervention training shall be developed and conducted to provide a constant, sustainable rapid intervention capability at the emergency scene utilizing a minimum crew size as required by NFPA 1710, Standard for the Organization and Deployment of Fire Suppression Operations, Emergency Medical Operations, and Special Operations to the Public by Career Fire Departments, or NFPA 1720, Standard for the Organization and Deployment of Fire Suppression Operations, Emergency Medical Operations, and Special Operations to the Public by Volunteer Fire Departments.'
                        },
                        4: {
                            text: 'The RIC training program shall train fire fighters to use individual RIC skills as a part of a crew.'
                        },
                        5: {
                            text: 'The policy shall address the entry level requirements for crew members, the training objectives, basic skills, equipment, and training evolutions contained in this standard.'
                        }
                    },
                    3: {
                        title: 'Rapid Intervention Crew Members',
                        1: {
                            text: 'All members being trained to become part of a RIC shall meet the minimum requirements outlined in Chapter 6.'
                        },
                        2: {
                            text: 'All members being trained to become part of a RIC shall receive a copy of the training policy.'
                        }
                    },
                    4: {
                        title: 'Conducting, Evaluating, and Documenting Training',
                        text: 'A safeety briefing shall be conducted prior to RIC training evolutions.',
                        1: {
                            text: 'The training policy shall include an annual performance evaluation of the RIC operations and RIC members based on the requirements of this standard.'
                        },
                        2: {
                            text: 'Training and evaluation documentation shall be maintained as required by the AHJ.'
                        }
                    }
                },
                5: {
                    title: 'RIC Instructor Requirements',
                    1: {
                        title: 'General',
                        text: 'All instructors shall be qualified to deliver RIC training as determined by the AHJ.',
                        1: {
                            text: 'The instructor-in-charge shall be responsible for full compliance with this standard.'
                        },
                        2: {
                            text: 'It shall be the responsibility of the instructor-in-charge to coordinate overall evolutions during RIC training.'
                        },
                        3: {
                            text: 'Additional instructors shall be designated when factors such as extreme temperatures or large groups are present, and evolutions of long duration or complexity are planned.'
                        }
                    },
                    2: {
                        title: 'Conducting Training Program',
                        text: 'The participating student-to-instructor ratio shall not be greater than 5 to 1.',
                        1: {
                            text: 'Prior to the evolution, instructors shall ensure that all protective clothing and equipment are being worn or used according to manufacturer’s instructions.'
                        },
                        2: {
                            text: 'Instructors shall conduct an accountability check as crews enter and exit the structure during an RIC training evolution.'
                        },
                        3: {
                            text: 'Instructors shall monitor and supervise all assigned students during the RIC training evolution.'
                        },
                        4: {
                            text: 'The instructor-in-charge shall provide for rest and rehabilitation of members operating at the scene, including any necessary medical evaluation and treatment, food and fluid replenishment, and relief from climatic conditions. (See Annex B.)'
                        }
                    }
                },
                6: {
                    title: 'Student Prerequisites',
                    1: {
                        title: 'General Requirements',
                        text: 'Prior to being permitted to participate in RIC training, the student shall have received training to meet the job performance requirements for Fire Fighter I in NFPA 1001, Standard for Fire Fighter Professional Qualifications.',
                        1: {
                            text: 'Students participating in RIC training who have received the required minimum training from other than the AHJ shall not be permitted to participate in any RIC training without first presenting prior written evidence of having successfully completed the prescribed minimum training to the level specified in Section 6.1.'
                        }
                    },
                    2: {
                        title: 'Rapid Intervention Crew Leaders',
                        1: {
                            text: 'All designated RIC leaders shall meet the requirements of Fire Officer I as specified in NFPA 1021, Standard for Fire Officer Professional Qualifications.'
                        },
                        2: {
                            text: 'RIC leaders shall complete the rapid intervention training program.'
                        }
                    },
                    3: {
                        title: 'Fitness',
                        text: 'The AHJ shall ensure that members can perform assigned duties and functions of rapid intervention and that members perform training exercises in accordance with Chapter 10 of NFPA 1500, Standard on Fire Department Occupational Safety and Health Program.'
                    }
                },
                7: {
                    title: 'Rapid Intervention Operations Training Program',
                    1: {
                        title: 'General',
                        text: 'The rapid intervention training program shall include the following areas:\n(1) Risk assessment and risk management\n(2) Activation and management of rapid intervention operations\n(3) Communications procedures specific to rapid intervention functions\n(4) Accountability and resource management\n(5) Criteria for deploying rapid intervention crews and resources to the scene\n(6) Integration of fire fighter rescue operations into the incident command system as specified by the AHJ\n(7) Implementation of an incident accountability system that meets the requirements of NFPA 1561, Standard on Emergency Services Incident Management System and Command Safety\n(8) Potential impact of RIC operations on the safety and welfare of rescuers and others as well as on other activities at the incident site\n(9) Distinction between rescue and recovery modes'
                    },
                    2: {
                        title: 'Incident Management System Integration',
                        text: 'The AHJ shall provide for and utilize training on the implementation of an incident management system that meets the requirements of NFPA 1561, Standard on Emergency Services Incident Management System and Command Safety, with written standard operating procedures applying to all members involved in emergency RIC operations.'
                    },
                    3: {
                        title: 'Rotation of RIC Personnel',
                        text: 'RIC instructors shall rotate personnel to reduce stress and fatigue.'
                    },
                    4: {
                        title: 'Rapid Intervention Skills',
                        text: 'The rapid intervention training program shall include, at a minimum, the following topics for personal and crew-oriented skills training and evaluation:\n(1) Declaring Mayday\n(2) Search techniques\n(3) Access and extrication\n(4) Air supply\n(5) Ropes\n(6) Protecting downed fire fighter(s) in place\n(7) Moving downed fire fighter(s) to safety\n(8) Fire fighter self-rescue techniques'
                    },
                    5: {
                        title: 'Declaring a Mayday',
                        text: 'The following protocols shall be included in the minimum training for RIC members:\n(1) Declaration of Mayday (the word that initiates an emergency response by an RIC)\n(2) Unit/name; situation; location; intention; personal accountability report (PAR): the nature of the emergency and conditions'
                    },
                    6: {
                        title: 'Search Techniques',
                        text: 'The following skills shall be included in the minimum training for RIC members:\n(1) Searching an area utilizing ropes\n(2) Searching in an area with limited means of egress\n(3) Searching with thermal imaging equipment'
                    },
                    7: {
                        title: 'Access and Extrication',
                        text: 'The following skills shall be included in the minimum training for RIC members:\n(1) Breach of barriers (walls, ceilings, floors)\n(2) Self-contained breathing apparatus (SCBA) profile reduction drill\n(3) Recognizing entanglement hazards\n(4) Disentanglement\n(5) Extraction from debris'
                    },
                    8: {
                        title: 'Air Supply',
                        text: 'The following shall be included in the minimum training for RIC members:\n(1) Air management procedures and techniques as required by NFPA 1404, Standard for Fire Service Respiratory Protection Training',
                        2: {
                            text: 'When using rescue air, the following items should be considered:\n(a) Capacity of the rescue air supply\n(b) Egress from the hazard area\n(c) Hazards observed'
                        },
                        3: {
                            text: 'When using rescue air, RIC members should be able to do the following:\n(a) Reposition or replace SCBA face piece on a downed fire fighter\n(b) Replace mask-mounted regulator on a downed fire fighter\n(c) Utilize the RIC universal air connection (UAC) where available\n(d) Utilize a NIOSH-certified EBSS/Buddy Breather where available\n(e) Replace the air supply of a downed fire fighter\n(f) Locate alternate rescue air supply sources'
                        }
                    },
                    9: {
                        title: 'SCBAs',
                        text: 'SCBAs used in training shall comply with the requirements of NFPA 1981, Standard on Open-Circuit Self-Contained Breathing Apparatus (SCBA) for Emergency Services.'
                    },
                    10: {
                        title: 'Ropes, Slings, and Harnesses',
                        text: 'The following skills shall be included in the minimum training for RIC members:\n(1) The use of search ropes, slings, and harnesses\n(2) The use of mechanical advantage rope systems for rescue of fire fighters\n(3) The use of rescue knots\n(4) The use of any equipment or rescue tactics required by the AHJ',
                        1: {
                            text: 'All belay and life safety ropes, slings, and harnesses used in training shall comply with the requirements of NFPA 1983, Standard on Life Safety Rope and Equipment for Emergency Services.'
                        },
                        2: {
                            text: 'When participating in an elevated emergency egress or vertical lift scenario, the fire fighter in training shall be secured by a belay line and a Class 1 harness if one of the following applies:\n(1) The fire fighter is playing the role of the downed fire fighter.\n(2) The fire fighter is participating in a self-rescue drill.\n(3) The fire fighter is rappelling.'
                        }
                    },
                    11: {
                        title: 'Protecting Downed Fire Fighter(s) in Place',
                        text: 'The following skills shall be included in the minimum training for RIC members:\n(1) Provide and maintain a continuous air supply\n(2) Provide protective hose line(s)\n(3) Secure a dedicated communications channel (talk group) for rescue operations\n(4) Continuously monitor conditions that can affect the rescue operations'
                    },
                    12: {
                        title: 'Moving Downed Fire Fighter(s) to Safety',
                        text: 'The following skills shall be included in the minimum training for RIC members:\n(1) Using basic drags, lifts, and carries (blankets, webbing/rope, push-pull, and simple pulley system)\n(2) Moving a downed fire fighter up and down stairs\n(3) Utilizing a rescue basket or rescue boards\n(4) Moving a downed fire fighter over a ladder (conscious assist and unconscious carry)\n(5) Moving a downed fire fighter through a window (ground level and upper levels)\n(6) Moving a downed fire fighter from below the area of operations (through floor)\n(7) Moving a downed fire fighter in an attic space\n(8) Moving a downed fire fighter from below grade\n(9) Moving a downed fire fighter through an elevated (restricted size) window in a room with limited space for crew movement',
                        1: {
                            text: 'Training for skills in 7.12(1) through 7.12(9) shall include various conditions such as in limited or zero visibility.'
                        },
                        2: {
                            text: 'Additional skills and rescue scenarios shall be developed as required by the AHJ.'
                        }
                    },
                    13: {
                        title: 'Fire Fighter Self-Rescue',
                        1: {
                            text: 'The following skills shall be included in the minimum training for RIC members:\n(1) Recognizing situations under which rapid intervention is required for rescue and required techniques for calling for assistance\n(2) Freeing self from entanglement\n(3) Rapid room orientation and exit, including awareness of primary and secondary exits from room\n(4) Individual air management'
                        },
                        2: {
                            text: 'Escape techniques for elevated emergency egress as approved by the AHJ shall be conducted in compliance with 7.10.2.'
                        }
                    },
                    14: {
                        title: 'Tools and Equipment',
                        text: 'The training program shall include the use of any equipment or rescue techniques approved by the local AHJ.',
                        1: {
                            text: 'Tools and equipment to be used by the RIC shall be determined by the AHJ based on need and resources available.'
                        },
                        2: {
                            text: 'Training for rapid intervention shall also include training on other rescue equipment and tools provided by the fire department.'
                        }
                    }
                },
                8: {
                    title: 'Required Performance for Rapid Intervention Crews (RIC)',
                    1: {
                        title: 'General',
                        1: {
                            text: 'The required performance for establishing a fire fighter rescue team shall consist of assembling the necessary staffing, assembling the minimum amount of tools, maintaining crew integrity and accountability, and locating and removing a fire fighter from a hazardous environment.'
                        },
                        2: {
                            text: 'RIC operations shall be conducted by the unit designated by the AHJ.'
                        },
                        3: {
                            text: 'The required staffing of RIC shall be consistent with the requirements of NFPA 1710, Standard for the Organization and Deployment of Fire Suppression Operations, Emergency Medical Operations, and Special Operations to the Public by Career Fire Departments.'
                        },
                        4: {
                            text: 'A safety officer shall be appointed for all RIC evolutions.'
                        }
                    },
                    2: {
                        title: 'Methods of Evaluation',
                        1: {
                            text: 'The RIC shall be staged in an area designated by the evaluator prior to the start of the evolution.'
                        },
                        2: {
                            text: 'A simulated downed fire fighter shall be placed in a location determined by the evaluator prior to the start of the evolution.'
                        },
                        3: {
                            text: 'Simulated “safe areas” shall be identified by the evaluator prior to the start of the evolution.'
                        },
                        4: {
                            text: 'These areas shall serve as locations where the RIC can move the downed fire fighter to end the evolution.'
                        },
                        5: {
                            text: 'When ordered to begin the evolution, the RIC shall be deployed to initiate the rescue.'
                        },
                        6: {
                            text: 'The evolution shall conclude when the downed fire fighter is removed to the designated safe area.'
                        },
                        7: {
                            text: 'The time to complete the evolution shall be documented by the evaluator.'
                        }
                    },
                    3: {
                        title: 'Locating, Assessing, and Removing a Downed Fire Fighter',
                        1: {
                            text: 'The required performance for locating, assessing, and removing a downed fire fighter shall consist of locating a simulated downed fire fighter using organized search and rescue tactics.'
                        },
                        2: {
                            text: 'RICs shall wear full personal protective equipment (PPE) and SCBA.'
                        },
                        3: {
                            text: 'The downed fire fighter shall be assessed for usable air supply, major traumatic injury, and level of consciousness.'
                        },
                        4: {
                            text: 'A rescue air supply shall be placed in service when sustainable breathing air for the downed fire fighter is absent.'
                        },
                        5: {
                            text: 'A radio report shall be broadcast including the location of the downed fire fighter, the fire fighter’s condition, and the intention of the RIC.'
                        },
                        6: {
                            text: 'The RIC shall package the downed fire fighter according to policy and guidelines established by the AHJ.'
                        }
                    },
                    4: {
                        title: 'Removing a Downed Fire Fighter up a Flight of Stairs',
                        1: {
                            text: 'The required performance for removing a downed fire fighter up a flight of stairs shall involve a minimum of two fire fighters wearing a full complement of PPE and SCBA.'
                        },
                        2: {
                            text: 'The RIC shall apply rescue techniques approved by the AHJ.'
                        }
                    },
                    5: {
                        title: 'Removing a Downed Fire Fighter from First-Floor Window',
                        1: {
                            text: 'The required performance for removing a downed fire fighter from a first-floor window shall involve a minimum of two fire fighters wearing a full complement of PPE and SCBA.'
                        },
                        2: {
                            text: 'The RIC shall apply rescue techniques approved by the AHJ.'
                        }
                    },
                    6: {
                        title: 'Removing a Downed Fire Fighter from a Second-Floor Window',
                        1: {
                            text: 'The required performance for removing a downed fire fighter from a second-floor window shall involve a minimum of two fire fighters wearing a full complement of PPE and SCBA.'
                        },
                        2: {
                            text: 'The RIC shall apply rescue techniques approved by the AHJ.'
                        }
                    },
                    7: {
                        title: 'Removing a Downed Fire Fighter from a Hole in the Floor',
                        1: {
                            text: 'The required performance for removing a downed fire fighter from a hole in the floor shall involve a minimum of two fire fighters wearing a full complement of PPE and SCBA.'
                        },
                        2: {
                            text: 'The RIC shall apply rescue techniques approved by the AHJ.'
                        }
                    }
                }
            }
        }
    },
    1500: {
        title: 'Standard on Fire Department Occupational Safety, Health, and Wellness Program',
        editions: {
            2018: {
                4: {
                    title: 'Fire Department Adminstration',
                    2: {
                        title: 'Risk Management Plan',
                        1: {
                            text: 'The fire department shall develop and adopt a comprehensive written risk management plan.'
                        },
                        2: {
                            text: 'The risk management plan shall at least cover the risks associated with the following:\n(1) Administration\n(2) Facilities\n(3) Training\n(4) Vehicle operations, both emergency and non-emergency\n(5) Protective clothing and equipment\n(6) Operations at emergency incidents (see Annex C)\n(7) Operations at non-emergency incidents\n(8) Products of combustion, carcinogens, fireground contaminants, and other incident-related health hazards\n(9) Other related activities'
                        },
                        3: {
                            text: 'The risk management plan shall include at least the following components (see Annex D):\n(1) Risk identification — actual and potential hazards\n(2) Risk evaluation — likelihood of occurrence of a given hazard and severity of its consequences\n(3) Establishment of priorities for action — the degree of a hazard based upon the frequency and risk of occurrence\n(4) Risk control techniques — solutions for elimination or mitigation of potential hazards; implementation of best solution\n(5) Risk management monitoring — evaluation of effectiveness of risk control techniques'
                        }
                    }
                },
                8: {
                    title: 'Emergency Operations',
                    1: {
                        title: 'Incident Management',
                        1: {
                            text: 'Emergency operations and other situations that pose similar hazards, including but not limited to training exercises, shall be conducted in a manner that recognizes hazards and prevents accidents and injuries.'
                        },
                        2: {
                            text: 'An incident management system that meets the requirements of NFPA 1561 shall be established with written standard operating procedures applying to all members involved in emergency operations.'
                        },
                        3: {
                            text: 'The incident management system shall be utilized at all emergency incidents.'
                        },
                        4: {
                            text: 'The incident management system shall be applied to drills, exercises, and other situations that involve hazards similar to those encountered at actual emergency incidents and to simulated incidents that are conducted for training and familiarization purposes.'
                        },
                        5: {
                            text: 'At an emergency incident, the incident commander shall be responsible for the overall management of the incident and the safety of all members involved at the scene.'
                        },
                        6: {
                            text: 'As incidents escalate in size and complexity, the incident commander shall divide the incident into tactical-level management components and assign an incident safety officer to assess the incident scene for hazards or potential hazards.'
                        },
                        7: {
                            text: 'At an emergency incident, the incident commander shall establish an organization with sufficient supervisory personnel to control the position and function of all members operating at the scene and to ensure that safety requirements are satisfied.'
                        },
                        8: {
                            text: 'At an emergency incident, the incident commander shall have the responsibility for the following:\n(1) Arrive on-scene before assuming command\n(2) Assume and confirm command of an incident and take an effective command position\n(3) Perform situation evaluation that includes risk assessment\n(4) Initiate, maintain, and control incident communications\n(5) Develop an overall strategy and an incident action plan and assign companies and members consistent with the standard operating procedures\n(6) Initiate an accountability and inventory worksheet\n(7) Develop an effective incident organization by managing resources, maintaining an effective span of control, and maintaining direct supervision over the entire incident, and designate supervisors in charge of specific areas or functions\n(8) Review, evaluate, and revise the incident action plan as required\n(9) Continue, transfer, and terminate command\n(10) On incidents under the command authority of the fire department, provide for liaison and coordination with all other cooperating agencies\n(11) On incidents where other agencies have jurisdiction, implement a plan that designates one incident commander or that provides for unified command',
                            1: {
                                text: 'Interagency coordination shall meet the requirements of NFPA 1561.'
                            }
                        }
                    },
                    2: {
                        title: 'Communications',
                        1: {
                            text: 'The fire department shall establish and ensure the maintenance of a fire dispatch and incident communications system that meets the requirements of NFPA 1561 and NFPA 1221.'
                        },
                        2: {
                            text: 'While operating in a hot or warm zone, all personnel shall be equipped with a portable radio provided by the fire department.'
                        },
                        3: {
                            text: 'The fire department standard operating procedures shall provide direction in the use of clear text radio messages for emergency incidents.',
                            1: {
                                text: 'The standard operating procedures shall use "emergency traffic" as a designator to clear the radio traffic for an emergency affecting the incident.'
                            },
                            2: {
                                text: 'This "emergency traffic" shall be permitted to be declared by any member who becomes aware of an emergency affecting the incident.'
                            }
                        },
                        4: {
                            text: 'When a member has declared "emergency traffic," that person shall use clear text to identify the type of emergency, change in conditions, or tactical operations.',
                            1: {
                                text: 'The member who has declared the "emergency traffic" shall conclude the "emergency traffic" message by transmitting "all clear, resume radio traffic" to end the emergency situation or to re-open the radio channels to communication after announcing the emergency message.'
                            },
                            2: {
                                text: 'The standard operating procedures shall use "mayday" as a designator to identify when a member is in a life-threatening situation and in need of immediate assistance.'
                            },
                            3: {
                                text: 'This "mayday" shall be permitted to be declared by any member who is in or who becomes aware of a member who is in a life-threatening situation and in need of immediate assistance.'
                            },
                            4: {
                                text: 'The incident commander shall conclude the "mayday" by transmitting "Mayday cleared, resume normal radio traffic."'
                            }
                        },
                        5: {
                            text: 'The fire department communications center shall start an incident clock when the first arriving unit is on-scene of a working structure fire or hazardous materials incident, or when other conditions appear to be time sensitive or dangerous.',
                            1: {
                                text: 'The dispatch center shall notify the incident commander at every 10-minute increment with the time that resources have been on the incident until the incident is knocked down or the incident becomes static.'
                            },
                            2: {
                                text: 'The incident commander shall be permitted to cancel the incident clock notification through the fire department communications center based on the incident conditions.'
                            }
                        }
                    },
                    3: {
                        title: 'Crew Resource Management (CRM) During Emergency Operations',
                        1: {
                            text: 'The incident commander shall integrate Crew Resource Management (CRM) into the regular functions of incident command.'
                        }
                    },
                    4: {
                        title: 'Risk Management During Emergency Operations',
                        1: {
                            text: 'The incident commander shall integrate risk management into the regular functions of incident command.'
                        },
                        2: {
                            text: 'The incident commander shall determine the life safety profile of the incident and apply the most appropriate level of risk to first responders consistent with the principles in 8.4.2.1.',
                            1: {
                                text: 'The concept of risk management shall be utilized on the basis of the following principles:\n(1) Activities that present a significant risk to the safety of members shall be limited to situations where there is a potential to save endangered lives.\n(2) Activities that are routinely employed to protect property shall be recognized as inherent risks to the safety of members, and actions shall be taken to reduce or avoid these risks.\n(3) No risk to the safety of members shall be acceptable when there is no possibility to save lives or property.\n(4) In situations where the risk to fire department members is excessive, activities shall be limited to defensive operations.'
                            }
                        },
                        3: {
                            text: 'The incident commander shall evaluate the risk to members with respect to the purpose and potential results of their actions in each situation.'
                        },
                        4: {
                            text: 'Risk management principles shall be routinely employed by supervisory personnel at all levels of the incident management system to define the limits of acceptable and unacceptable positions and functions for all members at the incident scene.'
                        },
                        5: {
                            text: 'At significant incidents and special operations incidents, the incident commander shall assign an incident safety officer who has the expertise to evaluate hazards and provide direction with respect to the overall safety of personnel.'
                        },
                        6: {
                            text: 'At terrorist incidents or other incidents involving potential CBRN exposure, the incident commander shall assess the risk to members and ensure that protective equipment appropriate for the risk is available for and used by members.'
                        },
                        7: {
                            text: 'Fire departments shall conduct a risk assessment to determine the need to provide appropriate chemical antidote kits for members in the event of a CBRN incident.'
                        }
                    },
                    5: {
                        title: 'Personnel Accountability During Emergency Operations',
                        1: {
                            text: 'The fire department shall establish written standard operating procedures for a personnel accountability system that is in accordance with NFPA 1561.'
                        },
                        2: {
                            text: 'The fire department shall consider local conditions and characteristics in establishing the requirements of the personnel accountability system.'
                        },
                        3: {
                            text: 'It shall be the responsibility of all members operating at an emergency incident to actively participate in the personnel accountability system.'
                        },
                        4: {
                            text: 'The incident commander shall maintain an awareness of the location and function of all companies or crews at the scene of the incident.'
                        },
                        5: {
                            text: 'Officers assigned the responsibility for a specific tactical level management component at an incident shall directly supervise and account for the companies and/or crews operating in their specific area of responsibility.'
                        },
                        6: {
                            text: 'Company officers shall maintain an ongoing awareness of the location and condition of all company members.'
                        },
                        7: {
                            text: 'Where assigned as a company, members shall be responsible to remain under the supervision of their assigned company officer.'
                        },
                        8: {
                            text: 'Members shall be responsible for following personnel accountability system procedures.'
                        },
                        9: {
                            text: 'The personnel accountability system shall be used at all incidents.'
                        },
                        10: {
                            text: 'The fire department shall develop, implement, and utilize the system components required to make the personnel accountability system effective.'
                        },
                        11: {
                            text: 'The standard operating procedures shall provide the use of additional accountability officers based on the size, complexity, or needs of the incident.'
                        },
                        12: {
                            text: 'The incident commander and members who are assigned a supervisory responsibility for a tactical level management component that involves multiple companies or crews under their command shall have assigned a member(s) to facilitate the ongoing tracking and accountability of assigned companies and crews.'
                        }
                    },
                    6: {
                        title: 'Members Operating at Emergency Incidents',
                        1: {
                            text: 'The fire department shall provide an adequate number of personnel to safely conduct emergency scene operations.',
                            1: {
                                text: 'Career fire departments shall meet the requirements of 5.2.4 of NFPA 1710.'
                            },
                            2: {
                                text: 'Volunteer fire departments shall meet the requirements of Section 4.3 of NFPA 1720.'
                            },
                            3: {
                                text: 'Operations shall be limited to those that can be safely performed by the personnel available at the scene.'
                            }
                        },
                        2: {
                            text: 'No member or members shall commence or perform any fire-fighting function or evolution that is not within the established safety criteria of the organizational statement as specified in 4.1.1.'
                        },
                        3: {
                            text: 'When inexperienced members are working at an incident, direct supervision shall be provided by more experienced officers or members.',
                            1: {
                                text: 'The requirement of 8.6.3 shall not reduce the training requirements contained in 5.1.3 and 5.1.4.'
                            }
                        },
                        4: {
                            text: 'Members operating in hazardous areas at emergency incidents shall operate in crews of two or more.'
                        },
                        5: {
                            text: 'Crew members operating in hazardous areas shall be in communication with each other through visual, audible, or physical means or safety guide rope, in order to coordinate their activities.'
                        },
                        6: {
                            text: 'Crew members shall be in proximity to each other to provide assistance in case of emergency.'
                        },
                        7: {
                            text: 'In accordance with the requirements of 8.8.2, at least two members shall be present outside the hazardous area available for assistance or rescue at emergency operations where members are operating inside a hazardous area.'
                        },
                        8: {
                            text: 'At aircraft rescue fire-fighting incidents, the initial IDLH shall be identified as the area from wingtip to wingtip for the specific aircraft.',
                            1: {
                                text: 'After size-up, the incident commander shall adjust the IDLH designation as the situation dictates, to meet operational needs.'
                            },
                            2: {
                                text: 'Aircraft rescue fire-fighting operations inside the area identified as the IDLH shall be in accordance with 8.6.4.'
                            }
                        },
                        9: {
                            text: 'When members are operating at a structure fire or performing special operations, the highest available level of emergency medical care shall be standing by at the scene with medical equipment and transportation capabilities. Basic life support (BLS) shall be the minimum level of emergency medical care.',
                            1: {
                                text: 'Basic life support (BLS) shall be the minimum level of emergency medical care.'
                            }
                        },
                        10: {
                            text: 'Emergency medical care and medical monitoring at hazardous materials incidents shall be provided by or supervised by personnel who meet the minimum requirements of NFPA 473.'
                        },
                        11: {
                            text: 'At all emergency operations, except as required in 8.6.9 and 8.6.10, the incident commander shall evaluate the risk to the members operating at the scene and, if necessary, request that at least BLS personnel and patient transportation be available.'
                        },
                        12: {
                            text: 'When members are operating from aerial devices, they shall be secured to the aerial device with a system in compliance with NFPA 1983.'
                        },
                        13: {
                            text: 'The incident commander shall ensure that personnel working in an IDLH atmosphere or hazardous area use applicable protective clothing and SCBA for risks that could be encountered.',
                            1: {
                                text: 'Members performing overhaul operations shall wear applicable protective clothing and a supplied-air respirator.'
                            },
                            2: {
                                text: 'When overhaul is not actively taking place, fire investigators or other members who enter the fire area following extinguishment shall wear protective clothing and a supplied-air respirator or an appropriate air-purifying respirator for respiratory hazards that could be encountered.'
                            }
                        },
                        14: {
                            text: 'Members involved in water rescue shall be issued and wear personal flotation devices that meet U.S. Coast Guard requirements.'
                        },
                        15: {
                            text: 'Fire departments shall develop a standard operating procedure for operating near hazardous energy sources.',
                            1: {
                                text: 'Procedures shall be developed for isolating personnel from the energized conductor.'
                            },
                            2: {
                                text: 'All fire fighters shall be made aware of the increased danger involving downed power lines when working, especially in limited visibility.'
                            },
                            3: {
                                text: 'Fire department personnel shall not be permitted to move or cut electrical meters.'
                            },
                            4: {
                                text: 'Fire fighters shall locate and isolate downed electrical wires and wait for utility company personnel to disconnect the power to those wires.',
                                1: {
                                    text: 'In cases of known immediate life-threatening situations, properly trained and equipped personnel shall be permitted to mitigate the hazard.'
                                }
                            },
                            5: {
                                text: 'Fire fighters shall keep a minimum safe distance from an overhead or downed power line until the line is de-energized and always function under the premise that a line is hot.'
                            },
                            6: {
                                text: 'The incident commander shall convey and continually re-evaluate strategic decisions related to fireground electrical hazards to all personnel on the scene.'
                            },
                            7: {
                                text: 'All fire fighters shall be made aware of the hazards of applying a solid-stream water application around energized electrical conductors.'
                            },
                            8: {
                                text: 'All fire fighters shall be repeatedly trained in safety-related practices for working around electrical energy.'
                            },
                            9: {
                                text: 'Fire departments shall develop a standard operating procedure for operating near rooftop photovoltaic (PV) generators.',
                                1: {
                                    text: 'Procedures shall be developed for isolating personnel from the energized equipment.'
                                },
                                2: {
                                    text: 'All fire fighters shall be made aware of the increased danger involving rooftop PV equipment when working, especially in limited visibility.'
                                },
                                3: {
                                    text: 'Fire department personnel shall notify the incident commander of any PV electrical generators on the premises.'
                                },
                                4: {
                                    text: 'In cases of known immediate life-threatening situations, properly trained and equipped personnel shall be permitted to mitigate the hazard.'
                                },
                                5: {
                                    text: 'Fire fighters shall keep a minimum distance from PV electrical generators until the system is de-energized and shall always function under the premise that the array is hot.'
                                },
                                6: {
                                    text: 'The incident commander shall convey to all personnel on the scene and continually re-evaluate strategic decisions related to fireground electrical hazards involving PV systems.'
                                },
                                7: {
                                    text: 'All fire fighters shall be made aware of the hazards of applying a solid-stream water application around energized PV systems.'
                                },
                                8: {
                                    text: 'All fire fighters shall be repeatedly trained in safety-related practices for working around PV systems.'
                                }
                            }
                        }
                    },
                    7: {
                        title: 'Hazard Control Zones',
                        1: {
                            text: 'Hazard control zones shall be established at every emergency incident to identify the level of risk to emergency responders and the appropriate level of PPE.'
                        },
                        2: {
                            text: 'The perimeters of the hazard control zones shall be designated by the incident commander.'
                        },
                        3: {
                            text: 'If the perimeters change during the course of the incident, these changes shall be communicated to all members on the scene.'
                        },
                        4: {
                            text: 'Hazard control zones shall be as follows:\n(1) Designated as no-entry, hot, warm, and cold (similar to hazardous materials incidents)\n(2) Marked with the applicable colored hazard tape, signage, or other appropriate means wherever possible\n(3) Communicated to all personnel attending the incident prior to being assigned to a hazard zone',
                            1: {
                                text: 'Hot zone (red tape) is the area presenting the greatest risks to members, will often be classified as an IDLH atmosphere, and presents the highest risk of human injury and/or exposure; therefore all members shall wear all of the PPE appropriate for the risks that might be encountered while in the hot zone.',
                                1: {
                                    text: 'All members operating within the hot zone shall have an assigned task.'
                                }
                            },
                            2: {
                                text: 'Warm zone (yellow tape) shall serve as a limited access area for members directly aiding or in support of operations in the hot zone where significant risk of human injury can still exist.'
                            },
                            3: {
                                text: 'Cold zone (green tape) shall establish the public exclusion or clean zone where there are minimal risks for human injury or exposure, or both, in this zone.'
                            },
                            4: {
                                text: 'No-entry zone (red/white chevron tape) is the area at an incident scene that no person(s) shall be permitted to enter due to imminent hazard(s), dangerous conditions, or the need to protect evidence.',
                                1: {
                                    text: 'Where a no-entry zone is designated, no personnel shall enter regardless of PPE.'
                                },
                                2: {
                                    text: 'Where red/white chevron tape is not readily available, no-entry zones shall be marked using three horizontal strands of yellow barrier tape, spaced 18 to 24 in. (46 to 61 cm) apart and securely fixed to stationary supports.'
                                },
                                3: {
                                    text: 'No-entry zones shall be illuminated to enhance nighttime visibility.'
                                },
                                4: {
                                    text: 'Where the threat of a building collapse exists, a collapse zone shall be established.',
                                    1: {
                                        text: 'A collapse zone shall be a No Entry Zone.'
                                    },
                                    2: {
                                        text: 'Collapse zones shall be established around the perimeter of the building at a distance that is equal to a minimum of 1.5 times the height of the building.'
                                    }
                                }
                            }
                        },
                        5: {
                            text: 'The incident commander shall ensure that the designation of the appropriate protective clothing and equipment is commensurate with the hazard in the zone the member will be operating in.'
                        },
                        6: {
                            text: 'All officers and members shall ensure the appropriate use of personnel protective equipment within that zone.'
                        },
                        7: {
                            text: 'The process of utilizing hazard control zones shall continue until the incident hazards have been mitigated or the incident is over.'
                        }
                    },
                    8: {
                        title: 'Rapid Intervention for Rescue of Members',
                        1: {
                            text: 'The fire department shall provide personnel for the rescue of members operating at emergency incidents.',
                            1: {
                                text: 'Personnel assigned to perform the function of the initial rapid intervention crew (IRIC) or the rapid intervention crew (RIC) shall be trained on the requirements of NFPA 1407.'
                            }
                        },
                        2: {
                            text: 'In the initial stages of an incident where only one crew is operating in the hazardous area at a working structural fire, a minimum of four individuals shall be required, consisting of two members working as a crew in the hazardous area and two standby members present outside this hazardous area available for assistance or rescue at emergency operations where entry into the danger area is required.',
                            1: {
                                text: 'The “initial stages” of an incident shall encompass the tasks undertaken by the first arriving company with only one crew assigned or operating in the hazardous area.'
                            },
                            2: {
                                text: 'The standby members shall be responsible for maintaining a constant awareness of the number and identity of members operating in the hazardous area, their location and function, and time of entry.'
                            },
                            3: {
                                text: 'The standby members shall remain in radio, visual, voice, or signal line communication with the crew.'
                            },
                            4: {
                                text: 'One standby member shall be permitted to perform other duties outside of the hazardous area, such as apparatus operator, incident commander, or technician or aide, provided constant communication is maintained between the standby member and the members of the crew.'
                            },
                            5: {
                                text: 'The assignment of any personnel, including the incident commander, the safety officer, or operators of fire apparatus, shall not be permitted as standby personnel if by abandoning their critical task(s) to assist or, if necessary, perform rescue, they clearly jeopardize the safety and health of any fire fighter working at the incident.',
                                1: {
                                    text: 'No one shall be permitted to serve as a standby member of the fire-fighting crew when the other activities in which the fire fighter is engaged inhibit the fire fighter\'s ability to assist in or perform rescue, if necessary, or are of such importance that they cannot be abandoned without placing other fire fighters in danger.'
                                }
                            },
                            6: {
                                text: 'The standby member shall be provided with full protective clothing, protective equipment, and SCBA appropriate for the risk that might be encountered.',
                                1: {
                                    text: 'The full protective clothing, protective equipment, and SCBA shall be immediately accessible for use by the outside crew if the need for rescue activities inside the hazardous area occurs.'
                                }
                            },
                            7: {
                                text: 'The standby members shall don full protective clothing, protective equipment, and SCBA prior to entering the hazardous area.'
                            },
                            8: {
                                text: 'When only a single crew is operating in the hazardous area in the initial stages of the incident, this standby member shall be permitted to assist with, or if necessary perform, rescue for members of his or her crew, provided that abandoning his or her task does not jeopardize the safety or health of the crew.'
                            },
                            9: {
                                text: 'Once a second crew is assigned or operating in the hazardous area, the incident shall no longer be considered in the “initial stage,” and at least one rapid intervention crew shall be deployed that complies with the requirements of 8.8.2.'
                            },
                            10: {
                                text: 'Initial attack operations shall be organized to ensure that if, on arrival at the emergency scene, initial attack personnel find an imminent life-threatening situation where immediate action could prevent the loss of life or serious injury, such action shall be permitted with less than four personnel.',
                                1: {
                                    text: 'No exception as permitted in 8.8.2.10 shall be allowed when there is no possibility to save lives.'
                                },
                                2: {
                                    text: 'Any such actions taken in accordance with 8.8.2.10 shall be thoroughly investigated by the fire department with a written report submitted to the fire chief.'
                                }
                            }
                        },
                        3: {
                            text: 'As the incident expands in size or complexity, which includes an incident commander\'s requests for additional resources beyond a fire department\'s initial attack assignment, the dedicated rapid intervention crew (RIC) shall on arrival of these additional resources be either one of the following:\n(1) On-scene members designated and dedicated as an RIC\n(2) On-scene crew/company or crews/companies located for rapid deployment and dedicated as RICs',
                            1: {
                                text: 'During fire fighter rescue operations each crew/company shall remain intact.'
                            }
                        },
                        4: {
                            text: 'An RIC shall consist of at least two members and shall be available for immediate rescue of a member or a crew.',
                            1: {
                                text: 'Each RIC shall be fully equipped with protective clothing, protective equipment, SCBA, and any specialized rescue equipment that could be needed given the specifics of the operation under way.'
                            }
                        },
                        5: {
                            text: 'At incidents where any SCBA being used is equipped with an RIC universal air connection (UAC), the RIC shall have the specialized rescue equipment necessary to complete the RIC UAC connection to a supplied air source.',
                            1: {
                                text: 'Where applicable, the breathing air source and any hoses and connections shall meet the requirements of NFPA 1981 and be NIOSH certified to 42 CFR 84, "Approval of Respiratory Protective Devices."'
                            },
                            2: {
                                text: 'The breathing air source shall have no less than 1200 L of breathing air before entering the hazard area.'
                            }
                        },
                        6: {
                            text: 'The composition and structure of a RIC shall be permitted to be flexible based on the type of incident and the size and complexity of operations.'
                        },
                        7: {
                            text: 'The incident commander shall evaluate the situation and the risks to operating crews and shall provide one or more RICs commensurate with the needs of the situation.'
                        },
                        8: {
                            text: 'In the early stages of an incident, which includes the deployment of a fire department\'s initial attack assignment, the RIC shall be in compliance with 8.2.5.1 and 8.8.2.5 and be either one of the following:\n(1) On-scene members designated and dedicated as an RIC\n(2) On-scene members performing other functions but ready to redeploy to perform RIC functions'
                        },
                        9: {
                            text: 'The assignment of any personnel shall not be permitted as members of the RIC if abandoning their critical task(s) to perform rescue operations clearly jeopardizes the safety and health of any member operating at the incident.'
                        },
                        10: {
                            text: 'During fire fighter rescue operations each crew/company shall remain intact.'
                        },
                        11: {
                            text: 'At least one dedicated RIC shall be standing by with equipment to provide for the rescue of members that are performing special operations or for members that are in positions that present an immediate danger of injury in the event of equipment failure or collapse.'
                        }
                    },
                    9: {
                        title: 'Rehabilitation During Emergency Operations',
                        1: {
                            text: 'Personnel shall undergo rehabilitation in accordance with NFPA 1584.',
                            1: {
                                text: 'Personnel shall not be permitted to use more than two SCBA cylinders before they are sent to rehabilitation operations.'
                            }
                        }
                    }
                }
            }
        }
    },
    1521: {
        title: 'Standard for Fire Department Safety Officer Professional Qualifications',
        editions: {
            2020: {
                5: {
                    title: 'Incident Safety Officer',
                    1: {
                        title: 'General',
                        text: 'The fire department incident safety officer (ISO) shall meet the requirements of Fire Officer Level I specified in NFPA 1021, and the job performance requirements (JPRs) defined in Sections 5.2 through 5.7.',
                        2: {
                            text: 'A fire department ISO shall recuse himself/herself from any investigatory process where a conflict of interest exists.'
                        }
                    },
                    2: {
                        title: 'General Requirements',
                        1: {
                            text: 'Perform the role of ISO within an incident command system (ICS) at an incident or planned event, given an incident or planned event, an ICS structure, a command post, a briefing from an incident commander (IC) or outgoing ISO, SOP related to health and safety, an incident action plan (IAP), applicable protective clothing and protective equipment, and communications and information recording equipment, so that the assignment is received and understood; situational information about the incident or planned event is received; incident priorities, goals, and objectives are transferred; action is taken to mitigate any immediate life safety threats; and applicable communication means are employed.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Understand accepted safety and health principles, including issues such as the hierarchy of controls, specific technical or regulatory areas pertinent to the response, and the accepted management principles needed to promote safety in the response environment.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Prioritizing tasks, making decisions in an environment with a large number of unknowns, evaluating resource needs, recognizing the need for supplemental technical knowledge, and taking action in a proactive manner to ensure responder safety and health.'
                                }
                            ]
                        },
                        2: {
                            text: 'Monitor the IAP, conditions, activities, and operations, given an incident or planned event, an IAP, and risk management assessment criteria, so that activities and operations that involve an unacceptable level of risk can be altered, terminated, or suspended to protect members’ health and safety.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Comprehensive knowledge of incident hazards, applicable legislation, regulations, codes, and standards, the incident management system (IMS), recognized safety practices, risk management criteria, including what constitutes unacceptable level of risk; and fire department operations, training materials, and SOP/Gs.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Ability to apply knowledge of fire behavior and fire dynamics, building construction, department SOP/Gs, training materials, and applicable safety practices in a risk management assessment to determine the most appropriate actions to minimize health and safety risks.'
                                }
                            ]
                        },
                        3: {
                            text: 'Manage the transfer of ISO duties, given an incident or planned event, an established command structure and ISO, an IAP, an incident safety plan, a current situation status, incident resources, a command post, incident documentation, and communications equipment, so that incident information is exchanged, reports and plans for the subsequent operational period are completed, continuity of authority and situational awareness are maintained, changes in incident or planned event complexity are accounted for, the new ISO is briefed on the incident or planned event, and the new ISO is identified.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'AHJ’s procedures for transfer of duty; information sources; resource accountability and tracking process; use of IMS forms; the role and duties of an ISO within an IMS; organizational policies and procedures for safety; accountability protocols; resource types and deployment methods; documentation methods and requirements; availability, capabilities, and limitations of responders and other resources; communication problems and needs; communications requirements; operational periods for ISO functions; and types of tasks and assignment responsibilities.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Conducting a transfer briefing meeting; acquiring and documenting information and orders from the IC; using reference materials; evaluating incident information; managing communications; completing required ICS and health and safety forms; recognizing the need to expand and/or transfer the safety function in the ICS structure; reviewing, understanding, and conducting a transfer of duty briefing, including the completion of the transfer documents; and communicating in a manner such that information is transferred and objectives are met.'
                                }
                            ]
                        },
                        4: {
                            text: 'Stop, alter, or suspend operations based on imminent threats posed to firefighter safety, given an incident or planned event that contains threats to firefighter safety, an incident management structure, risk management criteria, and applicable SOP/Gs, so that the hazard is identified, notice to suspend operations is communicated, action is taken to protect firefighter safety, and this information is communicated to the IC.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Knowledge of what constitutes imminent hazards at an incident or planned event that could impact firefighter safety, IMS, radio protocols and transmission procedures, fire behavior/dynamics, hazardous energy, reading smoke, building construction, and departmental SOP/Gs and training materials.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Ability to evaluate hazards; determine the relative degree of risk to members and whether they pose an imminent threat to firefighter safety; use of department radios and communication abilities.'
                                }
                            ]
                        },
                        5: {
                            text: 'Monitor and determine the incident scene conditions, given an incident or planned event, so that the ISO can report to the IC on the status of hazards and risks to members.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Knowledge of what constitutes hazards at an emergency incident, the IMS, radio protocols and transmission procedures, incident hazards, and departmental SOP/Gs.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Ability to evaluate hazards, determine the relative degree of risk to members, prioritize the risks, and communicate this information to the IC.'
                                }
                            ]
                        },
                        6: {
                            text: 'Monitor the accountability system, given an incident or planned event, an IMS, personal identification devices, radios, and applicable SOP/Gs, so that it can be determined that the accountability system is being utilized as designed, all relevant positions and functions are implemented, and any noted deficiencies are communicated to the IC.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Knowledge of incident management system, department accountability system positions and protocols, radio protocols and transmission procedures, and departmental SOP/Gs.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Ability to recognize inadequacies in the use of the accountability system.'
                                }
                            ]
                        },
                        7: {
                            text: 'Determine hazardous incident conditions and advise the IC to establish or modify control zones, given an incident, so that the incident control zones are communicated to members and entry into the hazardous area is controlled.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Comprehensive knowledge of hazardous conditions, operations, departmental SOP/Gs and training materials, control zones protocols, and the IMS.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Ability to evaluate the effect of proximity for incident hazards so that risk to members will be limited to emergency responders assigned tasks to mitigate the incident.'
                                }
                            ]
                        },
                        8: {
                            text: 'Identify motor vehicle incident scene hazards, given an apparatus and temporary traffic control devices, an incident or planned event, so that actions to mitigate the hazards as described in Section 8.7 of NFPA 1500 are taken to protect member safety.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Knowledge of hazards associated with vehicle incidents and apparatus placement, the IMS, departmental SOP/Gs and training materials, state/provincial and local traffic regulations, risk management principles and criteria, and applicable safety principles and practices.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Ability to apply knowledge of hazards and regulations to an incident within a risk management framework to protect member safety.'
                                }
                            ]
                        },
                        9: {
                            text: 'Monitor radio transmissions; given an incident or planned event with radio transmissions, so that communication barriers are identified and the possibility for missed, unclear, or incomplete communications is corrected.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Knowledge of radio protocols and transmission procedures, the IMS, emergency incident hazards, and departmental SOP/Gs.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Ability to recognize missed, unclear, or incomplete communications.'
                                }
                            ]
                        },
                        10: {
                            text: 'Identify the incident strategic requirements (e.g., fire, technical search and rescue, hazmat), the corresponding hazards, the size, complexity, and anticipated duration of the incident, including the associated risks, given an incident or planned event, an IMS, and applicable SOP/Gs, so that the ISO can determine the need for assistant ISOs and/or technical specialists and make the recommendations to the IC.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Comprehensive knowledge of incident hazards; applicable legislation, regulations, codes, and standards; the IMS; recognized safety practices; risk management criteria, including what constitutes unacceptable level of risk; and fire department operations, training materials, and SOP/Gs.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Ability to recognize the types of hazards that might require additional ISOs or technical specialists, and applicable safety practices.'
                                }
                            ]
                        },
                        11: {
                            text: 'Determine the hazards associated with the designation of a landing zone and interface with helicopters, given an incident or planned event that requires the use of a helicopter and landing zone, so that the IC can be informed of special requirements and the landing can be executed in a safe manner.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Helicopter and landing zone requirements; hazards associated with helicopters and landing zones; safety issues associated with landing zones; and the IMS.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Ability to recognize landing zone locations and hazards.'
                                }
                            ]
                        },
                        12: {
                            text: 'Notify the IC of the need for intervention resulting from an occupational exposure to atypical stressful events, given an incident or planned event and an awareness of incidents that can cause incident stress, so that members’ psychological health and safety can be protected.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Knowledge of incidents that can lead to occupational exposure to atypical stress, the signs and symptoms of occupational exposure to atypical stress, the difference between debriefing and defusing, and support teams and other resources to provide assistance.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Ability to recognize signs and symptoms of occupational exposure to atypical stress; an accepting and empathetic demeanor; and good communication skills.'
                                }
                            ]
                        },
                        13: {
                            text: 'Determine hazardous energy sources that can affect responder health and safety, given an incident or planned event, an active IAP with assigned responders, and an opportunity to perform environmental and operational reconnaissance, so that risks to personnel are identified, reduced, or eliminated.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Common component assemblies for hazardous energy sources, including but not limited to gas, electrical, water, and pressure vessels; hazardous properties of common utility gases; common electrical distribution grid components and arrangements; and control zone marking schemes as defined by 8.6.2 of NFPA 1500.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Critical identification, analysis, and judgment abilities; prioritizing to address hazards on a most critical-first basis; communicating hazard information to personnel via the incident safety plan, IAP, face-to-face, radio, and safety briefings; determining boundaries and markings for control zones.'
                                }
                            ]
                        },
                        14: {
                            text: 'Monitor conditions, including weather, fire fighter activities, and work cycle durations, given an incident or planned event, so that the need for rehabilitation can be determined, communicated to the IC, and implemented to ensure fire fighter health and safety.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Comprehensive knowledge of heat and cold assessment criteria, rehabilitation strategies, including NFPA 1584, SOP/Gs and training materials; available resources that can be used for rehabilitation, signs and symptoms of cardiac stress, and heat and cold stress.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Ability to recognize signs of cardiac, heat, and cold stress; set up a rehab area and ensure that members use it as designed.'
                                }
                            ]
                        },
                        15: {
                            text: 'Identify incident environmental conditions and contaminants, given an incident or planned event, so that identified hazards can be communicated to the IC and division and/or group supervisors, and the need for contamination control procedures for PPE, personnel hygiene, and utilized equipment can be determined and implemented, prior to incident departure, to help prevent continued exposure and cross contamination from known and potential contaminants.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Common byproducts of combustion and pyrolysis including toxic chemicals, biological pathogens, particulate matter, and aromatics; NFPA 1851; AHJ SOP/Gs for on-scene PPE contamination control and cancer prevention; methods and levels of equipment cleaning as prescribed by equipment manufacturers.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Ability to evaluate fire, smoke, and environmental conditions, determine member exposures to those conditions, and communicate contamination judgments to the IC and tactical work members.'
                                }
                            ]
                        }
                    },
                    3: {
                        title: 'Fire Suppression Operations',
                        1: {
                            text: 'Determine incident environmental and operational factors and confirm the establishment of rapid intervention crew (RIC) and evaluate the need to increase RIC capability, given an incident or planned event that includes one or more immediately dangerous to life and health (IDLH) elements, responders engaged in tactical operations, a pre-assigned RIC, and an IAP, so that a recommendation is offered to the IC.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'RIC criteria for NFPA 1500, NFPA 1561, NFPA 1710, NFPA 1720, AHJ SOP/Gs, and directives for RIC establishment and use.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Interpret applicable regulations, guidelines, procedures, and consensus standards for implementation at incidents; audit conditions to ensure policies are being followed; and formulate recommendations for incident command action.'
                                }
                            ]
                        },
                        2: {
                            text: 'Communicate fire behavior, building access/egress issues, collapse, and hazardous energy issues to established RICs, given an incident or planned event, so that RIC team leaders are aware of the observations and concerns of the ISO.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Structural/compartmentalized fire behavior, building construction features and associated hazards, and hazardous energy properties and components.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Ability to interpret fire suppression hazards and operations and communicate through face-to-face and radio methods.'
                                }
                            ]
                        },
                        3: {
                            text: 'Identify and estimate building/structural collapse hazards, given a building fire incident, a building collapse incident, reconnaissance opportunity, and established AHJ pre-incident building plan information, so that the identified collapse hazard can be communicated to the IC and tactical-level management units; judgment is offered to the IC for the establishment of control zone(s); personnel are removed from collapse zone dangers; and appropriate adjustments are made to the IAP by the IC to improve member safety.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Building construction classifications and associated hazards; structural fire collapse indicators; building fire spread; fire effects on building materials, loads, and forces; structural conditions that warrant stopping, altering, or suspending incident or planned event operations; procedures for managing unsafe acts or operations and procedures for notifying command of stopped, altered, or suspended operations; methods for determining collapse zone distances; and AHJ pre-incident target building hazards.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Critical identification, analysis, and judgment abilities; applying AHJ building fire preplan systems at actual incidents; interpreting collapse hazards; communicating hazard information to personnel via the incident safety plan, IAP, face-to-face, radio, and safety briefings; determining boundaries and markings for control zones; formulating recommendations for incident command action; exercising authority to suspend imminent danger operations; and anticipating evolving site conditions that require IAP changes.'
                                }
                            ]
                        },
                        4: {
                            text: 'Determine flashover and hostile fire event potential at building fires, given an incident, so that risks are identified and communicated to the incident commander and tactical-level management units, and adjustments are made to strategy and tactics to improve safety.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Compartmentalized fire behavior theory, flashover and other hostile fire incident indicators, ventilation flow path, fire-load (fuel) characteristics, effects of fire-fighting efforts on fire behavior.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Critical identification, analysis, and judgment abilities; reading smoke (volume, velocity, density, and color); and communicating fire behavior concerns through face-to-face and radio methods.'
                                }
                            ]
                        },
                        5: {
                            text: 'Determine fire growth and blow up, given wildland and cultivated vegetation fires, so that information can be communicated to the IC and tactical-level management components, and adjustments made to the IAP to improve member safety.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Wildland and vegetation fire behavior and wildland fire phenomena such as blow ups and flaring.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Critical identification, analysis, and judgment abilities; interpreting fuel, topography, flame length, and weather effects on wildland and vegetation fires; and communicating fire behavior concerns through face-to-face and radio methods.'
                                }
                            ]
                        },
                        6: {
                            text: 'Determine the suitability of building entry and egress options at building fires, given various building fire incidents, so that entry and egress options are optimized through communication with the IC and tactical-level management components.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Building construction access and egress challenges; AHJ building pre-fire systems; fire-fighting equipment capabilities, and AHJ fire-fighting resource capabilities.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Critical identification, analysis and judgment abilities; and communicating access and egress concerns through face-to-face and radio methods.'
                                }
                            ]
                        }
                    },
                    4: {
                        title: 'Technical Search and Rescue Operations',
                        1: {
                            text: 'Determine the need for a search and rescue technician-trained ISO or assistant ISO, given a technical search and rescue incident; CFR 1910.146; NFPA 1006; and AHJ SOP/Gs for technical search and rescue operations, so that the IC can appoint an assistant ISO or a search and rescue technician.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Technical search and rescue incident types as defined in NFPA 1006 and AHJ SOP/Gs for technical search and rescue operations.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Identifying technical search and rescue incident resource needs and forecasting stabilization strategies.'
                                }
                            ]
                        },
                        2: {
                            text: 'Prepare a safety plan that identifies corrective or preventive actions, given a technical search and rescue incident, an IAP that includes situation and resource status information, an incident safety analysis form (ICS form 215A or its equivalent), weather condition information, special technical data (such as safety data sheets and topographical information, blueprints, and building drawings), and predetermined incident information, so that safety data are obtained, an incident safety plan is developed with coordinating documentation, elements of the plan are incorporated in the IAP, changes in incident safety conditions are noted and reported, judgment is offered to the IC for the establishment of control zone(s) and exclusion zone(s), safety and appropriate PPE elements are met, and assistant ISOs are appointed as necessary.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Risk management principles; technical search and rescue operations strategies and tactics; hazard mitigation and countermeasure strategies; NIMS IAP and planning processes; NIMS documentation system; NFPA 1951; 29 CFR 1910.146; and AHJ SOP/Gs for hazardous materials operations.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Critical identification, analysis, and judgment abilities; communicating safety issues within the command structure; and reading/editing technical documentation.'
                                }
                            ]
                        },
                        3: {
                            text: 'Deliver a safety briefing for technical search and rescue incident response members, given a technical search and rescue incident, so that critical information such as expected hazards, PPE requirements, established zones, emergency procedures, air monitoring, medical surveillance, and chain-of-command elements are communicated.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'OSHA 29 CFR 1910.146 requirements for a site safety and health plan; NIMS forms and ICS processing criteria; general technical search and rescue operations safety strategies; and AHJ technical search and rescue SOP/Gs.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Ability to communicate critical messages in written and oral formats.'
                                }
                            ]
                        }
                    },
                    5: {
                        title: 'Hazardous Materials Operations',
                        1: {
                            text: 'Determine the need for a hazardous materials technician-trained ISO or assistant ISO, given a hazardous materials incident, 29 CFR 1910.120, NFPA 472, and NFPA 1072; and AHJ SOP/Gs for hazardous materials operations, so that the IC can appoint an assistant ISO or a hazardous materials technician.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Hazardous materials incident types as defined in NFPA 472 and NFPA 1072, and AHJ SOP/Gs for hazardous materials operations.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Identifying hazardous materials incident resource needs; forecasting stabilization strategies.'
                                }
                            ]
                        },
                        2: {
                            text: 'Prepare a safety plan that identifies corrective or preventive actions, given a hazmat incident, IAP that includes situation and resource status information, an incident safety analysis form (ICS form 215A or its equivalent), weather condition information, special technical data (such as safety data sheets and topographical information, blueprints, and building drawings), and predetermined incident information, so that safety data are obtained, an incident safety plan is developed with coordinating documentation, elements of the plan are incorporated in the IAP, changes in incident safety conditions are noted and reported, judgment is offered to the IC for the establishment of control zone(s) and exclusion zone(s), safety and PPE elements of 29 CFR 1910.120 are met, and assistant ISOs are appointed as necessary.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Risk management principles; hazardous materials operations strategies and tactics; hazard mitigation and countermeasure strategies; NIMS IAP and planning processes; NIMS documentation system; and AHJ SOPs/Gs for hazardous materials operations.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Critical identification, analysis, and judgment abilities; communicating safety issues within the command structure; and reading/editing technical documentation.'
                                }
                            ]
                        },
                        3: {
                            text: 'Deliver a safety briefing for hazardous materials incident response members, given a hazmat incident or scenario, so that critical information such as expected hazards, PPE requirements, established zones, decontamination procedures, emergency procedures, air monitoring, medical surveillance, and chain-of-command elements are communicated.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'OSHA 29 CFR 1910.120 requirements for a site safety and health plan; NIMS forms and ICS processing criteria; general hazmat operations safety strategies; and AHJ hazmat SOPs/Gs.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Ability to communicate critical messages in written and oral formats.'
                                }
                            ]
                        },
                        4: {
                            text: 'Identify that hazardous materials incident control zones have been established and communicated to personnel on the scene, given a hazardous materials incident and SOP/Gs, so that responders can identify marked control zones, which must be inclusive of no-entry zones, hot zones, hazard reduction zones, support zones, and corridors.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Common zoning strategies for hazardous materials operations, methods of marking zones, and AHJ SOP/Gs for zone communication; NFPA 472 and NFPA 1072; and other applicable NFPA documents.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Ability to adapt zoning strategies to individual incident challenges such as topography, weather, and resource variants.'
                                }
                            ]
                        }
                    },
                    6: {
                        title: 'Accident Investigations and Review',
                        1: {
                            text: 'Conduct a safety and health investigative process, given an incident or planned event, using applicable documents and techniques, so that the chain of evidence is started and maintained, critical incident data elements are collected, potential witnesses are identified, applicable SOP/Gs are identified for review, and gathered information is documented and prepared for the HSO or investigative continuance as established by the AHJ policies and SOP/Gs.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Procedures for conducting, documenting, recording, and reporting a safety investigation, SOP/Gs and health and safety investigative policies used by the AHJ; procedures for preserving evidence and documentation; and the technical knowledge pertinent to the incident under investigation.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Analyzing information from different data sources; identifying equipment and materials that might be considered evidence; interacting with or interviewing personnel associated with the incident, often under conditions of personal stress; completing safety investigation documentation; identifying cause(s) of injury, death, or property damage; and determining corrections to prevent similar losses in the future.'
                                }
                            ]
                        }
                    },
                    7: {
                        title: 'Post-Incident Analysis (PIA)',
                        1: {
                            text: 'Prepare a written post-incident analysis (PIA) from the ISO perspective, given a witnessed incident, exercise, or planned event, so that safety and health issues, best safety practices, deviations from SOP/Gs established by the AHJ, and recommendations for future events are documented.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'NFPA 1500, PIA reporting criteria, and AHJ SOP/Gs for PIAs.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Transferring incident observations into field notes and documenting field notes into a formal PIA structure.'
                                }
                            ]
                        },
                        2: {
                            text: 'Report observations, concerns, and recommendations, given a witnessed incident or planned event and PIA group setting, so that that safety and health issues, best safety practices, deviations from SOP/Gs established by the AHJ, and recommendations for future events are communicated to the AHJ.',
                            other: [
                                {
                                    title: '(A) Requisite Knowledge',
                                    text: 'Group dynamics in problem solving.'
                                },
                                {
                                    title: '(B) Requisite Skills',
                                    text: 'Active listening skills; and composing and relaying constructive information in a group setting.'
                                }
                            ]
                        }
                    }
                }
            }
        }
    },
    1620: {
        title: 'Standard for Pre-Incident Planning',
        editions: {
            2020: {

            }
        }
    },
    1962: {
        title: 'Standard for the Care, Use, Inspection, Service Testing, and Replacement of Fire Hose, Couplings, Nozzles, and Fire Hose Appliances',
        editions: {
            2018: {
                4: {
                    title: 'Care, Use, Inspection, Service Testing, and Replacement of Fire Hose',
                    1: {
                        title: 'Attack Hose, Supply Hose, and Forestry Hose',
                        1: {
                            text: 'Hose shall be inspected in accordance with Section 4.5 when it is placed in service.'
                        },
                        2: {
                            text: 'Hose that is in service shall be service tested as specified in Section 4.8 at least annually.'
                        },
                        3: {
                            text: 'Hose shall be service tested in accordance with Section 4.8 the later of 1 year after its date of manufacture or before it is placed in service for the first time.'
                        },
                        4: {
                            text: 'Hose held in storage for longer than 1 year shall be service tested in accordance with Section 4.8 before it is placed in service.'
                        },
                        5: {
                            text: 'Only clean, dry hose shall be placed in service.'
                        },
                        6: {
                            text: 'Hose carried on fire apparatus shall be loaded in such a way that air can circulate under the hose load to eliminate or reduce the growth of mildew in the hose jackets and rust and corrosion in the hose compartment.'
                        },
                        7: {
                            text: 'Hose shall be removed from the apparatus and reloaded so that the folds occur at different positions with sufficient frequency to prevent damage and the setting of permanent folds in the rubber lining.'
                        },
                        8: {
                            text: 'Large-diameter hose used to supply a pump from a hydrant shall be protected from chafing with chafing blocks or similar protection where it comes in contact with pavement or curbing.'
                        },
                        9: {
                            text: 'When connecting a pump to a hydrant, the hose shall be bent slightly to avoid kinks when the water is turned on.'
                        },
                        10: {
                            title: 'Supply Hose',
                            1: {
                                text: 'Hose marked SUPPLY HOSE shall not be used at operating pressures exceeding 185 psi (12.8 bar or 1275 kPa).'
                            },
                            2: {
                                title: 'Discharge Relief Devices',
                                1: {
                                    text: 'A relief or pressure control device shall be used on the discharge side of the pump when pumping into supply hose.'
                                },
                                2: {
                                    text: 'The relief device shall be set so that the discharge pressure does not exceed the service test pressure of the hose being used.'
                                },
                                3: {
                                    text: 'The relief or pressure control device shall be capable of controlling the discharge pressure below the service test pressure as specified in 4.8.2.2.'
                                }
                            },
                            3: {
                                text: 'Only slow-operating valves shall be used with supply hose.'
                            },
                            4: {
                                title: 'Relay Operations',
                                1: {
                                    text: 'Where supply hose is used in relay operations between pumps on fire department apparatus, the intake of each receiving pump shall be equipped with a relief valve.'
                                },
                                2: {
                                    text: 'The maximum pressure setting of the relief valve(s) shall be not more than 10 psi (0.7 bar or 69 kPa) over the static pressure of the water source to which it is connected or not more than 10 psi (0.7 bar or 69 kPa) over the discharge pressure of the supply pump in the relay.'
                                },
                                3: {
                                    text: 'In no event shall the relief valve be set to relieve at a pressure that exceeds 90 percent of the service test pressure of the hose used with the system.'
                                }
                            },
                            11: {
                                title: 'Damage Prevention',
                                1: {
                                    text: 'Hose, while in use, shall be positioned to minimize mechanical damage and heat exposure.'
                                },
                                2: {
                                    text: 'Vehicles shall not be driven over charged or uncharged fire hose unless the hose is bridged and the vehicle has sufficient ground clearance to cross the bridged hose.'
                                },
                                3: {
                                    text: 'Nozzles and valves shall be opened and closed slowly to prevent pressure surges and water hammer that can burst the hose and in turn cause injury to people or damage to the pump.'
                                },
                                4: {
                                    text: 'Care shall be taken to prevent the hose from chafing.'
                                },
                                5: {
                                    text: 'Care shall be taken to avoid dragging large-diameter fire hose, but if the hose must be dragged, it shall be dragged when flat.'
                                },
                                6: {
                                    text: 'When hose is in use during subfreezing weather, care shall be taken to prevent water from freezing inside the hose.',
                                    1: {
                                        text: 'To help prevent freezing once the water is turned on, some water shall be left running through the hose.'
                                    },
                                    2: {
                                        text: 'When the hose line is no longer needed, it shall be uncoupled and drained before the water freezes.'
                                    }
                                }
                            },
                            12: {
                                text: 'Hose that has frozen during use shall be thawed and service tested as specified in Section 4.8 before being put back in service or in storage.'
                            },
                            13: {
                                text: 'After each use and before being placed in storage or back in service, the hose shall be drained, cleaned, dried, and inspected as specified in Sections 4.5 and 4.6.'
                            }
                        }
                    },
                    2: {
                        title: 'Occupant-Use Hose',
                        1: {
                            text: 'Occupant-use hose shall be inspected in accordance with Section 4.5 when it is placed in service.'
                        },
                        2: {
                            text: 'In-service hose designed for occupant use only shall be removed and service tested as specified in Section 4.8 at intervals not exceeding 5 years after the date of manufacture and every 3 years thereafter.'
                        },
                        3: {
                            text: 'When hose is taken out of service for testing, replacement hose shall be installed on the rack, on the reel, or in the storage area until the tested hose is returned to service.'
                        },
                        4: {
                            text: 'In-service hose shall be unracked, unreeled, or unrolled and physically inspected as specified in Section 4.5 at least annually. The hose shall be reracked, rereeled, or rerolled so that any folds do not occur at the same position on the hose.'
                        },
                        5: {
                            title: 'Damage Prevention',
                            1: {
                                text: 'Hose stored on racks or reels shall be protected from the weather and any local environmental condition potentially harmful to the hose.'
                            },
                            2: {
                                text: 'Hose shall be protected from mechanical damage and exposure to heat.'
                            },
                            3: {
                                text: 'Enclosures for occupant-use hose shall be constructed and the hose stored in accordance with NFPA 24.'
                            }
                        },
                        6: {
                            text: 'In areas where rodents can pose a problem, the hose shall be visually inspected more frequently for rodent damage.'
                        },
                        7: {
                            text: 'After each use and before being placed back in service, the hose shall be inspected as specified in Section 4.5, service tested as specified in Section 4.8, and cleaned and dried as specified in Section 4.6.'
                        }
                    },
                    3: {
                        title: 'Booster Hose',
                        1: {
                            text: 'Booster hose shall be inspected in accordance with Section 4.5 when it is placed in service.'
                        },
                        2: {
                            text: 'Booster hose that is in service shall be service tested as specified in Section 4.9 at least annually.'
                        },
                        3: {
                            text: 'Booster hose shall be service tested in accordance with Section 4.9 the later of 1 year after its date of manufacture or before it is placed in service for the first time.'
                        },
                        4: {
                            text: 'Booster hose held in storage for longer than 1 year shall be service tested in accordance with Section 4.9 before it is placed in service.'
                        },
                        5: {
                            text: 'Hose shall be stored out of direct sunlight and as recommended by the manufacturer.'
                        },
                        6: {
                            text: 'Hose shall not be stored kinked and, if stored on a reel, care shall be taken to avoid twisting the hose when rolling it onto the reel.'
                        },
                        7: {
                            text: 'Covered hose that has exposed reinforcement either shall be removed from service, repaired, and service tested or shall be condemned.'
                        }
                    },
                    4: {
                        title: 'Suction Hose',
                        1: {
                            text: 'Suction hose shall be inspected in accordance with Section 4.5 when it is placed in service.'
                        },
                        2: {
                            text: 'Suction hose that is in service shall be service tested as specified in Section 4.10 at least annually.'
                        },
                        3: {
                            text: 'Hose shall be stored out of direct sunlight and as recommended by the manufacturer.'
                        },
                        4: {
                            text: 'Hose that has exposed or damaged reinforcement either shall be removed from service, repaired, and service tested or shall be condemned.'
                        },
                        5: {
                            text: 'Foreign objects of any kind, including items of equipment, shall not be carried inside the hose.'
                        },
                        6: {
                            text: 'Suction hose shall not be used under positive pressure unless it has been specifically designed for such use.'
                        }
                    },
                    5: {
                        title: 'Hose Inspection',
                        1: {
                            text: 'Physical inspection shall determine if the hose and couplings have been vandalized, are free of debris, and exhibit no evidence of mildew, rot, or damage by chemicals, burns, cuts, abrasion, and vermin.'
                        },
                        2: {
                            text: 'During the inspection, a check shall be made to determine if the service test of the hose is current.'
                        },
                        3: {
                            title: 'Liner Inspection',
                            1: {
                                text: 'The interior of the hose at each end shall be visually inspected for any physical signs of liner delamination.'
                            },
                            2: {
                                text: 'If the liner shows signs of delamination, the hose shall be condemned.'
                            }
                        },
                        4: {
                            text: 'If the hose fails the physical inspection (see 4.5.1), it shall be removed from service and either repaired as necessary and service-tested as specified in Section 4.8, Section 4.9, or Section 4.10 as appropriate or condemned.'
                        },
                        5: {
                            text: 'The couplings shall be inspected as specified in 7.1.3 and 7.1.4.'
                        },
                        6: {
                            text: 'Where nozzles are required on occupant-use hose, they shall be inspected as specified in Section 5.2.'
                        }
                    },
                    6: {
                        title: 'Cleaning and Drying',
                        1: {
                            text: 'After each use, all hose shall be cleaned.'
                        },
                        2: {
                            text: 'If dirt cannot be thoroughly brushed from the hose or if the hose has come in contact with harmful materials, the hose shall be washed.'
                        },
                        3: {
                            text: 'If, during use, the hose has been exposed to hazardous materials, it shall be decontaminated by the method approved for the contaminant.'
                        },
                        4: {
                            text: 'Cleaned hose shall be permitted to be wiped dry.'
                        },
                        5: {
                            text: 'Hose shall not be dried on hot pavements or under intense sunlight.'
                        }
                    },
                    7: {
                        title: 'Storage',
                        1: {
                            text: 'Hose shall be kept out of direct sunlight and in a well-ventilated location.'
                        },
                        2: {
                            text: 'All hose shall be drained and thoroughly dried before being placed in storage.'
                        },
                        3: {
                            text: 'Hose shall be stored only after it has been inspected in accordance with Section 4.5 and has been cleaned and dried.'
                        },
                        4: {
                            text: 'Hose that is out of service for repair shall be tagged as specified in 4.11.1.6 and 4.11.3.6 and kept separated from any hose in storage that is ready for service.'
                        }
                    },
                    8: {
                        title: 'Service Testing Attack, Supply, Forestry Hose, and Occupant-Use Hose',
                        1: {
                            text: 'Hose manufactured prior to July 1987 to meet the requirements of the 1979 and previous editions of NFPA 1961 shall be removed from service.'
                        },
                        2: {
                            text: 'Hose manufactured during July 1987 or after that date to the 1987 or subsequent editions of NFPA 1961 shall be service tested as specified in Section 4.8.',
                            1: {
                                text: 'Attack fire hose shall be service tested to a minimum of 300 psi (20.7 bar or 2070 kPa) or a pressure not to exceed the service test pressure marked on the hose.'
                            },
                            2: {
                                text: 'Supply fire hose shall be service tested to a minimum of 200 psi (13.8 bar or 1380 kPa) or a pressure not to exceed the service test pressure marked on the hose.'
                            },
                            3: {
                                text: 'Forestry fire hose shall be service tested to a minimum of 300 psi (20.7 bar or 2070 kPa) or a pressure not to exceed the service test pressure marked on the hose.'
                            },
                            4: {
                                text: 'Occupant-use hose shall be tested to the service test pressure marked on the hose.'
                            },
                            5: {
                                text: 'Proof pressure tests for hoses shall be conducted only at the point of manufacture or at a facility equipped to perform those tests.'
                            },
                            6: {
                                text: 'Tests in the field shall not subject the hose to its proof test pressure.'
                            },
                            7: {
                                text: 'After the correct service test pressure has been determined for each length of hose to be tested, the service test shall be conducted as specified in 4.8.4.'
                            }
                        },
                        4: {
                            title: 'Service Test Procedure',
                            1: {
                                text: 'Each length of hose to be service tested shall be inspected as specified in Section 4.5.'
                            },
                            2: {
                                text: 'Any length of hose that fails the inspection shall be removed from the service test area and repaired as necessary or condemned.'
                            },
                            3: {
                                text: 'All lengths of hose in the same hose line shall be of the same service test pressure.'
                            },
                            4: {
                                text: 'The total length of any hose line in the hose test layout to be service tested shall not exceed 300 ft (91 m).'
                            },
                            5: {
                                text: 'The hose test layout shall be straight, without kinks or twists.'
                            },
                            6: {
                                text: 'All 3½ in. (89 mm) and larger diameter hose shall be service tested while lying on a horizontal surface.'
                            },
                            7: {
                                text: 'A test location shall be selected that allows connection of the hose testing apparatus (pressure source) to a water source.'
                            },
                            8: {
                                text: 'A hose testing machine, a stationary pump, or a pump on a fire department apparatus shall be used as a pressure source.',
                                1: {
                                    text: 'If a hose testing machine is used, the procedure defined in 4.8.5 shall be used.'
                                },
                                2: {
                                    text: 'If a stationary pump or a pump on a fire department apparatus is used, the procedure defined in 4.8.6 shall be used.'
                                }
                            },
                            9: {
                                text: 'At the conclusion of the test, the hose records specified in Section 4.11 shall be updated to indicate the results of the service test for each length of hose tested.'
                            },
                            10: {
                                1: {
                                    text: 'If the hose leaks or the hose jacket fails inspection, a distinguishing mark noting the location of the defect(s) shall be placed on the hose.'
                                },
                                2: {
                                    text: 'If the couplings fail or are defective, they shall be repaired or replaced.'
                                },
                                3: {
                                    text: 'If the hose cannot be repaired, the couplings shall be removed from both ends.'
                                }
                            },
                            11: {
                                text: 'If the hose is repaired, or the couplings are repaired or replaced, the hose shall be service tested in accordance with Section 4.8 before being placed back in service.'
                            },
                            12: {
                                text: 'After testing, all hose shall be thoroughly cleaned, drained, and dried as specified in Section 4.6 before being placed in service or in storage.'
                            }
                        },
                        5: {
                            title: 'Service Test Using a Hose Testing Machine',
                            text: 'The procedure defined in this subsection shall be used when hose is service tested using a hose testing machine.',
                            warning: 'Because there is a potential for catastrophic failure during the service testing of fire hose, it is vital that safety precautions be taken to prevent exposure of anyone to this danger. Do not deviate from the procedures prescribed herein.',
                            1: {
                                text: 'The condition of the hose testing machine shall be thoroughly checked daily before each testing session and before the machine is used after being transported to a new testing site.',
                                1: {
                                    text: 'The hose testing machine shall be carefully examined for damaged components that might fail during the test.'
                                },
                                2: {
                                    text: 'If any damage is discovered, the hose testing machine shall not be used until the damaged component(s) is repaired or replaced.'
                                },
                                3: {
                                    text: 'A pressure leak integrity test shall be performed on the machine to determine whether the pressurized outlet side of the machine and its related components are leak-free.',
                                    1: {
                                        text: 'The fire hose outlet connection(s) of the machine shall be capped or otherwise closed.'
                                    },
                                    2: {
                                        text: 'Pressure shall be applied through the machine using the integral pump to a level that is 10 percent higher than the highest service test pressure needed for the hose to be tested.'
                                    },
                                    3: {
                                        text: 'The pressure shall be held for 3 minutes with the pump turned off.'
                                    },
                                    4: {
                                        text: 'If leaks are detected, the testing machine shall not be used until the leaking component(s) is repaired or replaced.'
                                    }
                                },
                                4: {
                                    text: 'The test gauge that is used to read the test pressure shall have been calibrated within the previous 12 months.'
                                },
                                5: {
                                    text: 'If the hose machine incorporates elevated outlets for water supply that are higher than the inflated diameter of the hose from the testing surface, a means to vent trapped air shall be provided between the hose and the outlet valve.'
                                }
                            },
                            2: {
                                title: 'Conducting the Test',
                                1: {
                                    text: 'The test layout shall be connected to the outlet side of the water supply valve on the hose testing machine.'
                                },
                                2: {
                                    text: 'A test cap with a bleeder valve shall be attached to the far end of each hose line in the test layout. If a test cap is not available, a nozzle with a notwist shutoff shall be permitted to be used.'
                                },
                                3: {
                                    text: 'With the test cap valve or the nozzle open, the pressure shall be raised gradually to 45 psi ± 5 psi (3.1 bar ± 0.35 bar or 310 kPa ± 35 kPa).'
                                },
                                4: {
                                    text: 'After the hose test layout is full of water, all the air in each hose line shall be exhausted by raising the discharge end of each hose line above the highest point in the system.',
                                    warning: 'All air must be removed from the hose before the valve in the test cap or the nozzle is closed and the pressure raised. The development of test pressures introduces the potential for a serious accident if air remains in the system.'
                                },
                                5: {
                                    text: 'If the hose testing machine incorporates elevated outlets for water supply that are higher than the inflated diameter of the hose from the testing surface, air shall be vented next to the water input end.'
                                },
                                6: {
                                    text: 'The nozzle or test cap valve shall be closed slowly, and then the outlet water supply valve shall be closed.'
                                },
                                7: {
                                    text: 'The hose directly in back of the test cap or the nozzle shall be secured to avoid possible whipping or other uncontrolled reactions in the event of a hose burst.'
                                },
                                8: {
                                    text: 'With the hose at 45 psi ± 5 psi (3.1 bar ± 0.35 bar or 310 kPa ± 35 kPa), it shall be checked for leakage at each coupling and the couplings tightened with a spanner wrench where necessary.'
                                },
                                9: {
                                    text: 'Each hose shall then be marked around its full circumference at the end or back of each coupling or collar to determine, after the hose has been drained, if the coupling or collar has slipped during the test.'
                                },
                                10: {
                                    text: 'All personnel other than those persons required to perform the remainder of the procedure shall clear the area.'
                                },
                                11: {
                                    text: 'The pressure shall be raised slowly at a rate not greater than 15 psi (1 bar or 103 kPa) per second until the service test pressure is attained and then maintained, by pressure boosts if necessary, for the duration of the stabilization period.'
                                },
                                12: {
                                    text: 'The stabilization period shall be not less than 1 minute per 100 ft (30 m) of hose in the test layout.'
                                },
                                13: {
                                    text: 'After the stabilization period, the hose test layout shall hold the service test pressure for 3 minutes without further pressure boosts.'
                                },
                                14: {
                                    text: 'While the hose test layout is at the service test pressure, the hose shall be inspected for leaks.',
                                    14: {
                                        1: {
                                            text: 'If the inspecting personnel walk the test layout to inspect for leaks, they shall be at least 15 ft (4.5 m) to the left side of the nearest hose line in the test layout. The left side of the hose line shall be defined as that side that is to the left when facing the free end from the pressure source.'
                                        },
                                        2: {
                                            text: 'Personnel shall never stand in front of the free end of the hose, stand closer than 15 ft (4.5 m) on either side of the hose, or straddle a hose in the test layout during the test.'
                                        }
                                    }
                                },
                                15: {
                                    text: 'If the hose test layout does not hold the service test pressure for the 3-minute duration, the service test shall be terminated.',
                                    15: {
                                        1: {
                                            text: 'The length(s) of hose that leaked shall have failed the test.'
                                        },
                                        2: {
                                            text: 'The test layout shall be drained and the defective hose removed from the test layout.'
                                        },
                                        3: {
                                            text: 'The service test shall be restarted beginning with the procedures required in 4.8.5.2.1.'
                                        }
                                    }
                                },
                                16: {
                                    text: 'After 3 minutes at the service test pressure, each test cap or nozzle shall be opened to drain the test layout.'
                                },
                                17: {
                                    title: 'Coupling Slippage',
                                    1: {
                                        text: 'The hose and any marks placed on the hose at the back of the couplings or at external collars shall be observed for coupling slippage after completion of the service test and after the hose has been drained.'
                                    },
                                    2: {
                                        text: 'If the hose assembly shows any sign of coupling slippage, the hose assembly shall have failed the test.'
                                    }
                                }
                            }
                        },
                        6: {
                            title: 'Service Test Using a Stationary Pump or a Pump on a Fire Department Apparatus',
                            text: 'The procedure given in 4.8.6.1 through 4.8.6.16.2 shall be used when hose is to be service-tested using a stationary pump or a pump on a fire department apparatus.',
                            warning: 'Because there is a potential for catastrophic failure during the service testing of fire hose, it is vital that safety precautions be taken to prevent exposure of anyone to this danger. Do not deviate from the procedures prescribed herein.',
                            1: {
                                text: 'The test gauge that is used to read the test pressure shall have been calibrated within the previous 12 months.'
                            },
                            2: {
                                text: 'A hose test valve consisting of a fire department gate valve with a ¼ in. (6.4 mm) opening drilled through the gate and designed to withstand the service test pressures shall be used between the pump and the hose test layout.'
                            },
                            3: {
                                text: 'The test layout shall be connected to the hose test valve.',
                                1: {
                                    text: 'If a pump on a fire apparatus is used, the hose test valve shall not be attached to any discharge outlet at or adjacent to the pump operator\'s position.'
                                },
                                2: {
                                    text: 'The hose test valve end of the hose line shall be secured with a belt tie-in or rope hose tool at a point 10 in. (250 mm to 400 mm) from the coupling.'
                                }
                            },
                            4: {
                                text: 'A test cap with a bleeder valve shall be attached to the far end of each hose line in the test layout. If a test cap is not available, a nozzle with a nontwist shutoff shall be permitted to be used.'
                            },
                            5: {
                                text: 'With the hose test valve open and the test cap valve or nozzle open, the pressure shall be gradually raised to 45 psi ± 5 psi (3.1 bar ± 0.35 bar or 310 kPa ± 35 kPa).'
                            },
                            6: {
                                1: {
                                    text: 'After the hose test layout is full of water, all air in each hose line shall be exhausted by raising the discharge end of each hose line above the highest point in the system.',
                                    warning: 'All air must be removed from the hose before the valve in the test cap or the nozzle is closed and the pressure raised. The development of test pressures introduces the potential for a serious accident if air remains in the system.'
                                }
                            },
                            7: {
                                text: 'The nozzle or test cap valve shall be closed slowly, and then the hose test valve shall be closed.'
                            },
                            8: {
                                text: 'The hose directly in back of the test cap or the nozzle shall be secured to avoid possible whipping or other uncontrolled reactions in the event of a hose burst.'
                            },
                            9: {
                                text: 'With the hose at 45 psi ± 5 psi (3.1 bar ± 0.35 bar or 310 kPa ± 35 kPa), it shall be checked for leakage at each coupling and the couplings tightened with a spanner wrench where necessary.'
                            },
                            10: {
                                text: 'Each hose shall then be marked around its full circumference at the end or back of each coupling or collar to determine, after the hose has been drained, if the coupling or collar has slipped during the test.'
                            },
                            11: {
                                text: 'All personnel other than those persons required to perform the remainder of the procedure shall clear the area.'
                            },
                            12: {
                                text: 'The pressure shall be raised slowly at a rate not greater than 15 psi (1 bar or 103 kPa) per second until the service test pressure is attained and then maintained for 3 minutes.'
                            },
                            13: {
                                text: 'While the test layout is at the service test pressure, the hose shall be inspected for leaks.'
                            },
                            14: {
                                1: {
                                    text: 'If the inspecting personnel walk the test layout to inspect for leaks, they shall be at least 15 ft (4.5 m) to the left side of the nearest hose line in the test layout. The left side of the hose line shall be defined as that side that is to the left when facing the free end from the pressure source.'
                                },
                                2: {
                                    text: 'Personnel shall never stand in front of the free end of the hose, stand closer than 15 ft (4.5 m) on either side of the hose, or straddle a hose in the test layout during the test.'
                                }
                            },
                            15: {
                                1: {
                                    text: 'If, during the test, a section of hose is leaking or a section bursts, the service test shall be terminated.'
                                },
                                2: {
                                    text: 'The length(s) of hose that leaked or burst shall have failed the test.'
                                },
                                3: {
                                    text: 'The test layout shall be drained and the defective hose removed from the test layout.'
                                }
                            },
                            16: {
                                text: 'The service test shall be restarted beginning with the procedures required in 4.8.5.2.1.'
                            }
                        },
                        17: {
                            text: 'After 3 minutes at the service test pressure, each test cap or nozzle shall be opened to drain the test layout.'
                        },
                        18: {
                            text: 'Coupling Slippage.',
                            1: {
                                text: 'The hose and any marks placed on the hose at the back of the couplings or at external collars shall be observed for coupling slippage after completion of the service test and after the hose has been drained.'
                            },
                            2: {
                                text: 'If the hose assembly shows any sign of coupling slippage, the hose assembly shall have failed the test.'
                            }
                        }
                    },
                    9: {
                        title: 'Service Testing Booster Hose',
                        1: {
                            text: 'Booster hose shall be tested in accordance with 4.8.4 to 110 percent of its maximum working pressure.'
                        },
                        2: {
                            text: 'If a maximum working pressure cannot be determined for the hose, it shall be tested to 110 percent of the normal highest working pressure as used in the system.'
                        }
                    },
                    10: {
                        title: 'Service Tetsing Suction Hose',
                        text: [
                            'Suction hose shall be dry-vacuum tested using the following procedure:',
                            '(1) The hose shall be attached to a suction source.',
                            '(2) The free end shall be sealed with a transparent disk and connected to an accurate vacuum measuring instrument.',
                            '(3) A 22 in. mercury (0.75 bar or 74.5 kPa) vacuum shall be developed.',
                            '(4) While holding the vacuum for 10 minutes, the interior of the hose shall be inspected through the transparent disk.',
                            '(5) There shall be no signs of physical damage or collapse of the lining into the waterway.'
                        ].join('\n')
                    },
                    11: {
                        title: 'Hose Records',
                        1: {
                            1: {
                                text: 'Accurate hose records shall be established and maintained.'
                            },
                            2: {
                                text: 'Each length of hose shall be assigned an identification number for use in recording its history throughout its service life.',
                                1: {
                                    text: 'The identification number shall be stenciled on the jacket or cover using an ink or paint that is not harmful to the hose.'
                                },
                                2: {
                                    text: 'The identification number shall be permitted to be stamped on the bowl or swivel of the female coupling in a manner that prevents damage to the coupling.'
                                },
                                3: {
                                    text: 'Records of hose used by fire departments shall be kept as part of the department’s or individual company’s complete equipment inventory.'
                                }
                            },
                            4: {
                                text: 'Records for hose on racks or reels or in enclosures shall be kept at the hose location or at a control location on the premises where the hose is located.'
                            },
                            5: {
                                text: 'The following information, if applicable, shall be included for each length of hose:\nAssigned identification number\nManufacturer and part number\nVendor\nSize (internal diameter of waterway)\nLength\nType of hose\nConstruction\nDate received and date put in service\nDate of each service test and the service test pressure\nRepairs and new length if shortened\nActual damage\nExposure to possible damage\nReason removed from service\nReason condemned\nIndication that the hose has been removed from service or condemned within the warranty period because of an in-warranty failure'
                            }
                        },
                        2: {
                            title: 'Forestry Hose Records',
                            text: 'The authority having jurisdiction shall determine the records necessary to achieve an effective hose management program for forestry hose and implement such a record-keeping system.'
                        },
                        3: {
                            title: 'Occupant-Use Hose Records',
                            1: {
                                text: 'A record for each length of occupant-use hose, whether on a rack or reel or in an enclosure, shall be kept on a tag attached near the female end of the hose.'
                            },
                            2: {
                                text: 'The tag shall be fastened in a manner that does not restrict the hose from deploying properly and will not damage the hose.'
                            },
                            3: {
                                text: 'The tag shall contain at least the following information for each length of hose:\nManufacturer and part number\nDate put in service\nDate of each inspection and person/agency performing the inspection\nDate of each service test and person/agency performing the service test'
                            },
                            4: {
                                text: 'An inspection checklist maintained on file or in an electronic method (e.g., bar coding) that provides a permanent record shall be permitted to be used in place of a tag to track inspection and service test data, provided each length of hose is assigned a unique identification number that is fastened to or recorded on the hose or female coupling and the information required by 4.11.3.3 is recorded.'
                            },
                            5: {
                                text: 'Where records are kept electronically, the electronic record shall be available at the facility where the hose is in service.'
                            },
                            6: {
                                text: 'Hose removed from service for repair or because it has been condemned shall be tagged with a distinctive tag with the reason for removal from service noted on the tag.'
                            }
                        },
                        4: {
                            title: 'Booster Hose Records.',
                            1: {
                                text: 'Accurate hose records shall be established and maintained.'
                            },
                            2: {
                                text: 'Each length of booster hose shall be assigned an identification number for use in recording its history throughout its service life.'
                            },
                            3: {
                                text: 'The identification number shall be stenciled on the jacket or cover using an ink or paint that is not harmful to the hose.'
                            },
                            4: {
                                text: 'The identification number shall be permitted to be marked on the bowl or swivel of the female coupling in a manner that prevents damage to the coupling.'
                            },
                            5: {
                                text: 'Records of booster hose used by fire departments shall be kept as part of the department’s or individual company’s complete equipment inventory.'
                            },
                            6: {
                                text: 'Records for booster hose on racks or reels or in enclosures shall be kept at the hose location or at a control location on the premises where the hose is located.'
                            },
                            7: {
                                text: 'The following information, if applicable, shall be included for each length of booster hose:\n(1) Assigned identification number\n(2) Manufacturer and part number\n(3) Vendor\n(4) Size (internal diameter of waterway)\n(5) Length\n(6) Type of hose\n(7) Construction\n(8) Date received and date put in service\n(9) Date of each service test and the service test pressure\n(10) Repairs and new length if shortened\n(11) Actual damage\n(12) Exposure to possible damage\n(13) Reason removed from service\n(14) Reason condemned\n(15) Indication that the hose has been removed from service or condemned within the warranty period because of an in-warranty failure.'
                            }
                        },
                        5: {
                            title: 'Suction Hose Records',
                            1: {
                                text: 'Accurate hose records shall be established and maintained.'
                            },
                            2: {
                                text: 'Each length of suction hose shall be assigned an identification number for use in recording its history throughout its service life.',
                                1: {
                                    text: 'The identification number shall be stenciled on the jacket or cover using an ink or paint that is not harmful to the hose.'
                                },
                                2: {
                                    text: 'The identification number shall be permitted to be marked on the bowl or swivel of the female coupling in a manner that prevents damage to the coupling.'
                                }
                            },
                            3: {
                                text: 'Records of suction hose shall be kept as part of the fire department\'s or individual company\'s complete equipment inventory.'
                            },
                            4: {
                                text: 'The following information, if applicable, shall be included for each length of suction hose:\n(1) Assigned identification number\n(2) Manufacturer and part number\n(3) Vendor\n(4) Size (internal diameter of waterway)\n(5) Length\n(6) Type of hose\n(7) Construction\n(8) Date received and date put in service\n(9) Date of each service test and the service test pressure\n(10) Repairs and new length if shortened\n(11) Actual damage\n(12) Exposure to possible damage\n(13) Reason removed from service\n(14) Reason condemned\n(15) Indication that the hose has been removed from service or condemned within the warranty period because of an in-warranty failure'
                            },
                            5: {
                                text: 'Hose removed from service for repair or because it has been condemned shall be tagged with a distinctive tag with the reason for removal from service noted on the tag.'
                            },
                            6: {
                                text: 'Personnel responsible for the repair and maintenance of fire hose shall ensure that a report of the work performed to repair each length is recorded on the permanent hose record.'
                            }
                        }
                    }
                },
                7: {
                    title: 'Care and Inspection of Couplings and Gaskets',
                    1: {
                        title: 'Couplings',
                        1: {
                            text: 'Couplings shall be kept in serviceable condition.'
                        },
                        2: {
                            text: 'A lubricant specified by the coupling manufacturer shall be permitted to be used on coupling swivels and threads.'
                        },
                        3: {
                            text: 'After each use and during each hose service test, couplings shall be visually inspected for the following defects:\nDamaged threads\nCorrosion\nSlippage on the hose\nOut-of-round\nConnections not rotating freely\nMissing lugs\nLoose external collar\nInternal gasket not in accordance with Section 7.2\nOther defects that could impair operation\nAny locking device operating improperly.'
                        },
                        4: {
                            text: 'Hose with defective couplings shall be removed from service and the couplings repaired or replaced.'
                        },
                        5: {
                            text: 'All nonthreaded 4 in. (100 mm) and 5 in. (125 mm) hose connections shall be provided with locks that meet NFPA 1963.'
                        },
                        6: {
                            text: 'Care shall be taken not to drop the couplings on pavement or other hard surfaces, which can cause damage to the swivel section or exposed threads.'
                        },
                        7: {
                            text: 'Care shall be taken to prevent vehicles from driving over couplings.'
                        },
                        8: {
                            text: 'Special care shall be taken where couplings of dissimilar metals are connected, as corrosion can occur due to this difference and moisture tends to accelerate this corrosion.',
                            1: {
                                text: 'Where couplings of dissimilar metals are left connected, they shall be disconnected and inspected at least quarterly.'
                            },
                            2: {
                                text: 'If corrosion exists, the couplings shall be cleaned and an anticorrosive lubricant specified by the coupling manufacturer shall be applied to the threads.'
                            },
                            3: {
                                text: 'Anticorrosive lubricant shall be applied at the time of each service test.'
                            }
                        },
                        9: {
                            text: 'When new or used bowl couplings are being attached, care shall be taken to have the hose fit correctly in the bowl.',
                            1: {
                                text: 'The outside diameter of the hose shall fit snugly in the internal diameter of the bowl of the coupling.'
                            },
                            2: {
                                text: 'The expansion ring shall be of the correct size and length for the coupling used.'
                            },
                            3: {
                                text: 'A new tail gasket shall be used.'
                            }
                        },
                        10: {
                            text: 'When new or used shank-type couplings are being attached, care shall be taken to have the hose fit properly on the shank.',
                            1: {
                                text: 'The inside diameter of the hose shall fit snugly on the external diameter of the shank of the coupling.'
                            },
                            2: {
                                text: 'The collar shall be compatible with the shank and shall be sized for the hose used.'
                            },
                            3: {
                                text: 'The socket head cap screws on shank-type couplings shall be torqued to the manufacturer\'s specified tolerance.'
                            }
                        },
                        11: {
                            text: 'When couplings are attached or reattached to hose, the hose shall be tested at its service test pressure in accordance with Section 4.8, 4.9, or 4.10 as appropriate.',
                            warning: 'Because there is a potential for catastrophic failure during these tests, it is vital that safety precautions be taken to prevent exposure of anyone to this danger. Do not deviate from the procedures prescribed in 4.8.5 and 4.8.6.'
                        },
                        12: {
                            text: 'The date and nature of the repair or recoupling and the identity of the person performing the repair shall be recorded for each length of hose as specified in Section 4.11.'
                        },
                        13: {
                            text: 'The socket head cap screws on shank-type couplings shall be checked at least annually to ensure that they are torqued to the manufacturer\'s specified tolerance and shall be replaced at any sign of wear.'
                        }
                    },
                    2: {
                        title: 'Gaskets',
                        1: {
                            text: 'The thread gasket in couplings, nozzles, and hose appliances shall be inspected for presence, tight fit, and lack of deterioration.'
                        },
                        2: {
                            text: 'Gaskets shall not protrude into the waterway.'
                        },
                        3: {
                            text: 'Any gasket that is defective or misfits shall be replaced with a new gasket that meets the requirements of NFPA 1963.'
                        }
                    }
                }
            }
        }
    },
    5000: {
        title: 'Building Construction and Safety Code®',
        editions: {
            2018: {
                7: {
                    title: 'Construction Types and Height and Area Requirements'
                }
            }
        }
    }
};

export default NFPA;