import React from 'react';
import {Chip} from '@mui/material';

export default function SmallChip(props) {
    return (
        <Chip
            sx={{
                mb: 0.5,
                mr: 0.5,
                height: 18,
                '& .MuiChip-label': {
                    fontSize: '0.6rem'
                }
            }}
            size="small"
            {...props}
        />
    )
};